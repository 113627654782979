import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { getPrettyDate } from '../../utils/i18nUtils';
export interface PrettyDateProps {
  date?: string;
  showTime?: boolean;
}

export const PrettyDate: FC<PrettyDateProps> = ({ date, showTime }) => {
  const { t } = useTranslation();
  let formattedDate = '' as any;

  if (!date) return <span>-</span>;
  if (showTime) {
    formattedDate = getPrettyDate(date, true, t('common.clockAbbr'));
  } else {
    formattedDate = getPrettyDate(date);
  }
  return <span>{formattedDate}</span>;
};
