import { faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, makeStyles } from '@material-ui/core';
import { makeRequired, makeValidate } from 'mui-rff';
import { FC } from 'react';
import { Form } from 'react-final-form';
import { Button, Input } from '../../components/FormControls';
import { MINIMUM_PASSWORD_LENGTH } from '../../constants/siteSettings';
import { Validator } from '../../utils/validation';

interface ResetPasswordFormProps {
  isLoading: boolean;
  onSubmit: (values: any, form: any, callback: any) => void;
}

const useStyles = makeStyles({
  loginButton: {
    textTransform: 'uppercase',
    marginTop: '0.5rem',
    borderRadius: '50px',
    height: '3.4rem',
  },
});

const ResetPasswordForm: FC<ResetPasswordFormProps> = ({
  isLoading,
  onSubmit,
}) => {
  const classes = useStyles();

  const schema = Validator.object().shape({
    password: Validator.string()
      .hasSpecialChars()
      .hasLowerCase()
      .hasUpperCase()
      .hasNumber()
      .min(MINIMUM_PASSWORD_LENGTH)
      .required(),
  });

  const validate = makeValidate(schema);
  const required = makeRequired(schema);
  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      render={({ handleSubmit, pristine, hasValidationErrors }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={1} justify="center">
              <Grid item xs={12}>
                <Input
                  id="password"
                  name="password"
                  type="password"
                  t="common.password"
                  icon={<FontAwesomeIcon icon={faLock} />}
                  required={required.password}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  className={classes.loginButton}
                  id="loginButton"
                  type="submit"
                  t="common.send"
                  fullWidth
                  loading={isLoading}
                  disabled={pristine || hasValidationErrors}
                />
              </Grid>
            </Grid>
          </form>
        );
      }}
    />
  );
};

export default ResetPasswordForm;
