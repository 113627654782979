import { Box, BoxProps, makeStyles } from '@material-ui/core';
import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import colors from '../../styles/colors';

export interface SimpleTableItem {
  label: string;
  value?: string | ReactNode;
  render?: () => any;
  hide?: boolean;
}
interface SimpleTableProps extends BoxProps {
  items: SimpleTableItem[];
  skipTranslation?: boolean;
}

const useStyles = makeStyles({
  cell: {
    color: colors.text.body1,
    minWidth: 200,
    padding: '6px 0',
    margin: 0,
    display: 'flex',
    alignItems: 'flex-start',
  },
  tr: {
    display: 'flex',
  },
});

export const SimpleTable: FC<SimpleTableProps> = ({
  items,
  skipTranslation,
  ...rest
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Box component="table" {...rest}>
      <Box component="tbody">
        {items.map((item) => {
          if (item.hide) return null;
          return (
            <Box component="tr" className={classes.tr} key={item.label}>
              <Box component="td" className={classes.cell}>
                {skipTranslation ? item.label : t(item.label)}
              </Box>
              <Box component="td" className={classes.cell}>
                {item.render ? item.render() : item.value}
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};
