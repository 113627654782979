/* eslint-disable react-hooks/exhaustive-deps */
import { Box, makeStyles, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStores } from '../../../stores/index';
import { ColorBox } from '../../ColorBox/ColorBox';
import { OrderOverview } from '../../OrderOverview.tsx/OrderOverview';
import { CommonModal } from '../CommonModal';

interface CartModalProps {
  isOpen: boolean;
  onClose: any;
}

const useStyles = makeStyles({
  modalContent: {
    maxHeight: '70vh',
    overflowY: 'auto',
  },
});

export const CartModal: FC<CartModalProps> = observer(({ isOpen, onClose }) => {
  const [resetModalOpen, setResetModalOpen] = useState(false);
  const {
    cartStore: { clearCart },
  } = useStores();
  const { t } = useTranslation();
  const classes = useStyles();

  const onResetModalConfirm = async () => {
    await clearCart();
    setResetModalOpen(false);
  };

  const onResetModalCancel = () => {
    setResetModalOpen(false);
  };

  const onRemoveClick = () => {
    setResetModalOpen(true);
  };

  return (
    <>
      <CommonModal
        open={isOpen}
        onClose={onClose}
        type="normal"
        title={t('orders.add.cartModal.title')}
      >
        <Box className={classes.modalContent}>
          <ColorBox p={3} color="red" mb={3} whiteText>
            {t('orders.add.cartModal.removeProductModal.body')}
          </ColorBox>
          <OrderOverview onRemoveClick={onRemoveClick} />
        </Box>
      </CommonModal>

      <CommonModal
        title={t('orders.add.cartModal.removeProductModal.title')}
        open={resetModalOpen}
        onConfirm={onResetModalConfirm}
        onCancel={onResetModalCancel}
        cancelText={t('orders.add.cartModal.removeProductModal.cancel')}
        confirmText={t('orders.add.cartModal.removeProductModal.confirm')}
        type="prompt"
      >
        <Typography>
          {t('orders.add.cartModal.removeProductModal.body')}
        </Typography>
      </CommonModal>
    </>
  );
});
