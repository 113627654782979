import { Button as MaterialButton, CircularProgress } from '@material-ui/core';
import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

interface ButtonProps {
  id: string;
  size?: 'small' | 'medium' | 'large';
  variant?: 'contained' | 'text' | 'outlined';
  loading?: boolean;
  color?: 'default' | 'inherit' | 'primary' | 'secondary';
  type?: 'button' | 'submit';
  disabled?: boolean;
  fullWidth?: boolean;
  className?: string;
  t?: string;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  onClick?: () => void;
}

export const Button: FC<ButtonProps> = ({
  id,
  size = 'large',
  variant = 'contained',
  loading = false,
  color,
  type,
  disabled,
  fullWidth,
  className,
  t,
  startIcon,
  endIcon,
  onClick,
  children,
  ...rest
}) => {
  const { t: translate } = useTranslation();
  const endIconOrLoading = loading ? <CircularProgress size={24} /> : endIcon;
  return (
    <MaterialButton
      id={id}
      size={size}
      variant={variant}
      color={color}
      type={type}
      disabled={disabled || loading}
      fullWidth={fullWidth}
      className={className}
      startIcon={startIcon}
      endIcon={endIconOrLoading}
      onClick={onClick}
      {...rest}
    >
      {t ? translate(t) : children}
    </MaterialButton>
  );
};
