import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Layout } from '../../components/Layout/Layout';
import { ReservationCalendar } from '../../components/ReservationCalendar/ReservationCalendar';

export interface CalendarScreenProps {}

export const CalendarScreen: FC<CalendarScreenProps> = observer(() => {
  const { t } = useTranslation();

  return (
    <Layout headerText={t('common.calendar')}>
      <ReservationCalendar />
    </Layout>
  );
});
