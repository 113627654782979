import { api, getPath, getAuthConfig } from './api';

const API_PATHS = Object.freeze({
  base: '/customer/{customerId}/bulletin',
  single: '/customer/{customerId}/bulletin/{itemId}',
  markAllRead: '/customer/{customerId}/bulletin/read',
  markRead: '/customer/{customerId}/bulletin/read/{itemId}',
});

class BulletinServiceModel {
  getBulletins = async (params: PaginationDTO) => {
    const res = await api.get(
      getPath(API_PATHS.base),
      getAuthConfig(false, params),
    );
    return res?.data;
  };

  getBulletin = async (id: string) => {
    const res = await api.get(getPath(API_PATHS.single, id), getAuthConfig());
    return res?.data;
  };

  createBulletin = async (data: IBulletin) =>
    api.post(getPath(API_PATHS.base), data, getAuthConfig());

  updateBulletin = async (id: string, data: IBulletin) =>
    api.put(getPath(API_PATHS.single, id), data, getAuthConfig());

  deleteBulletin = async (id: string) =>
    api.delete(getPath(API_PATHS.single, id), getAuthConfig());

  markAllBulletinsRead = async () =>
    api.post(getPath(API_PATHS.markAllRead), undefined, getAuthConfig());

  markBulletinRead = async (id: string) =>
    api.post(getPath(API_PATHS.markRead, id), undefined, getAuthConfig());
}

const BulletinService = new BulletinServiceModel();

export default BulletinService;
