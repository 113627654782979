import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Radios } from 'mui-rff';
import React from 'react';
import { SPACING } from '../../styles';
import colors from '../../styles/colors';

interface BoxRadioGroupProps {
  label: string;
  options: { value: string; label: string }[];
  value?: string;
  name: string;
}

const useStyles = makeStyles({
  option: {
    border: `1px solid ${colors.stroke}`,
  },
  container: {
    '& .MuiFormControlLabel-root': {
      border: `1px solid ${colors.stroke}`,
      width: '100%',
      marginBottom: SPACING[1],
      padding: `0 ${SPACING[1]}px`,
      marginRight: 0,
      marginLeft: 0,
    },
    '& .MuiFormControl-root': {
      width: '100%',
    },
  },
});

export const BoxRadioGroup: React.FC<BoxRadioGroupProps> = ({
  label,
  options,
  name,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Box mb={3}>
        <Typography component="label" variant="h3">
          {label}
        </Typography>
      </Box>

      <Radios data={options} name={name} />
    </Box>
  );
};
