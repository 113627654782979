import {
  Box,
  Container,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Layout } from '../../components/Layout';
import { Link } from '../../components/Link';
import { useStores } from '../../stores/index';
import LoginForm from './LoginForm';

interface LoginScreenProps {}

const useStyles = makeStyles({
  fullHeight: {
    height: '100%',
    minHeight: '100vh',
  },
  center: {
    textAlign: 'center',
  },
  formContainer: {
    height: '100%',
    width: '100%',
    maxWidth: '960px',
  },
});

export const LoginScreen: FC<LoginScreenProps> = observer(() => {
  const {
    authStore: { login, isLoading },
  } = useStores();
  const { t } = useTranslation();
  const classes = useStyles();

  const onLoginSubmit = (formValues: any) => {
    login(formValues);
  };

  return (
    <Layout>
      <Grid container justify="center" className={classes.fullHeight}>
        <Box className={classes.formContainer}>
          <Grid
            container
            justify="center"
            alignItems="center"
            className={classes.fullHeight}
          >
            <Grid item xs={12} md={8} lg={6}>
              <Container>
                <Box mb={4} className={classes.center}>
                  <Box mt={1}>
                    <Typography variant="h5">
                      {t('login.boxManagement')}
                    </Typography>
                  </Box>
                </Box>
                <Box mb={4} className={classes.center}>
                  <Typography variant="h1">{t('login.login')}</Typography>
                </Box>
                <Box>
                  <LoginForm onSubmit={onLoginSubmit} isLoading={isLoading} />
                </Box>
                <Box mt={4} className={classes.center}>
                  <Link to="/forgot-password">
                    {t('login.forgotPasswordLink')}
                  </Link>
                </Box>
              </Container>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Layout>
  );
});
