import { Box } from '@material-ui/core';
import * as Sentry from '@sentry/react';
import { createBrowserHistory } from 'history';
import countries from 'i18n-iso-countries';
import { Router } from 'react-router-dom';
import 'typeface-inter';
import './app.css';
import { ENV, SENTRY_ENABLED } from './constants';
import { initLang } from './i18n';
import Routes from './Routes';

initLang('fi');

countries.registerLocale(require('i18n-iso-countries/langs/en.json'));
countries.registerLocale(require('i18n-iso-countries/langs/fi.json'));
countries.registerLocale(require('i18n-iso-countries/langs/sv.json'));
const history = createBrowserHistory();

if (SENTRY_ENABLED) {
  Sentry.init({
    dsn: 'https://63699bf18d826d4ac954c7587c00fcca@o4506353584832512.ingest.sentry.io/4506388548812800',
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          'localhost',
          /^https:\/\/api\.qa\.livex\.fi/,
          /^https:\/\/api\.prod\.livex\.fi/,
        ],
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ],
    tracesSampleRate: 0.1,
    environment: ENV,
  });
}

const App = () => {
  return (
    <Box className="App">
      <Router history={history}>
        <Routes />
      </Router>
    </Box>
  );
};

export default App;
