import { Box, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { Select } from 'mui-rff';
import { FC, useEffect, useMemo } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useStores } from '../../../../stores';
import { SkyboxSettingsModalFooter } from './SkyboxSettingsModalFooter';
export interface SkyboxSettingsCrossUseProps {
  onCancel: any;
  onConfirm: any;
  skybox: ISkybox;
}

export const SkyboxSettingsCrossUse: FC<SkyboxSettingsCrossUseProps> = observer(
  ({ onConfirm, onCancel, skybox }) => {
    const { t } = useTranslation();
    const {
      ownerStore: { ownerListPage, getOwnerList },
      skyboxStore: { setOwnerForEvent },
      eventStore: { event },
    } = useStores();

    useEffect(() => {
      if (!ownerListPage) {
        getOwnerList();
      }
    }, [ownerListPage, getOwnerList]);

    const ownerOpts = useMemo(() => {
      if (!skybox?.secondaryOwners?.length) return [];
      const owners = [
        ...[skybox.company],
        ...skybox.secondaryOwners,
      ] as unknown as IOwner[];

      return owners.map((owner) => {
        return {
          value: owner.id,
          label: owner.name,
        };
      });
    }, [skybox]);

    const onSubmit = async (values: any) => {
      await setOwnerForEvent(skybox.id, {
        eventId: event!.id,
        ownerId: values.owner,
      });
      onConfirm();
    };

    return (
      <Box>
        <Typography variant="h1">
          {t('events.skyboxSettingsModal.crossUse.title')}
        </Typography>
        <Box mt={2} />
        <Typography variant="body2">
          {t('events.skyboxSettingsModal.crossUse.description')}
        </Typography>
        <Box mt={3} />

        <Form
          onSubmit={onSubmit}
          initialValues={skybox}
          render={({ handleSubmit }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Select
                  name="owner"
                  data={ownerOpts}
                  label={t('common.owner')}
                />
                <SkyboxSettingsModalFooter
                  onCancel={onCancel}
                  confirmText="common.save"
                  cancelText="common.back"
                />
              </form>
            );
          }}
        />
      </Box>
    );
  },
);
