import { observer } from 'mobx-react-lite';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ReportsLayout } from '../../components/Layout/hocs/ReportsLayout';
import Table from '../../components/MaterialTable/MaterialTable';
import { PATHS } from '../../constants/routes';
import { useStores } from '../../stores/index';
import { getAdminReportColumns } from './ReportColumns';
export interface ReportsAdminListScreenProps {}

export const ReportsAdminListScreen: FC<ReportsAdminListScreenProps> = observer(
  () => {
    const {
      reportStore: { report },
    } = useStores();
    const { t } = useTranslation();
    const history = useHistory();
    useEffect(() => {
      if (!report) history.push(PATHS.skyboxReports.main);
    }, [report, history]);

    return (
      <ReportsLayout>
        <Table data={report} columns={getAdminReportColumns(t)} />
      </ReportsLayout>
    );
  },
);
