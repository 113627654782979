import { Box } from '@material-ui/core';
import arrayMutators from 'final-form-arrays';
import { observer } from 'mobx-react-lite';
import { TextField } from 'mui-rff';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { FormHeader } from '../../components/FormHeader/FormHeader';
import { Wysiwyg } from '../../components/Wysiwyg/Wysiwyg';
import { useStores } from '../../stores/index';

export interface BulletinFormProps {
  onSubmit: any;
  onCancel: any;
  initialValues?: any;
  title: string;
}

export const BulletinForm: React.FC<BulletinFormProps> = observer(
  ({ onSubmit, onCancel, initialValues, title }) => {
    const {
      bulletinStore: { isLoading, resetBulletin },
    } = useStores();

    const { t } = useTranslation();

    useEffect(() => {
      return () => {
        resetBulletin();
      };
    }, [resetBulletin]);

    const onFormSubmit = useCallback(
      (values: any) => {
        const newValues = { ...values };
        onSubmit(newValues);
      },
      [onSubmit],
    );

    const defaultValues = useMemo(() => {
      const obj = { ...initialValues } || {};
      return obj;
    }, [initialValues]);

    return (
      <Form
        initialValues={defaultValues}
        mutators={{ ...arrayMutators }}
        onSubmit={onFormSubmit}
        render={({ handleSubmit }) => {
          return (
            <Box component="form" onSubmit={handleSubmit}>
              <FormHeader
                title={title}
                loading={isLoading}
                onCancel={onCancel}
              />
              <Box p={3} maxWidth={800}>
                <TextField name="title" label={t('bulletin.title')} required />

                <Box mt={3}>
                  <Field
                    name="content"
                    render={({ input }) => {
                      return (
                        <Wysiwyg
                          label={t('bulletin.content')}
                          isAlwaysEditable
                          {...input}
                        />
                      );
                    }}
                  />
                </Box>
              </Box>
            </Box>
          );
        }}
      />
    );
  },
);
