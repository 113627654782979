/* eslint-disable max-len */
/* eslint-disable implicit-arrow-linebreak */
import { CreateInternalEventDTO } from '../types/Event';
import { api, getAuthConfig, getPath } from './api';

const API_PATHS = Object.freeze({
  single: '/customer/{customerId}/internal-event/{itemId}',
  all: '/customer/{customerId}/internal-event',
  checkSlot: '/customer/{customerId}/internal-event/checkSlot',
});

class InternalEventServiceModel {
  getAllInternalEvents = (params: TimespanDTO) =>
    api.get(getPath(API_PATHS.all), getAuthConfig(false, params));

  getInternalEvent = (id: string) =>
    api.get(getPath(API_PATHS.single, id), getAuthConfig());

  updateInternalEvent = (id: string, data: any) =>
    api.put(getPath(API_PATHS.single, id), data, getAuthConfig());

  createInternalEvent = (data: CreateInternalEventDTO) =>
    api.post(getPath(API_PATHS.all), data, getAuthConfig());

  deleteInternalEvent = (id: string) =>
    api.delete(getPath(API_PATHS.single, id), getAuthConfig());

  checkCalendarSlotForInternalEvent = (data: CheckTimeSlotDTO) =>
    api.post(getPath(API_PATHS.checkSlot), data, getAuthConfig());
}

const InternalEventService = new InternalEventServiceModel();

export default InternalEventService;
