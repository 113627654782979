import { Grid } from '@material-ui/core';
import { FC } from 'react';

interface LoadingScreenProps {}

const LoadingScreen: FC<LoadingScreenProps> = () => {
  return <Grid style={{ height: '100%' }}></Grid>;
};

export default LoadingScreen;
