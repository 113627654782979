/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Layout } from '../../components/Layout/Layout';
import { PATHS } from '../../constants/routes';
import { useStores } from '../../stores/index';

interface OrderFormDOrderCompletedScreenProps {}
export const OrderFormOrderCompletedScreen: FC<OrderFormDOrderCompletedScreenProps> =
  observer(() => {
    const { t } = useTranslation();
    const history = useHistory();

    const [initialized, setInitialized] = useState(false);
    const {
      skyboxStore: { getSkyboxInvites, skyboxInvites },
      authStore: { isAdmin },
      orderStore: { getOrder },
      cartStore: { clearCart },
    } = useStores();

    useEffect(() => {
      if (!initialized) {
        setInitialized(true);
        getSkyboxInvites();
        getOrder();
        clearCart();
      }
    }, [skyboxInvites, getSkyboxInvites, initialized, setInitialized]);

    const onLinkButtonClick = () => {
      let redirectPath: string = PATHS.orders.owners.upcoming;
      if (isAdmin) {
        redirectPath = PATHS.orders.main;
      }
      history.push(redirectPath);
    };

    const translate = (key: string) => t(`orders.add.orderCompleted.${key}`);

    return (
      <Layout headerText={translate('title')}>
        <Box p={3}>
          <Box mb={3}>
            <Typography>{translate('body')}</Typography>
          </Box>
          <Button onClick={onLinkButtonClick}>{translate('linkText')}</Button>
        </Box>
      </Layout>
    );
  });
