export enum SystemNotificationType {
  INFO = 'INFO',
  WARNING = 'WARNING',
}

export interface SystemNotification {
  id: string;
  type: SystemNotificationType;
  message: string;
}
