import { Box } from '@material-ui/core';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getPrettyDate } from '../../utils/i18nUtils';
import { KeyValueList } from '../KeyValueList';
import { CommonModal, CommonModalProps } from '../Modals/CommonModal';

export interface ReservationCalendarOrderModalProps
  extends Omit<CommonModalProps, 'children' | 'type'> {
  onClose: any;
  open: boolean;
  order: ICalendarSkyboxUsage;
}

export const ReservationCalendarOrderModal: React.FC<
  ReservationCalendarOrderModalProps
> = ({ onClose, open, order }) => {
  const { t } = useTranslation();

  const ticketCount = useMemo(() => {
    const childPurchaseTickets =
      order.purchase?.children?.flatMap(
        (childPurchase) => childPurchase.tickets,
      ) ?? [];
    const allTickets = [
      ...(order.purchase?.tickets ?? []),
      ...childPurchaseTickets,
    ];
    return allTickets?.length || '0';
  }, [order]);

  const contactDetailsFields = [
    'contactName',
    'contactPhone',
    'contactEmail',
    'identifierText',
  ].map((id: any) => ({
    label: t(`common.skyboxContactDetails.${id}`),
    render: () => {
      const data = { ...order } as any;
      if (!data.purchaseSkybox) return '';
      return data.purchaseSkybox[id] || '';
    },
  }));

  const values = [
    { label: t('common.event'), value: order.event.title },
    {
      label: t('common.date'),
      value: getPrettyDate(order.event.date, true, t('common.clockAbbr'), true),
    },
    ...contactDetailsFields,
    {
      label: t('calendar.reservationsList.orderModal.ticketCount'),
      value: ticketCount,
    },
  ];

  return (
    <CommonModal
      open={open}
      onClose={onClose}
      title={t('calendar.reservationsList.orderModal.title')}
      maxWidth={640}
    >
      <Box>
        <KeyValueList items={values} />
      </Box>
    </CommonModal>
  );
};
