/* eslint-disable react-hooks/exhaustive-deps */
import { Box, CircularProgress, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { makeRequired, makeValidate, TextField } from 'mui-rff';
import { useEffect, useState } from 'react';
import { Form, FormSpy } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { AddOrderLayout } from '../../components/Layout';
import { CommonModal } from '../../components/Modals/CommonModal';
import { PersonalizationModal } from '../../components/Modals/hocs/PersonalizationModal/PersonalizationModal';
import { TicketList } from '../../components/TicketList/TicketList';
import SkyboxService, { SkyboxTicketType } from '../../services/SkyboxService';
import { useStores } from '../../stores/index';
import { Validator } from '../../utils/validation';

const useAvailableSkyboxTicketTypes = ({
  skyboxId,
  eventId,
}: {
  skyboxId?: string;
  eventId?: number;
}) => {
  const [tickets, setTickets] = useState<SkyboxTicketType[] | undefined>(
    undefined,
  );

  useEffect(() => {
    if (skyboxId && eventId) {
      SkyboxService.getSkyboxAvailableTicketTypes(skyboxId, eventId)
        .then((v) => {
          setTickets(v);
        })
        .catch(() => {
          setTickets([]);
        });
    }
  }, [skyboxId, eventId]);

  return tickets;
};

export const OrderFormTicketsScreen = observer(() => {
  const { t } = useTranslation();
  const [formValues, setFormValues] = useState<any>();
  const [initialized, setInitialized] = useState(false);
  const [personalizationModalOpen, setPersonalizationModalOpen] =
    useState(false);
  const [contactInfo, setContactInfo] = useState<
    | { contactName?: string; contactEmail?: string; contactPhone?: string }
    | undefined
  >();
  const [ticketlessOrderModalOpen, setTicketlessOrderModalOpen] =
    useState(false);

  const {
    cartStore: {
      setActiveStep,
      skyboxId,
      eventIds,
      activeStep,
      setContactDetails,
      isChildOrder,
      cart,
      isLoading,
    },
    authStore: { isOwner },
    eventStore: { event, getEvent },
  } = useStores();

  const isPersonalizationMandatory = () => {
    if (event?.eventState) {
      const {
        eventState: {
          PersonalizationMandatoryPerTicket,
          OrderCommentMandatory,
          PersonalizationMandatoryPerOrder,
        },
      } = event;
      return (
        !!PersonalizationMandatoryPerTicket ||
        !!OrderCommentMandatory ||
        !!PersonalizationMandatoryPerOrder
      );
    }
    return false;
  };

  useEffect(() => {
    if (cart && !contactInfo) {
      const parentOrderSkybox =
        cart.parent?.purchaseSkyboxes?.length &&
        cart.parent.purchaseSkyboxes[0];

      if (parentOrderSkybox) {
        setContactInfo(parentOrderSkybox);
      }

      const eventSkyboxInfo =
        cart.purchaseSkyboxes?.[0]?.skyboxInfo.eventSkybox;
      if (eventSkyboxInfo) {
        setContactInfo(eventSkyboxInfo);
      }
    }
  }, [cart]);

  const navigateToNext = () => {
    setActiveStep(activeStep + 1);
  };

  const continueOrder = (allowTicketlessOrder?: boolean) => {
    if (!cart?.tickets?.length && !allowTicketlessOrder) {
      setTicketlessOrderModalOpen(true);
      return;
    }
    try {
      if (formValues) {
        const {
          eventSkyboxId,
          id,
          price,
          redirectUrl,
          purchaseId,
          skyboxName,
          vatAmount,
          ...contactDetails
        } = formValues;
        setContactDetails(contactDetails);
      }

      if (isPersonalizationMandatory()) {
        setPersonalizationModalOpen(true);
        return;
      }
      navigateToNext();
    } catch (error) {
      console.error(error);
    }
  };

  const onConfirm = async () => {
    continueOrder();
  };

  const ticketTypes = useAvailableSkyboxTicketTypes({
    skyboxId,
    eventId: event?.id,
  });

  useEffect(() => {
    if (!eventIds?.eventId || !skyboxId) setActiveStep(0);
    if (eventIds?.eventId && eventIds.eventId !== event?.id && !initialized) {
      setInitialized(true);
      getEvent(eventIds.eventSeriesId!, eventIds.eventId, isOwner);
    }
  }, [
    eventIds,
    event,
    setActiveStep,
    getEvent,
    skyboxId,
    initialized,
    setInitialized,
    isOwner,
  ]);

  const ADDITIONAL_INFO_ROWS = [
    {
      id: 1,
      fields: [{ name: 'identifierText' }],
    },
    {
      id: 2,
      fields: [{ name: 'contactName' }],
    },
    {
      id: 3,
      fields: [
        {
          name: 'contactPhone',
        },
        {
          name: 'contactEmail',
        },
      ],
    },
  ];

  const schema = Validator.object().shape({
    identifierText: Validator.string(),
    contactName: Validator.string().nullable().required(),
    contactPhone: Validator.string().phoneNumber().nullable().required(),
    contactEmail: Validator.string().email().nullable().required(),
  });

  const validate = makeValidate(schema);
  const required = makeRequired(schema);

  const onSubmit = () => {};

  const onFormChange = ({ values }: any) => {
    if (!values) return;
    if (
      !values.contactEmail &&
      !values.contactName &&
      !values.contactPhone &&
      !values.identifierText
    )
      return;
    setFormValues(values);
  };

  const onPersonalizationModalConfirm = () => {
    navigateToNext();
  };

  const onPersonalizationModalCancel = () => {
    setPersonalizationModalOpen(false);
  };

  const onTicketlessOrderConfirm = () => {
    continueOrder(true);
  };

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      initialValues={contactInfo}
      render={({ handleSubmit, valid }) => {
        return (
          <AddOrderLayout
            title={t('orders.add.tickets.title')}
            onConfirm={onConfirm}
            confirmDisabled={!valid}
          >
            <form onSubmit={handleSubmit}>
              <Box mt={3}>
                <Typography variant="h3">
                  {t('orders.add.tickets.chooseTickets')}
                </Typography>
                <Box mt={3}>
                  {ticketTypes ? (
                    <TicketList tickets={ticketTypes} />
                  ) : (
                    <CircularProgress color="secondary" size={20} />
                  )}
                  {ticketTypes?.length === 0 && (
                    <Typography color="error">
                      {t('orders.add.tickets.noTicketsAvailable')}
                    </Typography>
                  )}
                </Box>

                <Box maxWidth={500} mt={3}>
                  <Typography variant="h3">
                    {t('orders.add.tickets.additionalInformation')}
                  </Typography>
                  {ADDITIONAL_INFO_ROWS.map((row) => {
                    return (
                      <Box mt={3} display="flex" key={row.id}>
                        {row.fields.map((field: any, index: number) => (
                          <Box
                            mr={index !== row.fields.length - 1 ? 3 : 0}
                            width="100%"
                          >
                            <TextField
                              name={field.name}
                              label={t(
                                `common.skyboxContactDetails.${field.name}`,
                              )}
                              helperText={
                                field.instructions &&
                                t(`orders.add.tickets.${field.instructions}`)
                              }
                              required={required[field.name]}
                              disabled={isChildOrder}
                            />
                          </Box>
                        ))}
                      </Box>
                    );
                  })}
                </Box>
              </Box>
              <FormSpy
                onChange={onFormChange}
                subscription={{ values: true }}
              />
            </form>
            <PersonalizationModal
              isOpen={personalizationModalOpen}
              onConfirmClick={onPersonalizationModalConfirm}
              onCancelClick={onPersonalizationModalCancel}
              loading={isLoading}
            />
            <CommonModal
              open={ticketlessOrderModalOpen}
              title={t('orders.add.ticketlessOrderModal.title')}
              onCancel={() => setTicketlessOrderModalOpen(false)}
              onConfirm={onTicketlessOrderConfirm}
              confirmText={t('orders.add.ticketlessOrderModal.confirm')}
              cancelText={t('orders.add.ticketlessOrderModal.cancel')}
            >
              <Typography>
                {t('orders.add.ticketlessOrderModal.body')}
              </Typography>
            </CommonModal>
          </AddOrderLayout>
        );
      }}
    />
  );
});
