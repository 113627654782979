import { createMuiTheme } from '@material-ui/core';
import { fiFI } from '@material-ui/core/locale';
import { SkeletonClassKey } from '@material-ui/lab';
import colors from './colors';
import { darken } from 'polished';
import { PICKER_OVERRIDES } from './picker-overrides';

declare module '@material-ui/core/styles/overrides' {
  export interface ComponentNameToClassKey {
    MuiSkeleton: SkeletonClassKey;
  }
}

export const SPACING = [0, 8, 16, 24, 32, 48, 64, 128, 160, 192, 256];
export const FONT_SIZES = [
  '0.80rem',
  '0.85rem',
  '1rem',
  '1.125rem',
  '1.5rem',
  '2.25rem',
];
const BORDER_RADIUS = '2px';

export const theme = createMuiTheme(
  {
    typography: {
      fontFamily: ['Inter', 'Verdana', 'sans-serif'].join(','),
      fontSize: 14,
      body1: {
        color: colors.text.body1,
        fontSize: FONT_SIZES[1],
      },
      body2: {
        color: colors.text.body2,
        fontSize: FONT_SIZES[0],
      },
      h1: {
        fontSize: FONT_SIZES[5], // 36px
        fontWeight: 700,
        color: colors.accent.primary,
      },
      h2: {
        fontSize: FONT_SIZES[4], // ${SPACING[3]px}
        fontWeight: 700,
        marginBottom: SPACING[2],
        color: colors.accent.primary,
      },
      h3: {
        fontSize: FONT_SIZES[3], // 18px
        fontWeight: 'bold',
        color: colors.accent.primary,
      },
      h4: {
        fontSize: FONT_SIZES[2], // 16px
        fontWeight: 'bold',
        color: colors.accent.primary,
      },
      h5: {
        fontSize: FONT_SIZES[1], // 16px
        fontWeight: 'bold',
        color: colors.accent.primary,
      },
      button: {
        fontSize: FONT_SIZES[3],
        fontWeight: 'normal',
        textTransform: 'none',
      },
    },
    spacing: SPACING,
    palette: {
      primary: {
        main: colors.text.body1,
      },
      secondary: {
        main: colors.accent.primary,
      },
      text: {
        primary: colors.text.body1,
        secondary: colors.text.body2,
      },
      background: {
        default: colors.background.bg2,
      },
    },
    props: {
      MuiButton: {
        variant: 'contained',
      },
    },

    overrides: {
      MuiFormLabel: {
        root: {
          fontWeight: 600,
          fontSize: '12px',
          color: colors.background.bg2,
          '&$disabled, &$focused': {
            color: colors.text.body1,
          },
        },
        filled: {
          color: colors.text.inverted,
        },
      },
      MuiFormControlLabel: {
        label: {
          '&$disabled': {
            color: colors.background.bg3,
          },
        },
      },
      MuiInputAdornment: {
        positionStart: {
          marginLeft: `${SPACING[1]}px`,
          marginRight: 0,
        },
      },
      MuiTablePagination: {
        select: {
          display: 'none',
        },
      },
      MuiInputLabel: {
        outlined: {
          position: 'relative',
          transform: 'translate(14px, 36px) scale(1)',
          '&$shrink': {
            transform: 'none',
          },
        },
        root: {
          color: colors.text.body1,
        },
        filled: {
          color: colors.text.body2,
        },
        formControl: {
          color: colors.text.body1,
          transform: 'none',
          fontSize: 12,
          fontWeight: 600,
          '& + .MuiInput-formControl': {
            marginTop: '20px',
          },
        },
        shrink: {
          transform: 'none',
        },
      },

      MuiOutlinedInput: {
        adornedStart: {
          paddingLeft: 0,
          '& input': {
            paddingLeft: `${SPACING[1]}px`,
          },
        },
        root: {
          backgroundColor: 'transparent',
          borderColor: colors.stroke,
          border: '1px solid',
          borderRadius: 5,
          '&:hover $notchedOutline': {
            borderStyle: 'none',
          },
        },
        notchedOutline: {
          borderStyle: 'none',
        },
        input: {
          padding: `${SPACING[1]}px ${SPACING[2]}px`,
        },
      },
      MuiRadio: {
        colorSecondary: {
          '&$disabled': {
            color: colors.background.bg3,
          },
        },
      },
      MuiInput: {
        root: {
          color: colors.text.body1,
          padding: `${SPACING[1]}px ${SPACING[2]}px`,
          backgroundColor: colors.background.bg1,
          borderRadius: BORDER_RADIUS,
          borderColor: colors.stroke,
          border: '1px solid',
          '&$focused, &:hover': {
            backgroundColor: colors.background.bg1,
            color: colors.text.body1,
          },
          '&$disabled': {
            backgroundColor: colors.background.bg3,
            color: colors.text.body2,
            borderBottom: '1px solid',
          },
        },
        underline: {
          '&:before': {
            display: 'none',
          },
        },
      },
      MuiFormGroup: {
        root: {
          flexDirection: 'row',
        },
      },
      MuiFormHelperText: {
        root: {
          color: colors.text.body2,
        },
      },
      MuiButton: {
        root: {
          color: colors.text.inverted,
          fontWeight: 400,
          padding: `0px ${SPACING[3]}px`,
          backgroundColor: colors.accent.primary,
          '&:hover': {
            backgroundColor: colors.text.body2,
          },
          '&.Mui-disabled': {
            color: colors.text.body2,
            backgroundColor: colors.background.bg3,
          },
        },
        text: {
          padding: `0px ${SPACING[3]}px`,
          borderRadius: BORDER_RADIUS,
          background: 'transparent',
          color: colors.text.body1,
          '& .MuiButton-label': {
            fontWeight: 400,
            fontSize: FONT_SIZES[0],
          },
          '&.Mui-disabled': {
            background: 'transparent',
            color: colors.text.body2,
          },
        },
        containedPrimary: {
          backgroundColor: colors.accent.primary,
        },
        contained: {
          padding: `0px ${SPACING[3]}px`,
          height: '40px',
          color: colors.text.inverted,
          backgroundColor: colors.accent.primary,
          '&:hover': {
            backgroundColor: darken(0.1, colors.accent.primary),
          },
          '&.Mui-disabled': {
            background: colors.background.bg3,
            color: 'white',
          },
          '& .MuiButton-label': {
            fontWeight: 400,
            fontSize: FONT_SIZES[0],
          },
        },
        outlined: {
          //backgroundColor: 'transparent',
          borderColor: colors.stroke,
          backgroundColor: colors.background.bg1,
          color: colors.text.body1,
          fontWeight: 400,
          '& .MuiButton-label': {
            fontWeight: '400 !important',
            fontSize: '0.85rem',
          },
          '&:hover': {
            backgroundColor: colors.background.bg2,
          },
          '&:disabled': {
            backgroundColor: 'transparent',
            borderColor: colors.text.body2,
          },
        },
        label: {
          fontSize: FONT_SIZES[2],
          fontWeight: 600,
        },
      },
      MuiTableCell: {
        body: {
          borderColor: colors.stroke,
        },
        stickyHeader: {
          backgroundColor: colors.background.bg1,
          color: colors.text.body2,
          // borderStyle: 'none',
          padding: '8px 16px',
          fontWeight: 600,
        },
        footer: {
          fontWeight: 600,
          borderBottom: 'none',
          backgroundColor: colors.background.bg2,
        },
      },
      MuiTableFooter: {},
      MuiLink: {
        root: {
          cursor: 'pointer',
        },
      },
      MuiMenuItem: {
        root: {
          color: colors.text.body1,
        },
      },
      MuiTab: {
        root: {
          fontSize: '0.875rem',
          minWidth: '0 !important',
          padding: '6px 0',
          marginRight: '12px',
          fontWeight: 600,
        },
      },
      MuiSelect: {
        iconOutlined: {},
        outlined: {
          borderColor: colors.stroke,
          border: '1px solid',
          backgroundColor: colors.background.bg1,
          padding: `${SPACING[1]}px ${SPACING[2]}px`,
          '&:hover, &:focus': {
            backgroundColor: colors.background.bg1,
          },

          '&.Mui-disabled': {
            color: colors.text.body2,
            backgroundColor: colors.background.bg1,
          },
        },
      },
      MuiList: {
        root: {
          borderRadius: 0,
        },
      },
      MuiListItem: {
        root: {
          '&.Mui-selected': {
            backgroundColor: colors.background.bg3,
          },
        },
      },
      MuiBreadcrumbs: {
        li: {
          '& a, & a p': {
            color: colors.accent.primary,
          },
          '& > p': {
            color: colors.text.body1,
          },
        },
        separator: {
          color: colors.text.body2,
        },
      },
      MuiDivider: {
        root: {
          backgroundColor: colors.text.body2,
        },
      },
      MuiSwitch: {
        track: {
          backgroundColor: colors.text.body2,
          opacity: 1,
        },
      },
      MuiCheckbox: {
        root: {
          color: colors.text.body1,
        },
        colorSecondary: {
          '&.Mui-checked': {
            color: colors.accent.primary,
          },
          '&.Mui-disabled': {
            color: colors.background.bg3,
          },
        },
      },
      MuiPaper: {
        root: {
          backgroundColor: colors.background.bg1,
          color: colors.text.body1,
          '&:focus': {
            outline: 'none',
          },
        },
      },
      MuiStepper: {
        root: {
          color: colors.text.inverted,
          backgroundColor: colors.accent.primary,
        },
      },
      MuiStepConnector: {
        line: {
          borderColor: colors.text.inverted,
        },
      },
      MuiStepLabel: {
        label: {
          color: colors.text.inverted,
          fontSize: '14px',
          '&$active': {
            fontWeight: 600,
            color: colors.text.inverted,
          },
          '&$completed': {
            color: colors.text.inverted,
          },
        },
        iconContainer: {
          border: '1px solid',
          borderColor: colors.text.inverted,
          color: colors.text.inverted,
          paddingRight: 0,
          marginRight: 12,
          borderRadius: 20,
          '&$active': {
            borderColor: colors.text.inverted,
          },
        },
      },
      MuiStepIcon: {
        text: {
          fill: colors.text.body2,
        },
        root: {
          color: 'transparent',
          '&$active': {
            color: colors.accent.primary,
            '& text': {
              fill: colors.text.inverted,
            },
          },
          '&$completed': {
            color: colors.text.inverted,
          },
        },
        completed: {
          color: colors.text.inverted,
        },
      },
      // @ts-ignore
      MuiAlert: {
        standardError: {
          backgroundColor: colors.red,
          color: colors.text.inverted,
        },
        standardSuccess: {
          backgroundColor: colors.green,
          color: colors.text.inverted,
        },
        icon: {
          color: '#fff !important',
        },
      },
      MuiAutocomplete: {
        outlined: {
          borderColor: colors.stroke,
          border: '1px solid',
          color: colors.text.inverted,
          backgroundColor: colors.background.bg2,
          padding: `${SPACING[1]}px ${SPACING[2]}px`,
          borderRadius: BORDER_RADIUS,

          '&:hover, &:focus': {
            backgroundColor: colors.background.bg1,
          },

          '&.Mui-disabled': {
            color: colors.text.body2,
            backgroundColor: colors.background.bg1,
          },
          height: 40,
        },

        groupLabel: {
          backgroundColor: colors.background.bg1,
          fontWeight: 600,
        },
        input: {
          marginBottom: 6,
        },
      },
      ...PICKER_OVERRIDES,
    },
  },
  fiFI,
);

export const spacing = (size: number) => {
  // @ts-ignore
  return theme.spacing * size;
};

export default theme;
