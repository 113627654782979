import { CUSTOMER_ID } from '../constants';
import { GetEventSeriesDTO, UpdateEventInformationDTO } from '../types/Event';
import { api, getAuthConfig, getPath } from './api';
import AssetService from './AssetService';

const API_PATHS = Object.freeze({
  eventSerie: '/customer/{customerId}/event/',
  publicEventSerie:
    '/customer/{customerId}/public/event-series?skyboxSalesEnabled=true',
  eventSeries: '/customer/{customerId}/event/{itemId}',
  event:
    '/customer/{customerId}/event/{itemId}/{secondaryItemId}/?withTicketInfo=1',
  publicEvent:
    '/customer/{customerId}/public/event-series/{itemId}/events/{secondaryItemId}?withTicketInfo=1&skyboxSalesEnabled=true',
  eventVisibility: '/customer/{customerId}/event/customer',
  eventInformation: '/customer/{customerId}/event',
  updatePrices: 'customer/{customerId}/event/updatePrices/{itemId}',
  setDefaultListsForEvent:
    '/customer/{customerId}/event/{itemId}/{secondaryItemId}/defaultLists',
  skyboxCalendarEvents: '/customer/{customerId}/skybox-events/calendar',
});

class EventServiceModel {
  getEventSerie = async (params?: GetEventSeriesDTO, publicQuery?: boolean) => {
    const path = publicQuery
      ? API_PATHS.publicEventSerie
      : API_PATHS.eventSerie;
    return api.get(getPath(path), getAuthConfig(false, params));
  };

  getEventSeries = async (eventSeriesId: string) => {
    return api.get(
      getPath(API_PATHS.eventSeries, eventSeriesId),
      getAuthConfig(),
    );
  };

  getEvent = async (
    eventSeriesId: string,
    eventId: string,
    publicQuery?: boolean,
  ) => {
    const path = publicQuery ? API_PATHS.publicEvent : API_PATHS.event;
    return api.get(getPath(path, eventSeriesId, eventId), getAuthConfig());
  };

  updateCustomerEventVisibility = async (data: any) =>
    api.put(getPath(API_PATHS.eventVisibility), data);

  getCustomerEventVisibility = async () =>
    api.get(getPath(API_PATHS.eventVisibility), getAuthConfig());

  updateEventInformation = async (data: UpdateEventInformationDTO) => {
    const newData = { ...data };
    if (data.duration !== undefined)
      newData.duration = parseInt(data.duration as unknown as string, 10);

    if (data.newEventBannerAsset !== undefined) {
      if (data.newEventBannerAsset === null) {
        newData.eventBannerAsset = null;
      } else {
        const name = `${CUSTOMER_ID}_${data.newEventBannerAsset.name}`;
        const content = data.newEventBannerAsset;
        const { data: res } = await AssetService.createAsset(name, content);
        newData.eventBannerAsset = res?.results || null;
      }
      newData.newEventBannerAsset = undefined;
    }

    api.put(getPath(API_PATHS.eventInformation), newData, getAuthConfig());
  };

  updatePrices = async (eventId: number) =>
    api.post(getPath(API_PATHS.updatePrices, eventId));

  setDefaultListsForEvent = ({
    eventId,
    eventSeriesId,
  }: {
    eventId: number;
    eventSeriesId: number;
  }) => {
    return api.put(
      getPath(API_PATHS.setDefaultListsForEvent, eventSeriesId, eventId),
      null,
      getAuthConfig(),
    );
  };

  getSkyboxCalendarEvents = (params: TimespanDTO) => {
    return api.get(
      getPath(API_PATHS.skyboxCalendarEvents),
      getAuthConfig(false, params),
    );
  };
}

const EventService = new EventServiceModel();

export default EventService;
