import { Box } from '@material-ui/core';
import { FC } from 'react';
import { SkyboxTicketType } from '../../services/SkyboxService';
import { TicketListItem } from './TicketListItem';

interface TicketListProps {
  tickets: SkyboxTicketType[];
}

export const TicketList: FC<TicketListProps> = ({ tickets }) => {
  return (
    <Box>
      {tickets?.map((ticket) => (
        <TicketListItem ticket={ticket} key={ticket.id} />
      ))}
    </Box>
  );
};
