import { Box, BoxProps, makeStyles, MenuItem } from '@material-ui/core';
import { makeRequired, makeValidate, Select, TextField } from 'mui-rff';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import theme from '../../styles/theme';
import { getCountryOptions } from '../../utils/i18nUtils';
import { Validator } from '../../utils/validation';

interface NewCustomerFormProps {}

interface RowProps extends BoxProps {}

const customerFormValidationSchema = Validator.object().shape({
  firstName: Validator.string().required(),
  lastName: Validator.string().required(),
  address: Validator.string().required(),
  city: Validator.string().required(),
  postalCode: Validator.string().numberString().length(5).required(),
  country: Validator.string().required(),
  email: Validator.string().email().required(),
  phone: Validator.string().phoneNumber().required(),
  search: Validator.string().email(),
});

export const customerFormValidate = makeValidate(customerFormValidationSchema);
const required = makeRequired(customerFormValidationSchema);

const useStyles = makeStyles({
  row: {
    display: 'flex',
    width: '100%',
    '& > *': {
      flex: 1,
      marginRight: theme.spacing(3),
      '&:last-child': {
        marginRight: 0,
      },
    },
  },
});

const Row: FC<RowProps> = ({ children, ...rest }) => {
  const classes = useStyles();
  return (
    <Box className={classes.row} {...rest}>
      {children}
    </Box>
  );
};

export const NewCustomerForm: FC<NewCustomerFormProps> = () => {
  const { t, i18n } = useTranslation();

  const translate = (id: string) => t(`common.customer.${id}`);

  const renderField = (name: string) => (
    <TextField name={name} label={translate(name)} required={required[name]} />
  );

  return (
    <>
      <Row mt={3}>
        {renderField('firstName')}
        {renderField('lastName')}
      </Row>
      <Row mt={3}>{renderField('address')}</Row>
      <Row mt={3}>
        {renderField('city')}
        <Row>
          {renderField('postalCode')}
          <Select
            name="country"
            label={translate('country')}
            required={required.country}
          >
            {getCountryOptions(i18n.language).map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </Row>
      </Row>
      <Row mt={3}>
        {renderField('phone')}
        {renderField('email')}
      </Row>
    </>
  );
};
