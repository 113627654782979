import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Layout } from '../../components/Layout';
import { PATHS } from '../../constants/routes';
import { useStore } from '../../stores';
import ToastStore from '../../stores/ToastStore';
import { SkyboxForm } from './SkyboxForm';

export interface EditSkyboxScreenProps {}

export const EditSkyboxScreen: React.FC<EditSkyboxScreenProps> = observer(
  () => {
    const { skybox, ownerList, isLoading, getSkybox, updateSkybox } =
      useStore('skyboxStore');
    const history = useHistory();

    const { id: idParam }: { id: string } = useParams();

    useEffect(() => {
      getSkybox(idParam);
    }, [getSkybox, idParam]);

    const handleUpdateClick = async (
      formValues: any,
      _form: any,
      cb: Function,
    ) => {
      if (!formValues.featuredAsset && !formValues.newFeaturedAsset) {
        ToastStore.showError('errors.skybox.featuredAssetRequired');
        return;
      }

      const data = {
        ...formValues,
        seatCount: parseInt(formValues.seatCount, 10),
        defaultPrice: parseFloat(formValues.defaultPrice),
        name: formValues.skyboxName,
      };

      delete data.skyboxName;

      await updateSkybox(data, cb);
      history.push(PATHS.skyboxes.main);
    };

    const handleCancelClick = () => {
      history.push(PATHS.skyboxes.main);
    };

    return (
      <Layout headerText={skybox?.name}>
        <SkyboxForm
          onSubmit={handleUpdateClick}
          onCancel={handleCancelClick}
          initialValues={skybox}
          isLoading={isLoading}
          ownerList={ownerList}
        />
      </Layout>
    );
  },
);
