import { Box, Typography } from '@material-ui/core';
import { getPrettyDate, getPrettySum } from '../../utils/i18nUtils';

const getEventCell = (t: any) => ({
  Header: t('reports.list.event'),
  accessor: 'eventDate',
  width: 300,
  Cell: ({ row: { original } }: any) => {
    const date =
      getPrettyDate(original.event?.date, true, t('common.clockAbbr')) || '';
    const title = original.event?.title;
    return (
      <Box>
        <Typography>{title}</Typography>
        <Typography>{date}</Typography>
      </Box>
    );
  },
  PrintCell: ({ row: { original } }: any) => {
    const date =
      getPrettyDate(original.event?.date, true, t('common.clockAbbr')) || '';
    const title = original.event?.title;
    return `${title} ${date}`;
  },
});

const getEventSeriesNameCell = (t: any) => ({
  Header: t('reports.list.eventSeriesName'),
  width: 260,
  Cell: ({ row: { original } }: any) => {
    return original.event?.title ?? '';
  },
});

const getTypeCell = (t: any) => ({
  Header: t('common.usage'),
  accessor: 'type',
  Cell: ({ row: { original } }: any) => {
    let type = '';
    const isChildOrder = !!original?.parent?.purchaseSkyboxes?.length;
    const skybox = original.purchaseSkyboxes && original.purchaseSkyboxes[0];

    if (skybox?.eventSkybox?.type === 'whole_sale') {
      type = 'rented';
    } else if (isChildOrder) {
      return t('reports.childOrder');
    } else {
      type = skybox?.eventSkybox?.type;
    }

    return t(`skybox.type-${type}`);
  },
});

const getTicketCountCell = (t: any) => ({
  Header: t('reports.list.tickets'),
  accessor: 'tickets',
  Cell: ({ row: { original } }: any) => original.tickets?.length,
});

const renderRentCell = ({ row: { original } }: any) => {
  const skybox = original.purchaseSkyboxes && original.purchaseSkyboxes[0];
  let sum = skybox?.price || 0;
  if (skybox?.eventSkybox?.type === 'group_sale') {
    sum = 0;
  }
  return getPrettySum(sum || 0, true);
};

const getMapIdCell = (t: any) => ({
  Header: t('reports.list.id'),
  accessor: 'mapId',
  Cell: ({ row: { original } }: any) => {
    const skybox = original.purchaseSkyboxes && original.purchaseSkyboxes[0];
    const isChildOrder = !!original?.parent?.purchaseSkyboxes?.length;
    if (isChildOrder) {
      return original?.parent?.purchaseSkyboxes[0]?.skyboxInfo?.mapId;
    }
    return skybox?.eventSkybox?.skybox?.mapId || '';
  },
});

export const getOwnerReportColumns = (t: any) => [
  getEventCell(t),
  getMapIdCell(t),
  getEventSeriesNameCell(t),
  getTypeCell(t),
  getTicketCountCell(t),
  {
    Header: t('reports.list.rentIncome'),
    accessor: 'rent',
    Cell: renderRentCell,
  },
];

export const getAdminReportColumns = (t: any) => [
  getEventCell(t),
  getMapIdCell(t),
  {
    Header: t('reports.list.owner'),
    accessor: 'owner',
    Cell: ({ row: { original } }: any) => {
      let skybox = original.purchaseSkyboxes && original.purchaseSkyboxes[0];
      if (!skybox && original?.parent?.purchaseSkyboxes?.length) {
        return (
          original?.parent?.purchaseSkyboxes[0].eventSkybox?.skybox?.company
            ?.name ?? ''
        );
      }
      return skybox?.eventSkybox?.skybox?.company?.name ?? '';
    },
  },
  getEventSeriesNameCell(t),
  getTypeCell(t),
  getTicketCountCell(t),
  {
    Header: t('reports.list.ticketPrice'),
    accessor: 'ticketPrice',
    Cell: ({ row: { original } }: any) => {
      let price = '';
      if (!original.tickets) return '';
      const prices = original.tickets
        .map((ticket: any) => ticket.price)
        .filter((value: any, index: any, self: any) => {
          return self.indexOf(value) === index;
        });
      if (prices.length > 1) {
        price = t('reports.list.variousTickets');
      } else {
        if (prices[0] === undefined) return '';
        price = getPrettySum(prices[0], true);
      }
      return price;
    },
  },
  {
    Header: t('reports.list.rent'),
    accessor: 'rent',
    Cell: renderRentCell,
  },
];
