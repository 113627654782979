import { makeTestIdProps } from '@guinie/react-testid';
import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  TextField,
} from '@material-ui/core';
import { FC } from 'react';
import { Field } from 'react-final-form';
import NumberFormat from 'react-number-format';

interface CurrencyFieldProps {
  label: string;
  name: string;
  validate?: any;
  disabled?: boolean;
  required?: boolean;
  helperText?: string;
}

export const CurrencyField: FC<CurrencyFieldProps> = ({
  disabled,
  label,
  name,
  validate,
  required,
  helperText,
}) => {
  return (
    <FormControl fullWidth>
      <Field
        name={name}
        required={required}
        validate={validate}
        format={(value) => (value === '' ? '' : Number(value / 100))}
        parse={(value) => (value === 0 ? String(value) : value * 100)}
        render={({ input, meta }) => {
          const showError = meta.error && (meta.touched || meta.submitFailed);
          return (
            <>
              <InputLabel error={showError}>
                {label}
                {required && <span> *</span>}
              </InputLabel>
              <Box marginTop="20px">
                <NumberFormat
                  value={input.value}
                  decimalSeparator=","
                  decimalScale={2}
                  fixedDecimalScale
                  onValueChange={(target) => input.onChange({ target })}
                  isNumericString
                  allowedDecimalSeparators={[',', '"']}
                  suffix="€"
                  fullWidth
                  customInput={TextField}
                  disabled={disabled}
                  inputProps={makeTestIdProps(name)}
                />
                {showError && (
                  <FormHelperText error>{meta.error}</FormHelperText>
                )}
                {helperText && <FormHelperText>{helperText}</FormHelperText>}
              </Box>
            </>
          );
        }}
      />
    </FormControl>
  );
};
