import {
  faAngleLeft,
  faAngleRight,
  faPrint,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Button,
  IconButton,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { observer } from 'mobx-react-lite';
import moment from 'moment-timezone';
import React, { useState } from 'react';
import { ToolbarProps } from 'react-big-calendar';
import { useTranslation } from 'react-i18next';
import { useStores } from '../../stores/index';
import { IconTextButton } from '../IconTextButton/IconTextButton';

export interface ReservationCalendarToolbarProps extends ToolbarProps {
  onAddNewClick: () => void;
  onTabChange: (activeTab: number) => void;
  activeTab: number;
}

export const ReservationCalendarToolbar: React.FC<ReservationCalendarToolbarProps> =
  observer(({ onNavigate, date, onAddNewClick, onTabChange, activeTab }) => {
    const {
      authStore: { isOwner, isJanitor },
    } = useStores();
    const { t } = useTranslation();
    const momentDate = moment(date);
    const startDateStr = momentDate.startOf('week').format('L');
    const endDateStr = momentDate.endOf('week').format('L');
    const [calendarValue, setCalendarValue] = useState(date);
    const [calendarOpen, setCalendarOpen] = useState(false);

    const getMonday = (date: Date) => {
      const day = date.getDay() || 7;
      if (day !== 1) date.setHours(-24 * (day - 1));
      return date;
    };

    const onPrevClick = () => {
      onNavigate('PREV');
    };
    const onNextClick = () => {
      onNavigate('NEXT');
    };

    const onMonthClick = () => {
      setCalendarOpen(true);
    };

    const onCalendarChange = (val: any) => {
      const startOfWeek = getMonday(val);
      setCalendarValue(startOfWeek);
      onNavigate('DATE', startOfWeek);
    };

    const renderAddButton = () => {
      if (isJanitor) return null;
      let label = 'addNewEvent';
      if (isOwner) label = 'addNewReservation';

      return (
        <IconTextButton
          type="add"
          onClick={onAddNewClick}
          label={`calendar.${label}`}
        />
      );
    };

    const onTabClick = (_e: any, index: number) => {
      onTabChange(index);
    };

    const onPrintClick = () => {
      window.print();
    };

    return (
      <Box>
        <Box
          mb={1}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          className="no-print"
        >
          <Box>
            <IconButton onClick={onPrevClick}>
              <FontAwesomeIcon icon={faAngleLeft} />
            </IconButton>
            <Button variant="text" onClick={onMonthClick}>
              <Typography variant="h3">
                {startDateStr}–{endDateStr}
              </Typography>
            </Button>
            <IconButton onClick={onNextClick}>
              <FontAwesomeIcon icon={faAngleRight} />
            </IconButton>
            <KeyboardDatePicker
              onChange={onCalendarChange}
              value={calendarValue}
              onOpen={() => setCalendarOpen(true)}
              onClose={() => setCalendarOpen(false)}
              TextFieldComponent={() => null}
              open={calendarOpen}
            />
          </Box>
          <Box display="flex">
            {renderAddButton()}
            <Box ml={1}>
              <Button
                variant="outlined"
                onClick={onPrintClick}
                style={{ height: 40 }}
              >
                <FontAwesomeIcon icon={faPrint} />
                <Box ml={1}>
                  <Typography>{t(`common.print`)}</Typography>
                </Box>
              </Button>
            </Box>
          </Box>
        </Box>
        <Box className="print-area" mb={3}>
          <Typography variant="h3">
            {startDateStr}–{endDateStr}
          </Typography>
        </Box>
        {!isOwner && (
          <Box mb={3} className="no-print">
            <Tabs onChange={onTabClick} value={activeTab}>
              <Tab label={t('calendar.tabs.calendar')} />
              <Tab label={t('calendar.tabs.reservations')} />
            </Tabs>
          </Box>
        )}
      </Box>
    );
  });
