import { Box } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { OrderLayout, OrderLayoutState } from '../../components/Layout';
import { TicketDeliveryTable } from '../../components/TicketDeliveryTable/TicketDeliveryTable';
import { useStores } from '../../stores/index';

export const OrderSendTicketsScreen: React.FC = observer(() => {
  const history = useHistory();

  const {
    skyboxStore: { skyboxOrder },
    orderStore: { order },
  } = useStores();

  const { livexOrderNumber }: { livexOrderNumber: string } = useParams();
  const { state } = history.location as { state: OrderLayoutState };
  const { showChildOrder } = state;

  const tickets = useMemo(() => {
    if (showChildOrder) {
      return order?.tickets;
    }
    return skyboxOrder?.purchase?.tickets as ITicketPurchase[];
  }, [order, showChildOrder, skyboxOrder]);

  const id = useMemo(() => {
    if (showChildOrder) {
      return order?.id;
    }
    return skyboxOrder?.purchase?.id;
  }, [order, showChildOrder, skyboxOrder]);

  return (
    <OrderLayout
      livexOrderNumber={livexOrderNumber}
      showChildOrder={showChildOrder}
      id={id}
    >
      <Box>
        <Box p={2}>
          {id && tickets?.length && (
            <TicketDeliveryTable tickets={tickets} purchaseId={id} />
          )}
        </Box>
      </Box>
    </OrderLayout>
  );
});
