export const getSkyboxTypeSelectOptions = (
  t: Function,
  includeEmpty?: boolean,
) => {
  const skyboxTypeOptions = [
    { value: 'whole_sale', label: t('skybox.type-whole_sale') },
    {
      value: 'conference_use_only',
      label: t('skybox.type-conference_use_only'),
    },
  ];

  if (includeEmpty)
    skyboxTypeOptions.unshift({ value: '', label: t('common.select') });
  return skyboxTypeOptions;
};

export const getSkyboxStatusSelectOptions = (
  t: Function,
  includeEmpty?: boolean,
) => {
  const items = [
    { value: 'true', label: t('common.skyboxOrderStatus.bookable') },
    { value: 'false', label: t('common.skyboxOrderStatus.unbookable') },
  ];
  // @ts-ignore
  if (includeEmpty)
    items.unshift({ value: '', label: t('common.skyboxOrderStatus.empty') });
  return items;
};
