import {
  Box,
  Button,
  CircularProgress,
  Grid,
  TextField as MuiTextField,
  Typography,
} from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { makeRequired, makeValidate, Select, TextField } from 'mui-rff';
import { FC, useEffect, useMemo, useState } from 'react';
import { Form, FormSpy } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import validator from 'validator';
import { useStores } from '../../stores/index';
import { Validator } from '../../utils/validation';
import { DateTimePicker } from '../DateTimePicker/DateTimePicker';
import { GuestList } from '../GuestList/GuestList';
import { CommonModal } from '../Modals';
import { CommonModalProps } from '../Modals/CommonModal';
import { IReservationSlot } from './ReservationCalendar';
import { ReservationCalendarWarning } from './ReservationCalendarWarning';

export interface ReservationCalendarReservationModalProps
  extends Omit<CommonModalProps, 'children' | 'type' | 'onClose' | 'open'> {
  handleClose: (fetchData?: boolean) => void;
  selectedSlot?: IReservationSlot;
  selectedReservation?: IBigCalendarReservation;
}

export const ReservationCalendarReservationModal: FC<ReservationCalendarReservationModalProps> =
  observer(({ handleClose, selectedSlot, selectedReservation }) => {
    const [defaultValues, setDefaultValues] = useState<any>();
    const [initialized, setInitialized] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [prevFormValues, setPrevFormValues] = useState<any>({});
    const { t } = useTranslation();
    const {
      skyboxStore: { getMyActiveSkyboxes, myActiveSkyboxes },
      userStore: { getUserList, users },
      authStore: { currentUser, isAdmin },
      calendarStore: {
        createCalendarReservation,
        updateCalendarReservation,
        checkCalendarSlotForReservation,
        calendarSlotValid,
        deleteCalendarReservation,
        getBlockedSkyboxes,
        blockedSkyboxes,
      },
    } = useStores();

    const FIELDS = useMemo(() => {
      return {
        skybox: 'skyboxId',
        organizer: 'userId',
        startDate: 'startDate',
        endDate: 'endDate',
        message: 'message',
      };
    }, []);
    const schema = Validator.object().shape({
      skyboxId: Validator.string().required(),
      userId: Validator.string().optional(),
      startDate: Validator.date().required(),
      endDate: Validator.date()
        .required()
        .min(Validator.ref(FIELDS.startDate), () =>
          t('validation.startDateBeforeEndDate'),
        ),
      message: Validator.string().optional(),
    });

    useEffect(() => {
      if (!initialized) {
        getMyActiveSkyboxes();
        getUserList();
        setInitialized(true);
      }
    }, [getMyActiveSkyboxes, initialized, setInitialized, getUserList]);

    const warning = useMemo(() => {
      if (typeof calendarSlotValid === 'object') {
        return calendarSlotValid.type;
      }
      return undefined;
    }, [calendarSlotValid]);

    useEffect(() => {
      if (selectedSlot) {
        setDefaultValues({
          [FIELDS.startDate]: selectedSlot.start,
          [FIELDS.endDate]: selectedSlot.end,
          [FIELDS.organizer]: currentUser?.id,
        });
      } else if (selectedReservation) {
        const guests = {} as any;
        selectedReservation.guests.map((guest, index) => {
          guests[`guest${index}`] = guest.email;
          return null;
        });
        setDefaultValues({
          ...selectedReservation,
          startDate: selectedReservation?.startDate.toISOString(),
          endDate: selectedReservation?.endDate.toISOString(),
          guests,
        });
      }
    }, [
      setDefaultValues,
      currentUser,
      selectedSlot,
      selectedReservation,
      FIELDS,
    ]);

    const userSelectData = useMemo(() => {
      const userSelectValues = users?.map((user) => ({
        value: user.id,
        label: `${user.firstName} ${user.lastName}`,
      }));
      userSelectValues?.unshift({ value: '', label: t('common.select') });
      return userSelectValues || [];
    }, [users, t]);

    const validate = makeValidate(schema);
    const required = makeRequired(schema);

    /**
     * User presses save
     */
    const onSubmit = async (values: any) => {
      const newGuests = values.guests
        ? Object.values(values.guests).filter((g) => g)
        : [];
      const valuesWithGuestArray = { ...values, ...{ guests: newGuests } };
      if (!selectedReservation) {
        await createCalendarReservation(valuesWithGuestArray);
      } else {
        await updateCalendarReservation(
          selectedReservation.id,
          valuesWithGuestArray,
        );
      }
      handleClose(true);
    };

    /**
     * Validates guest email
     */
    const validateEmail = (value: string) => {
      if (!value) return false;
      return validator.isEmail(value) ? false : 'validation.email';
    };

    const hasDateChanged = (values: any) => {
      return (
        values[FIELDS.startDate] &&
        values[FIELDS.endDate] &&
        (prevFormValues[FIELDS.startDate] !== values[FIELDS.startDate] ||
          prevFormValues[FIELDS.endDate] !== values[FIELDS.endDate])
      );
    };

    const onFormChange = async ({ values, valid }: any) => {
      if (isAdmin) return;
      if (hasDateChanged(values)) {
        getBlockedSkyboxes(values[FIELDS.startDate], values[FIELDS.endDate]);
      }
      if (
        values[FIELDS.skybox] &&
        valid &&
        (prevFormValues[FIELDS.skybox] !== values[FIELDS.skybox] ||
          hasDateChanged(values))
      ) {
        await checkCalendarSlotForReservation({
          startDate: values[FIELDS.startDate],
          endDate: values[FIELDS.endDate],
          skyboxId: values[FIELDS.skybox],
          ignoreId: selectedReservation?.id,
        });
      }
      setPrevFormValues(values);
    };

    const onDeleteClick = async () => {
      if (!selectedReservation) return;
      setDeleting(true);
      await deleteCalendarReservation(selectedReservation.id);
      setDeleting(false);
      handleClose(true);
    };

    const myBlockedSkyboxes = useMemo(() => {
      const blockedSkyboxIds = blockedSkyboxes?.map((skybox) => skybox.id);
      return myActiveSkyboxes?.filter((skybox) => {
        return blockedSkyboxIds?.includes(skybox.id);
      });
    }, [blockedSkyboxes, myActiveSkyboxes]);

    const skyboxSelectData = useMemo(() => {
      const blockedSkyboxIds = blockedSkyboxes?.map((skybox) => skybox.id);
      const myUnblockedSkyboxes = myActiveSkyboxes?.filter((skybox) => {
        return !blockedSkyboxIds?.includes(skybox.id);
      });
      const options = myUnblockedSkyboxes?.map((skybox) => ({
        value: skybox.id,
        label: skybox.name,
      }));
      options?.unshift({ value: '', label: t('common.select') });
      return options || [];
    }, [myActiveSkyboxes, t, blockedSkyboxes]);

    const blockedSkyboxesStr = useMemo(() => {
      if (!myBlockedSkyboxes) return '';
      const skyboxes = myBlockedSkyboxes.map((box) => box.name).join(', ');
      if (myBlockedSkyboxes?.length > 1)
        return t('calendar.reservationModal.skyboxesBlocked', { skyboxes });
      return t('calendar.reservationModal.skyboxBlocked', { skybox: skyboxes });
    }, [myBlockedSkyboxes, t]);

    return (
      <CommonModal open type="normal">
        <>
          {!!myBlockedSkyboxes?.length && (
            <ReservationCalendarWarning>
              {blockedSkyboxesStr}
            </ReservationCalendarWarning>
          )}
          {warning && <ReservationCalendarWarning type={warning} />}
          <Form
            initialValues={defaultValues}
            validate={validate}
            onSubmit={onSubmit}
            render={({ handleSubmit, form, values, hasValidationErrors }) => {
              let label = 'updateReservation';
              if (!selectedReservation) label = 'addNewReservation';

              return (
                <form onSubmit={handleSubmit} noValidate>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    {!isAdmin && (
                      <Typography variant="h2">
                        {t(`calendar.reservationModal.${label}`)}
                      </Typography>
                    )}
                    {selectedReservation && (
                      <Box display="flex">
                        {deleting && (
                          <Box mr={2}>
                            <CircularProgress />
                          </Box>
                        )}
                        {!isAdmin && (
                          <Button
                            disabled={deleting}
                            variant="outlined"
                            onClick={onDeleteClick}
                          >
                            {t('calendar.reservationModal.deleteReservation')}
                          </Button>
                        )}
                      </Box>
                    )}
                  </Box>

                  <Box my={3}>
                    <Typography variant="h3">
                      {t('calendar.reservationModal.reservationDetails')}
                    </Typography>
                  </Box>
                  <Grid container spacing={3}>
                    <Grid xs={7} item>
                      <Box mb={3}>
                        {!isAdmin && (
                          <Select
                            disabled={isAdmin}
                            name={FIELDS.skybox}
                            required={required[FIELDS.skybox]}
                            label={t('common.skybox')}
                            data={skyboxSelectData}
                            displayEmpty
                          />
                        )}
                        {isAdmin && (
                          <MuiTextField
                            label={t('common.skybox')}
                            name={FIELDS.skybox}
                            value={selectedReservation?.skybox?.name}
                            fullWidth
                            disabled
                          />
                        )}
                      </Box>

                      <Box mb={3}>
                        <Select
                          disabled={isAdmin}
                          name={FIELDS.organizer}
                          required={required[FIELDS.organizer]}
                          label={t('calendar.reservationModal.organizer')}
                          data={userSelectData}
                          displayEmpty
                        />
                      </Box>

                      <Grid container spacing={3}>
                        <Grid item xs={6}>
                          <DateTimePicker
                            disabled={isAdmin}
                            required={required[FIELDS.startDate]}
                            name={FIELDS.startDate}
                            label={t('calendar.reservationModal.startDate')}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <DateTimePicker
                            disabled={isAdmin}
                            required={required[FIELDS.endDate]}
                            name={FIELDS.endDate}
                            label={t('calendar.reservationModal.endDate')}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid xs={5} item>
                      <TextField
                        fullWidth
                        multiline
                        rows={12}
                        label={t('calendar.reservationModal.message')}
                        name={FIELDS.message}
                        helperText={t(
                          'calendar.reservationModal.messageHelper',
                        )}
                        disabled={isAdmin}
                      />
                    </Grid>
                  </Grid>

                  {users && (
                    <GuestList
                      name="guests"
                      form={form}
                      values={values}
                      reservation={selectedReservation}
                      validate={validateEmail}
                      organizer={
                        users.find((user) => user.id === values.userId) ||
                        currentUser
                      }
                    />
                  )}

                  <Box mt={3}>
                    <Box mt={1} display="flex" alignItems="center">
                      {!isAdmin && (
                        <Box mr={1}>
                          <Button
                            type="submit"
                            disabled={
                              hasValidationErrors || isAdmin || !!warning
                            }
                          >
                            {' '}
                            {t('common.save')}
                          </Button>
                        </Box>
                      )}
                      <Button
                        variant="outlined"
                        onClick={() => handleClose(false)}
                      >
                        {t(isAdmin ? 'common.close' : 'common.cancel')}
                      </Button>
                      <Box mr={2} />
                      {!selectedReservation && (
                        <Typography>
                          {t('calendar.reservationModal.submitHelper')}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                  <FormSpy
                    onChange={onFormChange}
                    subscription={{ values: true, valid: true }}
                  />
                </form>
              );
            }}
          />
        </>
      </CommonModal>
    );
  });
