/* eslint-disable react-hooks/exhaustive-deps */
import { Box, FormLabel, Grid, makeStyles } from '@material-ui/core';
import { FormState } from 'final-form';
import { observer } from 'mobx-react-lite';
import { TextField } from 'mui-rff';
import { FC, useCallback, useEffect, useState } from 'react';
import { Form, FormSpy, FormSpyProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useStores } from '../../../../stores/index';
import { CommonModal } from '../../CommonModal';
import { TicketPersonalizationContent } from './TicketPersonalizationContent';

interface PersonalizationModalProps {
  isOpen: boolean;
  onConfirmClick: () => void;
  onCancelClick: () => void;
  loading: boolean;
}

const useStyles = makeStyles({
  // content / overlay used by react-modal
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 0,
    maxWidth: 936,
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    zIndex: 3,
  },
  bodyText: {},
  footer: {
    justifyContent: 'flex-end',
  },
  formContainer: {
    minWidth: 60,
  },
});

type ModalType =
  | 'TicketPersonalization'
  | 'OrderPersonalization'
  | 'OrderComment';

export const PersonalizationModal: FC<PersonalizationModalProps> = observer(
  ({ isOpen, onConfirmClick, onCancelClick }) => {
    const FORM_ID = 'personalization';
    interface Values {
      [key: string]: any;
    }
    const { t } = useTranslation();
    const [initialValues, setInitialValues] = useState<Values>();
    const classes = useStyles();
    const [options, setOptions] = useState({
      title: '',
      submitCallback: null as any,
    });
    const [formState, setFormState] = useState<FormState<FormSpyProps>>();

    const {
      cartStore: {
        addPersonalization,
        personalizationData,
        personalizationFields,
        cart,
      },
      eventStore: { event },
    } = useStores();

    const getModalType = (): ModalType | null => {
      if (!event) return null;
      const {
        eventState: {
          PersonalizationMandatoryPerOrder,
          PersonalizationMandatoryPerTicket,
          OrderCommentMandatory,
        },
      } = event;
      if (PersonalizationMandatoryPerOrder) return 'OrderPersonalization';
      if (PersonalizationMandatoryPerTicket) return 'TicketPersonalization';
      if (OrderCommentMandatory) return 'OrderComment';
      return null;
    };

    useEffect(() => {
      if (!initialValues && personalizationData) {
        if (getModalType() === 'TicketPersonalization') {
          const initialFormData = {} as any;
          personalizationData.tickets?.map((ticket: any) => {
            initialFormData[ticket.ticketId] = ticket;
            return null;
          });
          setInitialValues(initialFormData);
          //   setInitialValues(personalizationData);
        } else if (getModalType() === 'OrderComment') {
          setInitialValues(personalizationData);
        } else if (getModalType() === 'OrderPersonalization') {
          setInitialValues(personalizationData.personalization);
        }
      }
    }, [personalizationData, getModalType()]);

    const getPersonalizationFields = (id?: string) => {
      if (!personalizationFields) return null;

      const keys = Object.keys(personalizationFields);
      return (
        <Box mt={3}>
          <Grid container spacing={2}>
            {keys.map((name) => {
              const fieldName = id ? `${id}.${name}` : name;
              return (
                <Grid item xs={6}>
                  <TextField
                    key={fieldName}
                    name={fieldName}
                    required={true}
                    label={t(`common.personalizationFields.${name}`)}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Box>
      );
    };

    /**
     * Renders modal content when eventState.PersonalizationMandatoryPerPrder=== true
     */
    const renderOrderPersonalizationContent = () => {
      return (
        <>
          {t('orders.add.personalizationModal.orderPersonalization.body')}
          {getPersonalizationFields()}
        </>
      );
    };

    /**
     * Renders modal content when eventState.OrderCommentMandatory === true
     */
    const renderOrderCommentContent = () => {
      return (
        <Box>
          <FormLabel>{event!.orderCommentaryQuestion}</FormLabel>
          <TextField name="comment" required />
        </Box>
      );
    };

    /**
     * When submitting the form
     */
    const onTicketPersonalizationSubmit = async (values: any) => {
      const tickets = Object.keys(values)
        .map((id) => ({
          ticketId: id,
        }))
        .map((t) => ({ ...t, ...values[t.ticketId] }));
      try {
        await addPersonalization({ tickets }, 'TicketPersonalization');
      } catch {
        return;
      }
      onConfirmClick();
    };

    const onOrderCommentSubmit = async (values: any) => {
      await addPersonalization(values, 'OrderComment');
      onConfirmClick();
    };

    const onOrderPersonalizationSubmit = async (values: any) => {
      const obj = {
        personalization: values,
      };
      try {
        await addPersonalization(obj, 'OrderPersonalization');
      } catch {
        return;
      }
      onConfirmClick();
    };

    useEffect(() => {
      if (getModalType() === 'OrderPersonalization') {
        setOptions({
          title: 'orders.add.personalizationModal.orderPersonalization.title',
          submitCallback: onOrderPersonalizationSubmit,
        });
      }
      if (getModalType() === 'OrderComment') {
        setOptions({
          title: 'orders.add.personalizationModal.orderComment.title',
          submitCallback: onOrderCommentSubmit,
        });
      }
      if (getModalType() === 'TicketPersonalization') {
        setOptions({
          title: 'orders.add.personalizationModal.ticketPersonalization.title',
          submitCallback: onTicketPersonalizationSubmit,
        });
      }
    }, [getModalType()]);

    const onFormChange = (props: FormState<FormSpyProps>) => {
      setFormState(props);
    };

    const onSubmit = () => {
      const { values } = formState as any;
      options.submitCallback(values);
    };

    const fakeSubmit = () => {};

    const validateForm = useCallback(
      (values: any) => {
        const fieldNames = personalizationFields
          ? Object.keys(personalizationFields)
          : [];
        const errors = {} as any;
        if (getModalType() !== 'TicketPersonalization') {
          fieldNames?.forEach((fieldName) => {
            if (!values[fieldName])
              errors[fieldName] = t('validation.required');
          });
        }
        if (getModalType() === 'TicketPersonalization') {
          cart?.tickets
            .map((ticket) => ticket.id)
            .forEach((ticketId) => {
              fieldNames?.forEach((fieldName) => {
                if (!values[ticketId] || !values[ticketId][fieldName]) {
                  if (!errors[ticketId]) errors[ticketId] = {};
                  errors[ticketId][fieldName] = t('validation.required');
                }
              });
            });
        }

        return errors;
      },
      [cart, getModalType(), personalizationFields],
    );

    return (
      <CommonModal
        open={isOpen}
        onConfirm={onSubmit}
        confirmDisabled={formState?.invalid}
        onCancel={onCancelClick}
        title={t(options.title)}
      >
        <Box className={classes.formContainer}>
          {options.submitCallback && (
            <Form
              initialValues={initialValues}
              onSubmit={fakeSubmit}
              validate={validateForm}
              render={({ handleSubmit }) => (
                <form id={FORM_ID} onSubmit={handleSubmit}>
                  {getModalType() === 'TicketPersonalization' && (
                    <TicketPersonalizationContent
                      formState={formState}
                      getPersonalizationFields={getPersonalizationFields}
                    />
                  )}
                  {getModalType() === 'OrderPersonalization' &&
                    renderOrderPersonalizationContent()}
                  {getModalType() === 'OrderComment' &&
                    renderOrderCommentContent()}
                  <FormSpy onChange={onFormChange} />
                </form>
              )}
            />
          )}
        </Box>
      </CommonModal>
    );
  },
);
