import { makeTestIdProps } from '@guinie/react-testid';
import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, { ReactNode, useEffect } from 'react';
import { useStores } from '../../stores';
import colors from '../../styles/colors';
import theme from '../../styles/theme';
import { NotificationBar } from '../NotificationBar';
import { Seo } from '../Seo/Seo';
import Spinner from './MainSpinner';
import SideMenu from './SideMenu';
import TopBar from './TopBar';
interface LayoutProps {
  headerText?: string;
  headerElementsRight?: ReactNode;
  headerElementsBottom?: ReactNode;
  headerElementsTop?: ReactNode;
  customHeader?: ReactNode;
}

const useStyles = makeStyles({
  layout: {
    color: colors.text.body1,
    backgroundColor: colors.background.bg2,
  },
  content: {
    height: '100%',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  pageHeader: {
    paddingTop: theme.spacing(3),
    color: colors.accent.primary,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  pageHeaderMainContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headerElements: {
    display: 'flex',
    width: 'auto',
    minWidth: 0,
    justifyContent: 'space-between',
  },
  headerElementsRight: {
    display: 'flex',
    width: 'auto',
    minWidth: 0,
    justifyContent: 'flex-end',
  },
  contentContainer: {
    flex: '1 1 auto',
    minHeight: 'auto',
    height: 'auto',
    margin: theme.spacing(3),
    backgroundColor: colors.background.bg1,
    position: 'relative',
    borderRadius: 5,
    boxShadow: '4px 4px 8px rgba(0, 16, 60, 0.1)',
  },
  headerText: {},
});

export const Layout: React.FC<LayoutProps> = observer(
  ({
    headerText,
    headerElementsRight,
    headerElementsTop,
    headerElementsBottom,
    children,
    customHeader,
  }) => {
    const {
      authStore: { currentUser, isOwner },
      customerStore: { currentCustomer, getCurrentCustomer },
      bulletinStore: { getBulletins, isLoading: bulletinsLoading },
    } = useStores();
    const classes = useStyles();

    useEffect(() => {
      if (!currentCustomer) {
        getCurrentCustomer();
      }
    }, [currentCustomer, getCurrentCustomer]);

    useEffect(() => {
      if (isOwner && !bulletinsLoading) getBulletins();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOwner]);

    const renderNonAuthContent = () => {
      return (
        <Grid id="content" item xs={12} className={classes.content}>
          {children}
        </Grid>
      );
    };

    const renderAuthContent = () => {
      return (
        <>
          <TopBar />

          <Box mt="60px">
            <Box display="flex" flex="1 1">
              <SideMenu />

              <Box id="content" className={classes.content} component="main">
                <NotificationBar />

                {customHeader}
                <div className={classes.contentContainer}>
                  {headerText && (
                    <Box px={3}>
                      <div className={classes.pageHeader}>
                        {headerElementsTop}
                        <Box className={classes.pageHeaderMainContent}>
                          <Typography
                            className={classes.headerText}
                            variant="h1"
                            {...makeTestIdProps(`page-title__${headerText}`)}
                          >
                            {headerText}
                          </Typography>
                          {headerElementsRight && (
                            <div className={classes.headerElements}>
                              <div className={classes.headerElementsRight}>
                                {headerElementsRight}
                              </div>
                            </div>
                          )}
                        </Box>
                      </div>
                      {headerElementsBottom}
                    </Box>
                  )}
                  <Spinner />
                  {children}
                </div>
              </Box>
            </Box>
          </Box>
        </>
      );
    };

    return (
      <>
        <Box id="layout" className={classes.layout}>
          {currentUser ? renderAuthContent() : renderNonAuthContent()}
        </Box>
        <Seo />
      </>
    );
  },
);
