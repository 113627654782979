import { Box } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Tabs, TabType } from '../../Tabs/Tabs';
import { Layout } from '../Layout';

interface TabbedLayoutProps {
  headerElementsTop?: any;
  headerElementsRight?: any;
  headerElementsBottom?: any;
  title?: string;
  tabs: TabType[];
}

export const TabbedLayout: React.FC<TabbedLayoutProps> = observer(
  ({ children, headerElementsRight, title, tabs, ...rest }) => {
    const Header = (
      <>
        <Box p={3}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            {!!tabs?.length && <Tabs items={tabs} />}
            {headerElementsRight}
          </Box>
        </Box>
      </>
    );

    return (
      <Layout headerText={title} {...rest}>
        {Header}
        {children}
      </Layout>
    );
  },
);
