import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Layout } from '../../components/Layout';
import { useStore } from '../../stores';
import { OwnerForm } from './OwnerForm';

export const AddOwnerScreen: FC = observer(() => {
  const { isLoading, createOwner, createOwnerUser, deleteOwnerUser } =
    useStore('ownerStore');
  const { t } = useTranslation();
  const history = useHistory();

  const handleCreateClick = async (
    formValues: any,
    _form: any,
    cb: Function,
  ) => {
    const data = {
      ...formValues,
    };
    await createOwner(data, cb);
    history.push('/owners');
  };

  const handleCancelClick = () => {
    history.push('/owners');
  };

  return (
    <Layout headerText={t('owner.newOwner')}>
      <OwnerForm
        onSubmit={handleCreateClick}
        onCancel={handleCancelClick}
        onAddOwnerUser={createOwnerUser}
        onDeleteOwnerUser={deleteOwnerUser}
        isLoading={isLoading}
      />
    </Layout>
  );
});
