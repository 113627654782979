import { makeStyles } from '@material-ui/core';
import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { NavMenuItem } from './NavMenuItem';

export type NavMenuItemType = {
  href: string;
  label: string;
  isActive: boolean;
  iconComponent?: any;
  hasNotification: boolean;
};
export interface WrappedNavMenuProps {
  items: NavMenuItemType[];
  collapsed: boolean;
  navigateTo: (item: NavMenuItemType) => void;
}

export interface NavMenuProps {
  items: NavMenuItemType[];
  collapsed: boolean;
}

const useStyles = makeStyles({
  menuList: {
    width: '100%',
    listStyle: 'none',
    paddingLeft: 0,
    position: 'sticky',
  },
});

export const WrappedNavMenu: FC<WrappedNavMenuProps> = ({
  items,
  navigateTo,
  collapsed,
}) => {
  const classes = useStyles();

  return (
    <ul className={classes.menuList}>
      {items.map((item) => (
        <NavMenuItem
          navigateTo={navigateTo}
          key={item.href}
          item={item}
          collapsed={collapsed}
        />
      ))}
    </ul>
  );
};

export const NavMenu: FC<NavMenuProps> = ({ items, collapsed }) => {
  const history = useHistory();
  const navigateTo = (item: any) => {
    history.push(item.href);
  };

  return (
    <WrappedNavMenu
      collapsed={collapsed}
      navigateTo={navigateTo}
      items={items}
    />
  );
};
