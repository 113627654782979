/* eslint-disable max-len */
/* eslint-disable implicit-arrow-linebreak */
import { api, getPath } from './api';

export const API_PATHS = Object.freeze({
  create: '/customer/{customerId}/asset',
});

class AssetServiceModel {
  createAsset = async (name: string, content: any) => {
    const formData = new FormData();
    formData.append('file', content);
    formData.append('name', name);
    formData.append('preview', '');
    return api.post(getPath(API_PATHS.create), formData, {
      headers: { 'content-type': 'multipart/form-data' },
    });
  };
}

const AssetService = new AssetServiceModel();

export default AssetService;
