import { observer } from 'mobx-react-lite';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DocumentsLayout } from '../../components/Layout/hocs/DocumentsLayout';
import { useStores } from '../../stores/index';

export const DocumentsServiceInformationScreen: FC = observer(() => {
  const { i18n } = useTranslation();

  const {
    commonStore: { documents, getDocument },
  } = useStores();

  useEffect(() => {
    getDocument('skyboxAdminServiceInformation', i18n.language);
  }, [i18n, getDocument]);

  return <DocumentsLayout content={documents.skyboxAdminServiceInformation} />;
});
