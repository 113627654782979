import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Layout } from '../../components/Layout';
import { PATHS } from '../../constants/routes';
import { useStores } from '../../stores/index';
import { BulletinForm } from './BulletinForm';

export interface CreateBulletinScreenProps {}

export const CreateBulletinScreen: FC<CreateBulletinScreenProps> = observer(
  () => {
    const {
      bulletinStore: { createBulletin },
    } = useStores();
    const { t } = useTranslation();
    const history = useHistory();

    const onSubmit = async (values: any) => {
      await createBulletin(values);
      navigateToList();
    };

    const navigateToList = () => history.push(PATHS.bulletins.main);

    return (
      <Layout headerText={t('bulletin.addNew')}>
        <BulletinForm
          onSubmit={onSubmit}
          onCancel={navigateToList}
          title={t('bulletin.addNew')}
        />
      </Layout>
    );
  },
);
