import colors from './colors';

export const PICKER_OVERRIDES = {
  MuiPickersToolbar: {
    toolbar: {
      backgroundColor: colors.accent.primary,
    },
  },
  MuiPickersCalendarHeader: {
    switchHeader: {
      padding: 8,
    },
  },
  MuiPickersDay: {
    day: {
      color: colors.text.body1,
    },
    daySelected: {
      backgroundColor: colors.accent.primary,
    },
    dayDisabled: {
      color: colors.text.body2,
    },
    current: {
      color: 'white',
    },
  },
  MuiPickersModal: {
    dialogAction: {
      color: colors.accent.primary,
    },
  },
};
