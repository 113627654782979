import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
} from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import moment from 'moment-timezone';
import { KeyboardDatePicker, Radios, Select } from 'mui-rff';
import { FC, useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { EventPicker } from '../../components/EventPicker';
import { useStores } from '../../stores/index';
import { getPrettyDate } from '../../utils/i18nUtils';

const EARLIEST_START_DATE = '2022-07-01';

interface ReportFormProps {
  onSubmit: any;
  submitText: string;
}

export const ReportForm: FC<ReportFormProps> = observer(
  ({ onSubmit, submitText }) => {
    const { t } = useTranslation();
    const [eventIds, setEventIds] = useState<any>();

    const {
      eventStore: { singleEventSeries, event },
      customerStore: { currentCustomer },
      authStore: { isOwner },
      reportStore: { isLoading },
      settingsStore: { myCompanyData },
      skyboxStore: {
        getMyActiveSkyboxes,
        myActiveSkyboxes,
        getSkyboxList,
        skyboxListPage,
      },
    } = useStores();

    const DATE_RANGES = [
      'today',
      'week',
      'month',
      'quarter',
      'year',
      'period',
    ].map((option) => ({
      value: option,
      label: t(`reports.dateRanges.${option}`),
    }));

    const EVENT_QUERY_TYPES = ['all', 'single'].map((option) => ({
      value: option,
      label: t(`reports.eventQueryTypes.${option}`),
    }));

    const SKYBOX_QUERY_TYPES = ['all', 'single'].map((option) => ({
      value: option,
      label: t(`reports.skyboxQueryTypes.${option}`),
    }));

    const CUSTOMER_TYPE = ['userOnly', 'adminOnly'].map((option) => ({
      value: option,
      label: t(`reports.customerType.${option}`),
    }));

    const initialValues = {
      dateRange: DATE_RANGES[0]!.value,
      eventQueryType: EVENT_QUERY_TYPES[0]!.value,
      skyboxQueryType: SKYBOX_QUERY_TYPES[0]!.value,
      customerType: CUSTOMER_TYPE[0]!.value,
    };

    useEffect(() => {
      if (isOwner) {
        getMyActiveSkyboxes();
      } else {
        getSkyboxList();
      }
    }, [getMyActiveSkyboxes, isOwner, getSkyboxList]);

    const skyboxSelectOptions = isOwner ? myActiveSkyboxes : skyboxListPage;

    const getTimeRange = ({ dateRange, startDate, endDate }: any) => {
      let start = moment().startOf('day');
      let end = moment().endOf('day');

      switch (dateRange) {
        case 'today':
          break;
        case 'week':
          start = moment().startOf('isoWeek').startOf('day');
          break;
        case 'month':
          start = moment().startOf('month').startOf('day');
          break;
        case 'quarter':
          start = moment().startOf('quarter');
          endDate = moment().endOf('quarter');
          break;
        case 'year':
          start = moment().startOf('year');
          break;
        case 'period':
          start = moment(startDate).startOf('day');
          end = moment(endDate).endOf('day');
          break;
        default:
          break;
      }
      return {
        startDate: start.toISOString(),
        endDate: end.toISOString(),
      };
    };

    const renderDateFields = () => {
      const FIELDS = ['startDate', 'endDate'];
      return (
        <Box mt={2} mb={5}>
          <Grid container spacing={3}>
            {FIELDS.map((field) => (
              <Grid key={field} item>
                <KeyboardDatePicker
                  label={t(`reports.${field}`)}
                  name={field}
                  minDate={new Date(EARLIEST_START_DATE)}
                  disableFuture
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      );
    };

    const onEventChange = (state: any) => {
      setEventIds(state);
    };

    const onFormSubmit = async (values: any) => {
      const titles = {
        eventSeriesTitle: singleEventSeries?.title,
        eventTitle: event && getPrettyDate(event?.date),
      };

      const queryParams = {
        ...values,
        ...getTimeRange(values),
        ...titles,
        ...(eventIds || {}),
        ...{
          companyId: isOwner ? myCompanyData?.id : null,
        },
        ...{
          skyboxName: skyboxSelectOptions?.find(
            (skybox) => skybox.id === values.skybox,
          )?.name,
        },
      };

      onSubmit(queryParams);
    };

    return (
      <Form
        initialValues={initialValues}
        onSubmit={onFormSubmit}
        render={({ values, handleSubmit }) => {
          return (
            <Box component="form" onSubmit={handleSubmit}>
              {isLoading && (
                <Box display="flex">
                  <CircularProgress />
                </Box>
              )}
              <Box>
                <Typography variant="h3">
                  {t('reports.chooseTimespan')}
                </Typography>
                <Radios name="dateRange" data={DATE_RANGES} />
                {values.dateRange === 'period' && renderDateFields()}
              </Box>
              <Box mt={3}>
                <Typography variant="h3">{t('reports.chooseEvent')}</Typography>
                <Radios name="eventQueryType" data={EVENT_QUERY_TYPES} />
                <Box mt={1}>
                  {values.eventQueryType === 'single' && (
                    <EventPicker onChange={onEventChange} />
                  )}
                </Box>
              </Box>
              {currentCustomer?.hasSkyboxes && (
                <Box mt={3}>
                  <Typography variant="h3">
                    {t('reports.chooseSkybox')}
                  </Typography>
                  <Radios name="skyboxQueryType" data={SKYBOX_QUERY_TYPES} />
                  <Box mt={1}>
                    {values.skyboxQueryType === 'single' && (
                      <Select
                        data={
                          skyboxSelectOptions?.map(
                            ({ id, mapId, name, active }) => ({
                              value: id,
                              label: `${mapId} - ${name}${
                                active
                                  ? ''
                                  : ` (${t(
                                      'skybox.deactivated',
                                    ).toLowerCase()})`
                              }`,
                            }),
                          ) || []
                        }
                        name="skybox"
                      />
                    )}
                  </Box>
                </Box>
              )}

              <Box mt={3}>
                <Button disabled={isLoading} type="submit">
                  {submitText}
                </Button>
              </Box>
            </Box>
          );
        }}
      />
    );
  },
);
