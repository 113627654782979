import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { FC, useMemo } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { DEFAULT_SKYBOX_VAT } from '../../../../constants/siteSettings';
import { useStores } from '../../../../stores';
import { getPrettySum } from '../../../../utils/i18nUtils';
import { CurrencyField } from '../../../CurrencyField/CurrencyField';
import { SkyboxSettingsModalFooter } from './SkyboxSettingsModalFooter';

export interface SkyboxSettingsRerentProps {
  onCancel: any;
  onConfirm: any;
  skybox: ISkybox;
  channels: string[];
}

/**
 * Modal component
 */
export const SkyboxSettingsRerent: FC<SkyboxSettingsRerentProps> = observer(
  ({ onConfirm, onCancel, skybox, channels }) => {
    const { t } = useTranslation();
    const {
      skyboxStore: { freeMySkybox },
      eventStore: { event },
    } = useStores();

    const currencyField = useMemo(
      () => (skybox?.eventSkybox ? 'eventSkybox.price' : 'defaultPrice'),
      [skybox],
    );

    const onSubmit = async (values: any) => {
      const price = values.eventSkybox
        ? values.eventSkybox.price
        : values.defaultPrice;

      await freeMySkybox({
        eventId: event!.id,
        skyboxId: skybox.id,
        type: skybox.type,
        channels,
        price,
      });
      onConfirm();
    };

    return (
      <Box>
        <Typography variant="h1">
          {t('events.skyboxSettingsModal.rerent.title')}
        </Typography>
        <Box mt={2} />
        <Typography variant="body2">
          {t('events.skyboxSettingsModal.rerent.description')}
        </Typography>

        {event?.customerEventInformation?.skyboxListPrice && (
          <Box display="flex" alignItems="center" mt={1}>
            <FontAwesomeIcon icon={faInfoCircle} />
            <Box mr={1} />
            <Typography>
              {t('events.skyboxSettingsModal.skyboxListPrice', {
                price: getPrettySum(
                  event.customerEventInformation.skyboxListPrice,
                  true,
                ),
              })}
            </Typography>
          </Box>
        )}
        <Box mt={3} />

        <Form
          onSubmit={onSubmit}
          initialValues={skybox}
          render={({ handleSubmit }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Box maxWidth={250}>
                  <CurrencyField
                    name={currencyField}
                    label={t('events.rentPrice')}
                    helperText={t('common.priceInclVAT', {
                      vat: DEFAULT_SKYBOX_VAT,
                    })}
                  />
                </Box>
                <SkyboxSettingsModalFooter
                  onCancel={onCancel}
                  confirmText="common.save"
                  cancelText="common.back"
                />
              </form>
            );
          }}
        />
      </Box>
    );
  },
);
