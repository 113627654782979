import { Box, InputAdornment, makeStyles, TextField } from '@material-ui/core';
import Search from '@material-ui/icons/Search';
import debounce from 'lodash.debounce';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import colors from '../../styles/colors';

export interface SearchInputProps {
  placeholder?: string;
  onChange: (value: string) => void;
  useDebounce?: boolean;
  debounceTimeout?: number;
  variant?: 'outlined' | 'filled' | 'standard';
  label?: string;
  fullWidth?: boolean;
  value?: string;
}

const useStyles = makeStyles({
  field: {
    '& .MuiOutlinedInput-root': {
      borderRadius: 30,
      backgroundColor: colors.background.bg2,
    },
    height: 36,
    '& input': {
      height: 18,
    },
  },
});

export const SearchInput: FC<SearchInputProps> = ({
  value,
  placeholder,
  variant,
  onChange,
  debounceTimeout,
  useDebounce,
  ...rest
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const debounced = debounce(onChange, debounceTimeout || 500);

  const onType = (e: any) => {
    const {
      target: { value },
    } = e;
    if (useDebounce) {
      debounced(value);
      return;
    }
    onChange(value);
  };

  return (
    <Box>
      <TextField
        className={classes.field}
        placeholder={placeholder || t('common.search')}
        variant={variant || 'outlined'}
        onChange={onType}
        defaultValue={value}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search style={{ fill: colors.text.body2 }} />
            </InputAdornment>
          ),
        }}
        {...rest}
      />
    </Box>
  );
};
