import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { PrettyDate } from '../../components/Date/PrettyDate';
import { IconTextButton } from '../../components/IconTextButton/IconTextButton';
import { InfoTooltip } from '../../components/InfoTooltip/InfoTooltip';
import { Layout } from '../../components/Layout/Layout';
import MaterialTable from '../../components/MaterialTable/MaterialTable';
import { PATHS } from '../../constants/routes';
import BulletinService from '../../services/BulletinService';
import { useStores } from '../../stores/index';
import colors from '../../styles/colors';

interface Props {}

const PAGE_SIZE = 10;

interface QueryParams {
  page: number;
}

const useStyles = makeStyles({
  unreadIndicator: {
    height: 10,
    width: 10,
    borderRadius: 20,
    backgroundColor: colors.red7,
    marginRight: 12,
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  listHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

export const BulletinListScreen: FC<Props> = observer(() => {
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();

  const [params, setParams] = useState<QueryParams>({
    page: 0,
  });

  const {
    authStore: { isAdmin, isOwner },
    bulletinStore: {
      allBulletins,
      getBulletins,
      bulletinCount,
      unreadBulletins,
    },
  } = useStores();

  const onAddClick = () => {
    history.push(PATHS.bulletins.add);
  };

  const fetchBulletins = useCallback(() => {
    const { page } = params;
    getBulletins({
      skip: (page ?? 0) * PAGE_SIZE,
      take: PAGE_SIZE,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    fetchBulletins();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addButton = isAdmin && (
    <IconTextButton type="add" onClick={onAddClick} label="bulletin.addNew" />
  );

  const onPageChange = (pageIndex: number) => {
    setParams({ page: pageIndex });
  };

  const onMarkAllAsReadClick = async () => {
    await BulletinService.markAllBulletinsRead();
    fetchBulletins();
  };

  const onOpenBulletinClick = useCallback(
    (id: string) => () => {
      history.push(PATHS.bulletins.single.replace(':id', id));
    },
    [history],
  );

  const COLUMNS = useMemo(
    () => [
      {
        accessor: 'title',
        Header: t('bulletin.title'),
        Cell: ({ row: { original } }: { row: { original: IBulletin } }) => {
          return (
            <Box className={classes.titleContainer}>
              {!isAdmin && !original.readAt && (
                <InfoTooltip
                  hideInfoIcon
                  tooltipText={t('bulletin.unreadMessage')}
                >
                  <Box className={classes.unreadIndicator} />
                </InfoTooltip>
              )}
              <Typography>{original.title}</Typography>
            </Box>
          );
        },
      },
      {
        accessor: 'createdAt',
        Header: t('bulletin.createdAt'),
        Cell: ({ row: { original } }: { row: { original: IBulletin } }) => {
          return <PrettyDate date={original.createdAt} showTime />;
        },
      },
      {
        accessor: 'action',
        Header: t('common.action'),
        Cell: ({ row: { original } }: { row: { original: IBulletin } }) => {
          return (
            <Button
              onClick={onOpenBulletinClick(original.id)}
              variant="outlined"
            >
              {t('bulletin.openBulletin')}
            </Button>
          );
        },
      },
    ],
    [t, onOpenBulletinClick, isAdmin, classes],
  );

  return (
    <Layout headerText={t('bulletin.listHeader')}>
      <Box p={3}>
        <Box className={classes.listHeader}>
          <Typography variant="body2">
            {t('bulletin.count', { count: bulletinCount })}
          </Typography>
          {isOwner && unreadBulletins && (
            <Button variant="outlined" onClick={onMarkAllAsReadClick}>
              {t('bulletin.markAllAsRead')}
            </Button>
          )}
          {addButton}
        </Box>
        <Box mb={3} />
        <MaterialTable
          paginationEnabled
          totalCount={bulletinCount}
          onPageChange={onPageChange}
          data={allBulletins}
          columns={COLUMNS}
        />
      </Box>
    </Layout>
  );
});
