import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { SkyboxEventLayout } from '../../components/Layout/hocs/SkyboxEventLayout';

export interface EventInformationScreenProps {}

export const EventInformationScreen: FC<EventInformationScreenProps> = observer(
  () => {
    return <SkyboxEventLayout>INFORMATION</SkyboxEventLayout>;
  },
);
