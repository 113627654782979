import { makeValidate } from 'mui-rff';
import { Validator } from '../../../../../utils/validation';
import { SelfReservationContactDetailsParams } from './SelfReservationContactDetailsParams';

const SelfReservationContactNameSchema = Validator.string().optional();
const SelfReservationContactPhoneSchema = Validator.string()
  .phoneNumber()
  .optional();
const SelfReserVationContactEmailSchema = Validator.string().email().optional();

export const SelfReservationContactDetailsValidate = makeValidate(
  Validator.object().shape({
    contactName: SelfReservationContactNameSchema,
    contactPhone: SelfReservationContactPhoneSchema,
    contactEmail: SelfReserVationContactEmailSchema,
  }),
);

export const getValidReservationContactDetailsFields = (
  params: SelfReservationContactDetailsParams,
) => ({
  contactEmail: SelfReserVationContactEmailSchema.isValidSync(
    params.contactEmail,
  )
    ? params.contactEmail
    : undefined,
  contactName: SelfReservationContactNameSchema.isValidSync(params.contactName)
    ? params.contactName
    : undefined,
  contactPhone: SelfReservationContactPhoneSchema.isValidSync(
    params.contactPhone,
  )
    ? params.contactPhone
    : undefined,
});
