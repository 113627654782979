/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { PATHS } from '../../../constants/routes';
import { useStores } from '../../../stores/index';
import MaterialTable from '../../MaterialTable/MaterialTable';
import { CommonModal } from '../../Modals/CommonModal';
import { SearchInput } from '../../SearchInput/SearchInput';
import { Tabs } from '../../Tabs';
import { Layout } from '../Layout';

export interface OwnerOrderListLayoutProps {
  type: 'upcoming' | 'past';
  columns: any;
}

interface QueryParams {
  page: number;
  search?: string;
  past: boolean;
}

const PAGE_SIZE = 10;

export const OwnerOrderListLayout: FC<OwnerOrderListLayoutProps> = observer(
  ({ type, columns }) => {
    const { t } = useTranslation();
    const [newOrderModalOpen, setNewOrderModalOpen] = useState(false);
    const {
      skyboxStore: { getMySkyboxesOrders, mySkyboxesOrders, myOrderCount },
      cartStore: { savedCartId, clearCart },
    } = useStores();

    const [params, setParams] = useState<QueryParams>({
      page: 0,
      search: undefined,
      past: type === 'past',
    });

    const getOrders = (params: QueryParams) => {
      getMySkyboxesOrders({
        skip: params.page * PAGE_SIZE,
        take: PAGE_SIZE,
        search: params.search,
        past: params.past,
      });
    };

    useEffect(() => {
      getOrders(params);
    }, [params]);

    const history = useHistory();
    const TABS = [
      {
        label: t('orders.owner.upcomingOrders'),
        path: PATHS.orders.owners.upcoming,
      },
      {
        label: t('orders.owner.pastOrders'),
        path: PATHS.orders.owners.past,
      },
    ];
    const renderTabs = () => <Tabs items={TABS} />;

    const onAddClick = () => {
      if (savedCartId) {
        setNewOrderModalOpen(true);
        return;
      }
      history.push(PATHS.orders.add.main);
    };

    const onCloseNewOrderModal = () => {
      setNewOrderModalOpen(false);
    };

    const onModalNewOrderClick = async () => {
      clearCart();
      history.push(PATHS.orders.add.main);
    };

    const onModalContinueOrderClick = () => {
      history.push(PATHS.orders.add.main);
    };

    const addButton = (
      <Box>
        <Button variant="outlined" startIcon="+" onClick={onAddClick}>
          {t('orders.addOrder')}
        </Button>
      </Box>
    );

    const onPageChange = async (pageIndex: number) => {
      if (pageIndex !== params.page) {
        const newParams = { ...params };
        newParams.page = pageIndex || 0;
        setParams(newParams);
      }
    };

    const onSearchChange = async (value: string) => {
      const newParams = { ...params };
      newParams.search = value;
      newParams.page = 0;
      setParams(newParams);
    };

    return (
      <Layout
        headerText={t('orders.orders')}
        headerElementsBottom={renderTabs()}
        headerElementsRight={addButton}
      >
        <Box p={3}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={3}
          >
            <Typography variant="body2">
              {t(`orders.${type}OrdersCount`, { count: myOrderCount || 0 })}
            </Typography>
            <SearchInput onChange={onSearchChange} useDebounce />
          </Box>
          <MaterialTable
            columns={columns}
            data={mySkyboxesOrders}
            onPageChange={onPageChange}
            totalCount={myOrderCount || 0}
            defaultPageSize={10}
            paginationEnabled
            useControlledState
            controlledPageIndex={params.page}
          />
        </Box>

        <CommonModal
          title={t('orders.newOrderModal.title')}
          footer={
            <Box mt={3} display="flex">
              <Button onClick={onModalContinueOrderClick}>
                {t('orders.newOrderModal.continueOrder')}
              </Button>
              <Box mr={1} />
              <Button onClick={onModalNewOrderClick} variant="outlined">
                {t('orders.newOrderModal.startNewOrder')}
              </Button>
              <Box mr={1} />
              <Button onClick={onCloseNewOrderModal} variant="outlined">
                {t('common.close')}
              </Button>
            </Box>
          }
          open={newOrderModalOpen}
        >
          <Box>
            <Typography>{t('orders.newOrderModal.body')}</Typography>
          </Box>
        </CommonModal>
      </Layout>
    );
  },
);
