/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Checkbox, FormControlLabel } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useMemo } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { EventPicker } from '../../components/EventPicker';
import { EventPickerState } from '../../components/EventPicker/EventPicker';
import { AddOrderLayout } from '../../components/Layout';
import MaterialTable from '../../components/MaterialTable/MaterialTable';
import { SkyboxMap } from '../../components/SkyboxMap';
import { DEFAULT_SKYBOX_VAT } from '../../constants/siteSettings';
import { useStores } from '../../stores/index';
import { getPrettySum } from '../../utils/i18nUtils';

export interface OrderFormMainScreenProps {}

export const OrderFormMainScreen: FC<OrderFormMainScreenProps> = observer(
  () => {
    const { t } = useTranslation();
    const {
      cartStore: {
        setActiveStep,
        setEventIds,
        eventIds,
        addSkyboxToCart,
        setSkyboxId,
        skyboxId,
        createCart,
        clearCart,
      },
      skyboxStore: {
        getEventSkyboxes,
        eventSkyboxes,
        getMyEventSkyboxes,
        myEventSkyboxes,
        resetEventSkyboxes,
        resetMyEventSkyboxes,
      },
      authStore: { isOwner, currentUser },
      settingsStore: { myCompanyData },
    } = useStores();

    const onEventChange = (state: EventPickerState) => {
      resetEventSkyboxes();
      resetMyEventSkyboxes();
      if (state.eventId && state.eventId !== eventIds.eventId)
        setEventIds(state);
    };

    useEffect(() => {
      resetEventSkyboxes();
      resetMyEventSkyboxes();
      clearCart();
    }, [clearCart]);

    useEffect(() => {
      if (eventIds && eventIds.eventId) {
        if (isOwner) {
          getEventSkyboxes(eventIds?.eventId);
          getMyEventSkyboxes(eventIds?.eventId);
        }
      }
    }, [eventIds, getEventSkyboxes, isOwner]);

    const onBoxCheckboxChange = (e: any) => {
      const {
        target: { name, checked },
      } = e;
      if (!checked) {
        setSkyboxId(undefined);
        return;
      }
      setSkyboxId(name);
    };

    let columns = [
      {
        Header: t('common.id'),
        accessor: 'mapId',
      },
      {
        Header: t('common.name'),
        accessor: 'name',
      },
      {
        Header: t('common.capacity'),
        accessor: 'seatCount',
      },
      {
        Header: t('common.priceInclVATParen', {
          vat: DEFAULT_SKYBOX_VAT,
        }),
        accessor: 'defaultPrice',
        Cell: ({ row: { original } }: any) => {
          const eventSkybox =
            original.eventSkybox ||
            original.eventSkyboxes?.find(
              (esb: IEventSkybox) => esb.eventId === eventIds.eventId,
            );
          const price = eventSkybox ? eventSkybox.price : original.defaultPrice;
          return getPrettySum(price || 0, true);
        },
      },
      {
        Header: t('common.select'),
        accessor: 'id',
        Cell: ({ row: { original } }: any) => {
          return (
            <FormControlLabel
              label={t('common.select')}
              control={
                <Checkbox
                  checked={skyboxId === original.id}
                  onChange={onBoxCheckboxChange}
                  name={original.id}
                />
              }
            />
          );
        },
      },
    ];

    if (isOwner) {
      columns = columns.filter((c) => c.accessor !== 'stand');
    }

    const navigateToNextStep = () => {
      setActiveStep(1);
    };

    const onConfirm = async () => {
      if (!eventIds.eventId || !skyboxId) return;
      const isWholeSaleSkybox =
        availableBoxes?.find((skybox: ISkybox) => skybox.id === skyboxId)
          ?.type === 'whole_sale';

      if (isWholeSaleSkybox) {
        try {
          await addSkyboxToCart();
          navigateToNextStep();
        } catch (error) {
          console.error(error);
        }
      } else {
        try {
          await createCart();
          navigateToNextStep();
        } catch (error) {
          console.error(error);
        }
      }
    };

    const availableBoxes = useMemo(() => {
      let myAvailableBoxes = [] as any;
      let otherAvailableBoxes = [] as any;

      if (isOwner) {
        if (myEventSkyboxes) {
          // Skyboxes that are reserved for the current user
          myAvailableBoxes = myEventSkyboxes.filter((skybox) => {
            if (
              skybox.reservedFor?.id === currentUser?.id &&
              !skybox.purchase
            ) {
              return true;
            }
            return false;
          });
        }
        if (eventSkyboxes) {
          otherAvailableBoxes = eventSkyboxes.filter((skybox) => {
            if (myCompanyData && skybox.ownerCompanyId === myCompanyData.id)
              return false;

            const eventSkybox = skybox.eventSkyboxes.find(
              (eSkybox: IEventSkybox) => eSkybox.eventId === eventIds.eventId,
            );

            if (!eventSkybox) return false;

            return (
              (!eventSkybox?.reservedForId ||
                eventSkybox.reservedForId === myCompanyData!.id) &&
              eventSkybox.type !== 'not_for_sale' &&
              eventSkybox.type !== 'disabled' &&
              eventSkybox.type !== 'group_sale'
            );
          });
        }
      }

      return [...myAvailableBoxes, ...otherAvailableBoxes];
    }, [eventSkyboxes, myEventSkyboxes, eventIds, myCompanyData]);

    return (
      <AddOrderLayout
        confirmDisabled={!skyboxId}
        onConfirm={onConfirm}
        title={t('orders.add.event.title')}
      >
        <Box mt={3} mb={3} maxWidth={500}>
          <EventPicker onChange={onEventChange} value={eventIds} />
        </Box>
        <Box display="flex" justifyContent="flex-end">
          <SkyboxMap />
        </Box>
        <Form
          onSubmit={() => {}}
          render={() => {
            return (
              <Box>
                {eventIds?.eventId && (
                  <Box mt={3}>
                    <MaterialTable columns={columns} data={availableBoxes} />
                  </Box>
                )}
              </Box>
            );
          }}
        />
      </AddOrderLayout>
    );
  },
);
