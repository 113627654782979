import { Tab, Tabs as MaterialTabs } from '@material-ui/core';
import { FC, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export type TabType<T = unknown> = {
  label: string;
  path?: string;
  onClick?: Function;
  state?: T;
};

interface TabsProps {
  items: TabType[];
  activeIndex?: number;
}

export const Tabs: FC<TabsProps> = ({ items, activeIndex }) => {
  const [value, setValue] = useState(0);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const currentPathIndex =
      activeIndex ?? items.findIndex((item) => item.path === location.pathname);
    if (currentPathIndex !== undefined) setValue(currentPathIndex);
  }, [location, items, activeIndex]);

  const onChange = (_e: any, tabIndex: number) => {
    setValue(tabIndex);
    const { onClick, path, state } = items[tabIndex]!;
    if (onClick) onClick(tabIndex);
    if (path) history.push(path, state);
  };
  return (
    <MaterialTabs value={value} onChange={onChange}>
      {items.map((item) => {
        return <Tab key={item.label} label={item.label} />;
      })}
    </MaterialTabs>
  );
};
