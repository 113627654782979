import { Box } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { PATHS } from '../../../constants';
import { TabbedLayout } from './TabbedLayout';

export const DocumentsLayout: FC<{ content?: string }> = observer(
  ({ content }) => {
    const { t: ct } = useTranslation(undefined, { keyPrefix: 'documents' });

    const tabs = [
      {
        label: ct('serviceInformation'),
        path: PATHS.documents.serviceInformation,
      },
      { label: ct('userAgreement'), path: PATHS.documents.userAgreement },
      { label: ct('privacyPolicy'), path: PATHS.documents.privacyPolicy },
    ];

    return (
      <TabbedLayout tabs={tabs}>
        {content && (
          <Box ml={3} dangerouslySetInnerHTML={{ __html: content }} />
        )}
      </TabbedLayout>
    );
  },
);
