/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Typography as T } from '@material-ui/core';
import { GetApp } from '@material-ui/icons';
import { ExportToCsv } from 'export-to-csv';
import { observer } from 'mobx-react-lite';
import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Layout } from '../../components/Layout/Layout';
import MaterialTable from '../../components/MaterialTable/MaterialTable';
import { SearchInput } from '../../components/SearchInput/SearchInput';
import { useStores } from '../../stores/index';
import { getPrettyDate, getPrettySum } from '../../utils/i18nUtils';
import { ListTableClickableCell } from './components/ListTableClickableCell';

export interface AdminOrderListScreenProps {}

interface QueryParams {
  page: number;
  search?: string;
}

export const AdminOrderListScreen: React.FC<AdminOrderListScreenProps> =
  observer(() => {
    const { t } = useTranslation();
    const [params, setParams] = useState<QueryParams>({
      page: 0,
      search: undefined,
    });

    const {
      skyboxStore: { getSkyboxOrders, skyboxOrders, orderCount },
    } = useStores();

    const PAGE_SIZE = 10;

    const getOrders = (params: QueryParams) => {
      getSkyboxOrders({
        skip: params.page * PAGE_SIZE,
        take: PAGE_SIZE,
        search: params.search,
      });
    };

    useEffect(() => {
      getOrders(params);
    }, [params]);

    const getDate = (date: string) =>
      getPrettyDate(date, true, t('common.clockAbbr')) || '';

    const getPrintDate = (date: string) => moment(date).format('D.M.Y');

    // Tapahtuma
    // Tilaisuus
    // Aition numero
    // Vuokratun aition tyyppi
    // Vuokraavan  yrityksen nimi
    // Lippujen määrä varauksessa
    const COLUMNS = [
      {
        Header: t('common.eventSeries'),
        accessor: 'eventSeries.title',
        width: 250,
        hideFromScreen: true,
        PrintCell: ({ row: { original } }: any) => original.eventSeries?.title,
      },
      {
        Header: t('common.event'),
        accessor: 'title',
        width: 250,
        Cell: ({ row: { original } }: any) => (
          <ListTableClickableCell item={original}>
            {original.eventSkybox?.event?.title}
          </ListTableClickableCell>
        ),
        PrintCell: ({ row: { original } }: any) =>
          original.eventSkybox?.event?.title,
      },
      {
        Header: t('common.date'),
        accessor: 'date',
        width: 250,
        Cell: ({ row: { original } }: any) => (
          <ListTableClickableCell item={original}>
            {getDate(original.eventSkybox?.event?.date)}
          </ListTableClickableCell>
        ),
        PrintCell: ({ row: { original } }: any) =>
          getPrintDate(original.eventSkybox?.event?.date),
      },
      {
        Header: t('orders.orderNumber'),
        accessor: 'livexOrderNumber',
        width: 100,
        Cell: ({ row: { original } }: any) => (
          <ListTableClickableCell item={original}>
            {original.purchase?.livexOrderNumber}
          </ListTableClickableCell>
        ),
        PrintCell: ({ row: { original } }: any) =>
          original.purchase?.livexOrderNumber,
      },
      {
        Header: t('orders.orderDate'),
        accessor: 'paidOn',
        width: 250,
        Cell: ({ row: { original } }: any) => (
          <ListTableClickableCell item={original}>
            {getDate(original.purchase?.paidOn)}
          </ListTableClickableCell>
        ),
        PrintCell: ({ row: { original } }: any) =>
          getPrintDate(original.purchase?.paidOn),
      },
      {
        Header: t('orders.skybox'),
        accessor: 'mapId',
        Cell: ({ row: { original } }: any) => (
          <ListTableClickableCell item={original}>
            {original.eventSkybox?.skybox?.mapId}
          </ListTableClickableCell>
        ),
        PrintCell: ({ row: { original } }: any) =>
          original.eventSkybox?.skybox?.mapId,
      },
      {
        Header: t('common.type'),
        accessor: 'type',
        Cell: ({ row: { original } }: any) => (
          <ListTableClickableCell item={original}>
            {t(`skybox.type-${original.eventSkybox?.type}`)}
          </ListTableClickableCell>
        ),
        PrintCell: ({ row: { original } }: any) =>
          t(`skybox.type-${original.eventSkybox?.type}`),
      },
      {
        Header: t('orders.renter'),
        accessor: 'renter',
        Cell: ({ row: { original } }: any) => (
          <ListTableClickableCell item={original}>
            {original.eventSkybox?.skybox?.company?.name}
          </ListTableClickableCell>
        ),
        PrintCell: ({ row: { original } }: any) =>
          original.eventSkybox?.skybox?.company?.name,
      },
      {
        Header: t('common.tickets'),
        accessor: 'id',
        Cell: ({ row: { original } }: any) => (
          <ListTableClickableCell item={original}>
            {original.purchase?.tickets?.length}
          </ListTableClickableCell>
        ),
        PrintCell: ({ row: { original } }: any) =>
          original.purchase?.tickets?.length,
      },
      {
        Header: t('reports.list.ticketPrice'),
        accessor: 'ticketPrice',
        hideFromScreen: true,
        PrintCell: ({ row: { original } }: any) => {
          let price = '';
          if (!original.purchase?.tickets) return '';
          const prices = original.purchase?.tickets
            .map((ticket: any) => ticket.price)
            .filter((value: any, index: any, self: any) => {
              return self.indexOf(value) === index;
            });

          if (prices.length > 1) {
            price = t('reports.list.variousTickets');
          } else {
            if (prices[0] === undefined) return '';
            price = getPrettySum(prices[0], true);
          }
          return price;
        },
      },
      {
        Header: t('orders.orderFee'),
        accessor: 'orderFee',
        hideFromScreen: true,
        PrintCell: ({ row: { original } }: any) =>
          getPrettySum(original?.purchase?.orderFee || 0, true),
      },
      {
        Header: t('owner.invoicingFee'),
        accessor: 'invoicingFee',
        hideFromScreen: true,
        PrintCell: ({ row: { original } }: any) => {
          if (!original?.purchase?.invoicingInfo) return '';
          const invoicingInfo = JSON.parse(original.purchase.invoicingInfo);
          const company = invoicingInfo?.userinfo?.companies[0];

          return getPrettySum(company?.invoicingFee || 0, true);
        },
      },
      {
        Header: t('common.rent'),
        accessor: 'rent',
        hideFromScreen: true,
        PrintCell: ({ row: { original } }: any) => {
          if (!original.eventSkybox) return '';

          let sum = original.eventSkybox.price || 0;
          if (
            original.eventSkybox.type === 'group_sale' ||
            original.eventSkybox.type === 'not_for_sale'
          ) {
            sum = 0;
          }
          return getPrettySum(sum || 0, true);
        },
      },
      {
        Header: t('orders.totalSum'),
        accessor: 'totalSum',
        hideFromScreen: true,
        PrintCell: ({ row: { original } }: any) => {
          return getPrettySum(original.purchase?.totalSum, true);
        },
      },
    ];

    const screenColumns = COLUMNS.filter((col) => !col.hideFromScreen);

    const onExportCsvClick = async () => {
      const allOrders = await getSkyboxOrders({
        skip: undefined,
        take: undefined,
      });
      const headers = COLUMNS.map((column) => column.Header);

      const options = {
        useKeysAsHeaders: false,
        showLabels: true,
        headers,
        filename: `orders_aitioadmin_${moment().format('D.M.Y hh.mm')}`,
      };

      const csvRows =
        allOrders &&
        allOrders[0].map((reportRow: any) => {
          return COLUMNS.map((column: any) => {
            // the react-table Cell format
            const data = { row: { original: reportRow } };

            if (column.PrintCell) {
              return column.PrintCell(data);
            }
            if (column.Cell) {
              return column.Cell(data);
            }
            return reportRow[column.accessor];
          });
        });
      const csvExporter = new ExportToCsv(options);
      csvExporter.generateCsv(csvRows);
    };

    const ExportCsvButton = (
      <Button id="exportCsv" onClick={onExportCsvClick} variant="outlined">
        <GetApp />
        <Box ml={1}>{t('orders.financialMgmtReport')}</Box>
      </Button>
    );

    const onSearchChange = async (value: string) => {
      const newParams = { ...params };
      newParams.search = value;
      newParams.page = 0;
      setParams(newParams);
    };

    const onPageChange = async (pageIndex: number) => {
      if (pageIndex !== params.page) {
        const newParams = { ...params };
        newParams.page = pageIndex;
        setParams(newParams);
      }
    };

    return (
      <Layout headerText={t('orders.orders')}>
        <Box p={3}>
          <Box
            mb={3}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <T color="textSecondary">
              {t('orders.orderCount', { count: orderCount })}
            </T>

            <Box display="flex">
              <SearchInput onChange={onSearchChange} useDebounce />
              <Box mr={1} />
              {ExportCsvButton}
            </Box>
          </Box>

          <MaterialTable
            columns={screenColumns}
            data={skyboxOrders}
            onPageChange={onPageChange}
            totalCount={orderCount}
            defaultPageSize={PAGE_SIZE}
            paginationEnabled
            useControlledState
            controlledPageIndex={params.page}
          />
        </Box>
      </Layout>
    );
  });
