import { Link as MaterialLink, makeStyles } from '@material-ui/core';
import { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles({
  link: {
    cursor: 'pointer',
    whiteSpace: 'nowrap',
  },
});

interface LinkProps {
  external?: boolean;
  to?: string;
  onClick?: () => void;
}

export const Link: FC<LinkProps> = ({ to, external, onClick, children }) => {
  const classes = useStyles();
  if (to) {
    if (external) {
      return (
        <MaterialLink target="_blank" href={to} className={classes.link}>
          {children}
        </MaterialLink>
      );
    }
    return (
      <MaterialLink component={RouterLink} to={to} className={classes.link}>
        {children}
      </MaterialLink>
    );
  }
  return (
    <MaterialLink onClick={onClick} className={classes.link}>
      {children}
    </MaterialLink>
  );
};
