import { observer } from 'mobx-react-lite';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Layout } from '../../components/Layout';
import { useStore } from '../../stores';
import ToastStore from '../../stores/ToastStore';
import { SkyboxForm } from './SkyboxForm';

export const AddSkyboxScreen: FC = observer(() => {
  const { isLoading, ownerList, getOwnerList, createSkybox } =
    useStore('skyboxStore');
  const { isAdmin } = useStore('authStore');
  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    if (isAdmin) getOwnerList();
  }, [getOwnerList, isAdmin]);

  const handleCreateClick = async (
    formValues: any,
    _form: any,
    cb: Function,
  ) => {
    if (!formValues.featuredAsset && !formValues.newFeaturedAsset) {
      ToastStore.showError('errors.skybox.featuredAssetRequired');
      return;
    }

    const data = {
      ...formValues,
      seatCount: parseInt(formValues.seatCount, 10),
      defaultPrice: parseFloat(formValues.defaultPrice),
      name: formValues.skyboxName,
    };

    delete data.skyboxName;
    await createSkybox(data, cb);
    history.push('/skyboxes');
  };

  const handleCancelClick = () => {
    history.push('/skyboxes');
  };

  return (
    <Layout headerText={t('skybox.newSkybox')}>
      <SkyboxForm
        onSubmit={handleCreateClick}
        onCancel={handleCancelClick}
        ownerList={ownerList}
        isLoading={isLoading}
        type="add"
      />
    </Layout>
  );
});
