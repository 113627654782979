/* eslint-disable react-hooks/exhaustive-deps */
import { Box } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { Autocomplete } from 'mui-rff';
import { FC, useEffect, useMemo, useState } from 'react';
import { Form, FormSpy } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useStore } from '../../stores/index';
import { getPrettyDate } from '../../utils/i18nUtils';

export type EventPickerState = {
  eventId?: number;
  eventSeriesId?: number;
};

export interface EventPickerProps {
  onChange: (state: EventPickerState) => void;
  value?: EventPickerState;
  disabled?: boolean;
  disableNestedForm?: boolean;
}

export const EventPicker: FC<EventPickerProps> = observer(
  ({ onChange, value, disabled, disableNestedForm }) => {
    const { t } = useTranslation();
    const { eventSeries, getEventSeries, event, getEvent } =
      useStore('eventStore');
    const [initialized, setInitialized] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState();
    const { isOwner } = useStore('authStore');
    useEffect(() => {
      if (!initialized) {
        // Makes public query if current user is Owner
        getEventSeries(undefined, !!isOwner);
        setInitialized(true);
      }
    }, [initialized, getEventSeries, isOwner]);

    const allEvents = useMemo(() => {
      if (!eventSeries) return [];
      return eventSeries?.flatMap((es) =>
        es.events.map((event) => ({
          ...event,
          ...{ eventSeriesTitle: es.title },
        })),
      );
    }, [eventSeries]);

    const onSubmit = () => {};

    const onFormChange = ({ values }: any) => {
      const eventSeriesId = allEvents?.find(
        (event) => event.id === values.eventId,
      )?.eventSeriesId;

      if (values.eventId && values.eventId !== selectedEvent) {
        setSelectedEvent(values.eventId);
        if ((!event || event.id !== values.eventId) && eventSeriesId) {
          // Makes public query if curent user is Owner
          getEvent(eventSeriesId, values.eventId, !!isOwner);
        }
      }
      onChange({ eventId: values.eventId, eventSeriesId });
    };

    const Content = (
      <Box display="flex" position="relative">
        {!!allEvents?.length && (
          <Autocomplete
            options={allEvents}
            name="eventId"
            label={t('common.event')}
            getOptionValue={(option) => option.id}
            getOptionLabel={(option) =>
              `${getPrettyDate(option.date, true)}: ${option.title}`
            }
            groupBy={(option) => String(option.eventSeriesTitle)}
            fullWidth
            disabled={disabled}
          />
        )}
        <FormSpy onChange={onFormChange} subscription={{ values: true }} />
      </Box>
    );

    if (disableNestedForm) return Content;

    return (
      <Form initialValues={value} onSubmit={onSubmit} render={() => Content} />
    );
  },
);
