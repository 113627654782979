/* eslint-disable max-len */
/* eslint-disable implicit-arrow-linebreak */
import { api, getPath, getAuthConfig } from './api';

const API_PATHS = Object.freeze({
  reset: '/customer/{customerId}/skybox/qrcode/reset',
  qrCode: '/customer/{customerId}/skybox/qrcode',
  sendEmail: '/customer/{customerId}/skybox/qrcode/send',
  resendCodes: '/customer/{customerId}/reservation/resend/{itemId}',
});

class AxessServiceModel {
  /**
   * Axess
   */
  resetAccessCode = () =>
    api.post(getPath(API_PATHS.reset), null, getAuthConfig());

  getAccessQrCode = async () =>
    await api.get(getPath(API_PATHS.qrCode), getAuthConfig());

  sendAccessCodeToEmail = () => api.post(getPath(API_PATHS.sendEmail));

  resendCodes = (reservationId: string) =>
    api.post(getPath(API_PATHS.resendCodes, reservationId));
}

const AxessService = new AxessServiceModel();

export default AxessService;
