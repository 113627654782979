import * as Sentry from '@sentry/react';
import axios from 'axios';
import { makeAutoObservable, runInAction } from 'mobx';
import AuthService from '../services/AuthService';
import CartStore from './CartStore';
import CommonStore from './CommonStore';
import EventStore from './EventStore';
import OrderStore from './OrderStore';
import SettingsStore from './SettingsStore';
import ToastStore from './ToastStore';
import UserStore from './UserStore';

export interface IAuthStore {
  state: StoreState;
  isLoading: boolean;
  isAuthLoading: boolean; // Authorized user is loading, used by router to determine when routes change
  isInitialized: boolean;
  currentUser?: IAdminUser;
  currentUserRoles?: string[];
  passwordChanged: boolean;
  isAdmin: boolean;
  isOwner: boolean;
  isSuperAdmin: boolean;
  isManager: boolean;
  isJanitor: boolean;
  isSuperOwner: boolean;
  isLoggedIn: boolean;
  loginWithOauth: (token: string) => void;
  login: (credentials: ILoginCredentials, cb: Function) => void;
  getCurrentUser: () => void;
  logout: () => void;
  getResetPasswordEmail: (data: IGetResetPassword) => void;
  resetPassword: (data: IResetPassword) => void;
}

const handleLoginError = (error: any) => {
  let errorMsg = 'errors.user.loginFailed';
  if (error.response) {
    const {
      response: {
        config: { url },
      },
    } = error;

    if (url.includes('claimShoppingCart')) {
      errorMsg = 'errors.cart.claimCartFailed';
    }
  }
  ToastStore.showError(errorMsg);
};

const ADMIN_ROLES = [
  'SuperAdmin',
  'SkyboxAdmin',
  'SuperManager',
  'Manager',
  'Admin',
] as UserRole[];
const OWNER_ROLES = ['SkyboxOwner'] as UserRole[];

class AuthModel implements IAuthStore {
  state: StoreState = 'Idle';
  isAuthLoading: IAuthStore['isAuthLoading'] = true;
  private _currentUser: IAuthStore['currentUser'] = undefined;
  isInitialized = false;
  passwordChanged = false;

  constructor() {
    makeAutoObservable(this);
  }

  get currentUser() {
    return this._currentUser;
  }

  set currentUser(user: IAuthStore['currentUser'] | undefined) {
    Sentry.setUser(user ? { email: user?.email, id: user?.id } : null);
    this._currentUser = user;
  }

  get isLoading() {
    return this.state === 'Loading';
  }

  get isAdmin() {
    if (!this.currentUser) return false;
    const roles = [...ADMIN_ROLES];
    return this.currentUser?.roles.some((role) => roles.includes(role));
  }

  get isOwner() {
    if (!this.currentUser) return false;
    const roles = [...OWNER_ROLES];
    return this.currentUser?.roles.some((role) => roles.includes(role));
  }

  get isManager() {
    if (!this.currentUser) return false;
    const roles = ['Manager'];
    return this.currentUser?.roles.some((role) => roles.includes(role));
  }

  get isJanitor() {
    if (!this.currentUser) return false;
    const roles = ['Janitor'];
    return this.currentUser?.roles.some((role) => roles.includes(role));
  }

  get isSuperOwner() {
    if (!this.currentUser) return false;
    return this.currentUser?.roles.includes('SuperOwner');
  }

  get isSuperAdmin() {
    if (!this.currentUser) return false;
    return this.currentUser?.roles.includes('SuperAdmin');
  }

  get isLoggedIn() {
    return !!this.currentUser;
  }

  get currentUserRoles() {
    if (!this.currentUser) return [];
    const roles = this.currentUser.roles;
    if (roles.includes('SuperAdmin') || roles.includes('SuperManager'))
      roles.push('SkyboxAdmin');
    if (roles.includes('SuperOwner')) roles.push(UserStore.superOwnerRole);
    return roles;
  }

  /**
   * Writes a new auth token after successful oauth request
   */
  loginWithOauth = async (token: string) => {
    this.isAuthLoading = true;
    try {
      CommonStore.updateAuthToken(token);
      await this.getCurrentUser();
      runInAction(() => {
        this.isInitialized = true;
        this.isAuthLoading = false;
      });
    } catch (error) {
      handleLoginError(error);
      runInAction(() => {
        this.state = 'Error';
        this.isAuthLoading = false;
      });
    }
  };

  /**
   * Logs the user in
   */
  login = async (credentials: ILoginCredentials) => {
    this.state = 'Loading';
    this.isAuthLoading = true;
    try {
      const response = await AuthService.login(credentials);

      runInAction(() => {
        // Prevent non-admin users from entering non-skybox-admin

        this.state = 'Success';
        this.currentUser = response.data.user;
        this.isAuthLoading = false;
      });
      CommonStore.updateAuthToken(response.data.token);
      ToastStore.showSuccess('successes.user.loginSuccess', {
        email: credentials.email,
      });
    } catch (error) {
      handleLoginError(error);
      runInAction(() => {
        this.state = 'Error';
        this.isAuthLoading = false;
      });
    }
  };

  /**
   * Logs the user out (i.e. deletes the token)
   */
  logout = () => {
    CommonStore.updateAuthToken('');
    SettingsStore.reset();
    OrderStore.reset();
    CartStore.clearCart();
    CartStore.resetEventIds();
    CommonStore.reset();
    EventStore.reset();
    this.currentUser = undefined;
  };

  /**
   * Returns the current user
   */
  getCurrentUser = async () => {
    this.state = 'Loading';
    try {
      const response = await AuthService.getMe();
      runInAction(() => {
        this.currentUser = response.data;
        this.state = 'Success';
      });
    } catch (error) {
      runInAction(() => {
        this.state = 'Error';
      });
      ToastStore.showError('errors.user.getCurrentUserFailed');
      throw error;
    }
  };

  /**
   * Refreshes current user if authToken is present
   */
  refreshIsAuthenticated = async () => {
    if (CommonStore.authToken) {
      this.isAuthLoading = true;
      await this.getCurrentUser();
      runInAction(() => {
        this.isInitialized = true;
        this.isAuthLoading = false;
      });
    } else {
      this.currentUser = undefined;
      this.isInitialized = true;
      this.isAuthLoading = false;
    }
  };

  /**
   * Triggers password reset email to be sent into specified email address
   */
  getResetPasswordEmail = async (data: IGetResetPassword) => {
    this.state = 'Loading';
    try {
      await AuthService.getResetPasswordEmail(data);
      runInAction(() => {
        this.state = 'Success';
      });
      ToastStore.showSuccess('successes.user.passwordResetInstructionsSent');
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message === 'User not found'
        ) {
          ToastStore.showError('errors.user.resetPasswordUserNotFound');
        }
      }
      runInAction(() => {
        this.state = 'Error';
      });
      throw error;
    }
  };

  /**
   * Resets current password
   */
  resetPassword = async (data: IResetPassword, onSuccess?: () => void) => {
    this.state = 'Loading';
    try {
      await AuthService.resetPassword(data);
      runInAction(() => {
        this.state = 'Success';
        this.passwordChanged = true;
      });
      ToastStore.showSuccess('successes.user.passwordReset');
      onSuccess?.();
    } catch (error) {
      let msg = 'errors.user.passwordResetFailed';

      if (axios.isAxiosError(error)) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message === '"Expired or bad token'
        ) {
          msg = 'errors.user.passwordResetFailedExpired';
        }
      }
      runInAction(() => {
        this.state = 'Error';
      });

      ToastStore.showError(msg);
      throw error;
    }
  };
}

const AuthStore = new AuthModel();

export default AuthStore;
