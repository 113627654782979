import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { TextField } from 'mui-rff';
import { FC, useState } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { DEFAULT_SKYBOX_VAT } from '../../../../constants/siteSettings';
import { useStores } from '../../../../stores';
import { getPrettySum } from '../../../../utils/i18nUtils';
import { CurrencyField } from '../../../CurrencyField/CurrencyField';
import { SkyboxSettingsModalFooter } from './SkyboxSettingsModalFooter';

export interface SkyboxSettingsTargetedRentalProps {
  onCancel: any;
  onClose: any;
  skybox: ISkybox;
}

/**
 * Modal component
 */
export const SkyboxSettingsTargetedRental: FC<SkyboxSettingsTargetedRentalProps> =
  observer(({ onCancel, onClose, skybox }) => {
    const { t } = useTranslation();
    const [page, setPage] = useState(0);
    const { eventId, eventSeriesId } = useParams() as any;
    const {
      skyboxStore: { placeForTargetedRental },
      eventStore: { event },
    } = useStores();

    const onSubmit = async ({ price, email }: any) => {
      switch (page) {
        case 0:
          return setPage(1);
        case 1:
          await placeForTargetedRental({
            eventId: parseInt(eventId),
            eventSeriesId: parseInt(eventSeriesId),
            price,
            email,
            skyboxId: skybox.id,
          });
          onClose();
          break;
      }
    };

    const MainPage = (
      <Box>
        <Box my={2}>
          <Typography variant="h3">
            {t('events.skyboxSettingsModal.targeted.page1.rentHeading')}
          </Typography>
        </Box>
        {event?.customerEventInformation?.skyboxListPrice && (
          <Box display="flex" alignItems="center" mb={3}>
            <FontAwesomeIcon icon={faInfoCircle} />
            <Box mr={1} />
            <Typography>
              {t('events.skyboxSettingsModal.skyboxListPrice', {
                price: getPrettySum(
                  event?.customerEventInformation.skyboxListPrice,
                  true,
                ),
              })}
            </Typography>
          </Box>
        )}
        <CurrencyField
          label={t('events.rentPrice')}
          helperText={t('common.priceInclVAT', { vat: DEFAULT_SKYBOX_VAT })}
          name="price"
        />
      </Box>
    );

    const renderUserPage = () => (
      <Box mt={1}>
        <Typography variant="h3">
          {t('events.skyboxSettingsModal.targeted.page2.enduserTitle')}
        </Typography>
        <Box mt={2} />
        <Typography variant="body2">
          {t('events.skyboxSettingsModal.targeted.page2.description')}
        </Typography>

        <Box maxWidth={400} my={3}>
          <TextField
            name="email"
            label={t('events.skyboxSettingsModal.targeted.endUserEmail')}
            required
            helperText={t('events.skyboxSettingsModal.targeted.emailHelper')}
          />
        </Box>
      </Box>
    );

    const onCancelClick = () => {
      if (page === 0) return onCancel();
      setPage(0);
    };

    const INITIAL_VALUES = {
      price: skybox.eventSkybox
        ? skybox.eventSkybox.price
        : skybox.defaultPrice,
    };

    return (
      <Box>
        <Typography variant="h1">
          {t('events.skyboxSettingsModal.targeted.title')} {`(${page + 1}/2)`}
        </Typography>
        <Box mt={2} />
        <Typography variant="body2">
          {t('events.skyboxSettingsModal.targeted.description')}
        </Typography>
        <Box mt={3} />
        <Form
          onSubmit={onSubmit}
          initialValues={INITIAL_VALUES}
          render={({ handleSubmit }) => {
            return (
              <form onSubmit={handleSubmit}>
                {page === 0 && MainPage}
                {page === 1 && renderUserPage()}
                <SkyboxSettingsModalFooter
                  confirmText="events.skyboxSettingsModal.targeted.confirm"
                  onCancel={onCancelClick}
                  cancelText="common.back"
                />
              </form>
            );
          }}
        />
      </Box>
    );
  });
