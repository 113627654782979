import { Box, Button, makeStyles, Typography as T } from '@material-ui/core';
import debounce from 'lodash.debounce';
import { observer } from 'mobx-react-lite';
import queryString from 'query-string';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { PATHS } from '../../constants/routes';
import { useStores } from '../../stores/index';
import colors from '../../styles/colors';
import { FONT_SIZES } from '../../styles/theme';
import { getPrettyDate } from '../../utils/i18nUtils';
import MaterialTable from '../MaterialTable/MaterialTable';
import { SearchInput } from '../SearchInput';
import {
  ListSkyboxEventSeriesEventsResponseItemDto,
  SkyboxEventListViewModel,
} from './SkyboxEventListViewModel';

interface SkyboxEventListProps {
  eventSeriesId?: number;
  pageSize: number;
}

const useStyles = makeStyles({
  disabledReleaseForSaleCell: {
    opacity: 0.25,
  },
  openModalButton: {
    '& .MuiButton-label': {
      fontWeight: 300,
      fontSize: FONT_SIZES[0],
    },
  },
  skyboxesCell: {
    display: 'flex',
    flexDirection: 'column',
  },
  availabilityIndicator: {
    height: 12,
    width: 12,
    marginRight: 6,
    borderRadius: 12,
    display: 'block',
    '&.green': {
      backgroundColor: colors.green,
    },
    '&.red': {
      backgroundColor: colors.red7,
    },
    '&.yellow': {
      backgroundColor: colors.gold7,
    },
  },
  availabilityContainer: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 2,
  },
});

const Availability: React.FC<{ color: 'green' | 'red' | 'yellow' }> = ({
  children,
  color,
}) => {
  const classes = useStyles();

  return (
    <Box component="span" className={classes.availabilityContainer}>
      <Box
        component="span"
        className={`${classes.availabilityIndicator} ${color}`}
      />
      {children}
    </Box>
  );
};

export const SkyboxEventList: React.FC<SkyboxEventListProps> = observer(
  ({ eventSeriesId, pageSize: defaultPageSize }) => {
    const history = useHistory();
    const location = useLocation();
    const { t } = useTranslation();
    const classes = useStyles();
    const {
      authStore: { isAdmin, isOwner },
    } = useStores();

    const query = useMemo(() => {
      const params = queryString.parse(location.search) as unknown as {
        search?: string;
        page: number;
        pageSize: number;
      };
      params.page = Number(params.page);
      params.pageSize = Number(params.pageSize);
      if (isNaN(params.page)) params.page = 0;
      if (isNaN(params.pageSize)) params.pageSize = defaultPageSize;

      return params;
    }, [location, defaultPageSize]);

    const viewModel = useMemo(() => {
      const viewModel = new SkyboxEventListViewModel({
        eventSeriesId,
        isOwner,
      });
      return viewModel;
    }, [eventSeriesId, isOwner]);

    useEffect(() => {
      viewModel.fetchData(query);
    }, [query, viewModel]);

    const onOpenEventClick = useCallback(
      (event: ListSkyboxEventSeriesEventsResponseItemDto) => () => {
        const path = PATHS.skyboxEvents.eventSkyboxes
          .replace(':eventSeriesId', String(event.eventSeriesId))
          .replace(':eventId', String(event.id));
        history.push(path, {
          prevPath: `${location.pathname}${location.search}`,
        });
      },
      [history, location],
    );

    const columns = useMemo(() => {
      const COLUMNS = [
        {
          Header: t('events.eventName'),
          accessor: 'title',
          width: isAdmin ? '50%' : '35%',
        },
        {
          Header: t('common.date'),
          accessor: 'date',
          Cell: ({ row: { original } }: any) =>
            getPrettyDate(original.date, true, t('common.clockAbbr'), true),
          width: isAdmin ? '30%' : '25%',
        },
        {
          Header: t('common.skyboxes'),
          accessor: 'ownSkyboxInfo',
          Cell: ({
            row: { original },
          }: {
            row: { original: ListSkyboxEventSeriesEventsResponseItemDto };
          }) => {
            if (isAdmin) return '';

            return (
              <Box className={classes.skyboxesCell}>
                {!!original.ownSkyboxInfo?.reservedCount && (
                  <Availability color="yellow">
                    {t('events.waitingCount', {
                      count: original.ownSkyboxInfo?.reservedCount,
                    })}
                  </Availability>
                )}
                {!!original.ownSkyboxInfo?.vacantCount && (
                  <Availability color="green">
                    {t('events.vacantForEventCount', {
                      count: original.ownSkyboxInfo?.vacantCount,
                    })}
                  </Availability>
                )}
              </Box>
            );
          },
          width: '20%',
        },
        {
          Header: t('common.action'),
          accessor: 'action',
          width: 275,
          Cell: ({ row: { original } }: any) => {
            return (
              <Button variant="outlined" onClick={onOpenEventClick(original)}>
                {t('events.openEvent')}
              </Button>
            );
          },
        },
      ];

      if (isAdmin) {
        return COLUMNS.filter((col) => col.accessor !== 'skyboxes');
      }
      return COLUMNS;
    }, [classes, isAdmin, onOpenEventClick, t]);

    const navigateWithParams = useCallback(
      (params: any) => {
        const newUrlParams = new URLSearchParams(params as any);

        history.push({
          pathname: location.pathname,
          search: newUrlParams.toString(),
        });
      },
      [history, location.pathname],
    );

    const onPageChange = async (pageIndex: number) => {
      if (pageIndex === null) return;
      if (pageIndex !== query?.page) {
        const newParams = { ...query };
        newParams.page = pageIndex;
        navigateWithParams(newParams);
      }
    };

    const onSearchChange = useMemo(() => {
      return debounce(async (value: string) => {
        const newParams = { ...query };
        newParams.search = value;
        newParams.page = 0;
        navigateWithParams(newParams);
      }, 500);
    }, [navigateWithParams, query]);

    const onPageSizeChange = (pageSize: number) => {
      if (pageSize === undefined) return;
      const newParams = { ...query };
      newParams.pageSize = pageSize;
      newParams.page = 0;
      navigateWithParams(newParams);
    };

    return (
      <Box p={3}>
        <Box
          mb={3}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <T color="textSecondary">
            {t('events.eventCount', { count: viewModel.totalEventCount })}
          </T>
          <SearchInput
            onChange={onSearchChange}
            useDebounce
            value={query.search}
          />
        </Box>
        <MaterialTable
          columns={columns}
          data={viewModel.events}
          onPageChange={onPageChange}
          onPageSizeChange={onPageSizeChange}
          totalCount={viewModel.totalEventCount}
          defaultPageSize={query.pageSize}
          paginationEnabled
          useControlledState
          controlledPageIndex={query.page}
          isLoading={viewModel.loading}
          loading={viewModel.loading}
        />
      </Box>
    );
  },
);
