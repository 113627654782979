import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
} from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { OrderLayout, OrderLayoutState } from '../../components/Layout';
import { SimpleTable } from '../../components/SimpleTable';
import { PATHS } from '../../constants/routes';
import OrderService from '../../services/OrderService';
import { useStores } from '../../stores/index';
import colors from '../../styles/colors';
import { getGroupedItems } from '../../utils/commonUtils';
import { getPrettyDate } from '../../utils/i18nUtils';

const OrderReceiptDownloadButton = observer(
  ({ orderId }: { orderId: string }) => {
    const { t } = useTranslation();
    const {
      toastStore: { showError },
    } = useStores();

    const [loading, setLoading] = useState(false);

    const onClick = useCallback(async () => {
      setLoading(true);
      try {
        await OrderService.getReceiptPdf(orderId);
      } catch {
        showError('errors.order.getReceipt');
      } finally {
        setLoading(false);
      }
    }, [orderId, showError]);

    return (
      <Button
        variant="outlined"
        type="button"
        onClick={onClick}
        disabled={loading}
        startIcon={
          loading && (
            <CircularProgress
              color="inherit"
              style={{ width: '20px', height: '20px' }}
            />
          )
        }
      >
        {t('orders.detailsModal.getReceipt')}
      </Button>
    );
  },
);

export const OrderDetailsScreen = observer(() => {
  const { t } = useTranslation();
  const history = useHistory();

  const {
    skyboxStore: { skyboxOrder, getSkyboxInvites },
    authStore: { isOwner },
    orderStore: { getOrder, order },
    cartStore: {
      createCart,
      setEventIds,
      setActiveStep,
      setSkyboxId,
      addSkyboxToCart,
    },
  } = useStores();

  const { state } = history.location as { state: OrderLayoutState };
  const { livexOrderNumber }: { livexOrderNumber: string } = useParams();

  const [showChildOrder, setShowChildOrder] = useState(!!state?.showChildOrder);
  const [id, setId] = useState<string | undefined>(state?.id);

  const tickets = useMemo(() => {
    if (showChildOrder) return order?.tickets;
    return skyboxOrder?.purchase?.tickets;
  }, [order, showChildOrder, skyboxOrder]);

  const currentOrderId = useMemo(() => {
    if (showChildOrder) {
      return order?.id;
    }
    return skyboxOrder?.purchase?.id;
  }, [showChildOrder, order, skyboxOrder]);

  const servingPurchases = useMemo(() => {
    let purchases = [] as IServingPurchase[];
    if (showChildOrder) {
      purchases = order?.servingPurchases || [];
    } else {
      purchases = skyboxOrder?.purchase?.servingPurchases || [];
    }
    const grouped = getGroupedItems(purchases, 'product.category.name');

    if (!grouped || !purchases.length) return [];

    return [
      {
        label: 'orders.detailsModal.servings',
        render: () => {
          return (
            <Box>
              {grouped.map((item: GroupedArrayItem) => (
                <Box key={item.id}>
                  <Typography>{item.title}</Typography>
                  {item.children.map((servingPurchase: IServingPurchase) => (
                    <Typography
                      key={servingPurchase.id}
                    >{`- ${servingPurchase.amount}x ${servingPurchase.product.name}`}</Typography>
                  ))}
                </Box>
              ))}
            </Box>
          );
        },
      },
    ];
  }, [order, showChildOrder, skyboxOrder]);

  const groupedTickets = useMemo(() => {
    if (!tickets) return [];
    return getGroupedItems(tickets, 'eventimTicketData.TicketTypeText');
  }, [tickets]);

  const parentPurchaseData = [
    {
      label: 'orders.orderNumber',
      value: showChildOrder
        ? order?.livexOrderNumber
        : skyboxOrder?.purchase?.livexOrderNumber,
    },
    {
      label: 'orders.orderDate',
      value: getPrettyDate(
        showChildOrder ? order?.paidOn : skyboxOrder?.purchase?.paidOn,
        true,
        t('common.clockAbbr'),
      ),
    },
  ];

  const getChildPurchaseData = (purchase: any) => [
    {
      label: 'orders.orderNumber',
      value: purchase.livexOrderNumber,
    },
    {
      label: 'orders.orderDate',
      value: getPrettyDate(purchase.paidOn, true, t('common.clockAbbr')),
    },
  ];

  const eventData = [
    {
      label: 'common.eventSeries',
      value: skyboxOrder?.eventSkybox?.event?.title,
    },
    {
      label: 'common.event',
      value: getPrettyDate(
        skyboxOrder?.eventSkybox?.event?.date,
        true,
        t('common.clockAbbr'),
      ),
    },
    {
      label: 'common.skybox',
      value: skyboxOrder?.eventSkybox?.skybox?.name,
    },
  ];

  const orderContent = [
    {
      label: 'common.tickets',
      hide: !groupedTickets?.length,
      value: (
        <Box>
          {groupedTickets.map((ticket) => (
            <Box key={ticket.id}>{`${ticket.quantity}x ${ticket.title}`}</Box>
          ))}
        </Box>
      ),
    },
    ...servingPurchases,
  ];

  const otherData = [
    {
      label: 'common.host',
      render: () => {
        if (!skyboxOrder) return null;
        let contactObj:
          | { contactName: string; contactEmail: string; contactPhone: string }
          | undefined = undefined;

        if (showChildOrder) {
          contactObj = order?.parent?.purchaseSkyboxes?.length
            ? order.parent.purchaseSkyboxes?.[0]
            : undefined;
        } else {
          contactObj = skyboxOrder;
        }

        if (!contactObj) return;

        return (
          <Box>
            <Box>{contactObj.contactName}</Box>
            <Box>{contactObj.contactEmail}</Box>
            <Box>{contactObj.contactPhone}</Box>
          </Box>
        );
      },
    },
    {
      label: 'orders.customerInformation',
      render: () => {
        if (!skyboxOrder?.purchase?.orderUserInfo) return null;
        const {
          firstName,
          lastName,
          address,
          city,
          country,
          postalCode,
          phone,
          email,
        } = skyboxOrder?.purchase.orderUserInfo;
        return (
          <Box>
            <Box>{`${firstName} ${lastName}`}</Box>
            <Box mt={2}>
              <Box>{`${address}`}</Box>
              <Box>{`${postalCode} ${city}`}</Box>
              <Box>{`${country}`}</Box>
            </Box>
            <Box mt={2}>
              <Box>{`${phone}`}</Box>
              <Box>{`${email}`}</Box>
            </Box>
          </Box>
        );
      },
    },
    {
      label: 'orders.paymentMethod',
      value: skyboxOrder?.purchase?.paymentMethod
        ? t(`common.paymentMethod.${skyboxOrder.purchase.paymentMethod}`)
        : '',
    },
  ];

  const onBuyTicketsClick = async () => {
    setActiveStep(1);
    setSkyboxId(skyboxOrder?.eventSkybox.skyboxId);
    setEventIds({
      eventSeriesId: skyboxOrder?.event.eventSeriesId,
      eventId: skyboxOrder?.event.id,
    });
    if (skyboxOrder?.eventSkybox.type === 'whole_sale') {
      if (skyboxOrder.purchaseId) {
        await createCart(skyboxOrder?.purchaseId);
      } else {
        await addSkyboxToCart();
      }
    } else {
      await createCart(skyboxOrder?.purchaseId);
    }
    history.push(PATHS.orders.add.tickets);
  };

  const onOpenChildOrderClick = async (orderId: any) => {
    await getOrder(orderId);
    await getSkyboxInvites(orderId);
    setId(orderId);
    setShowChildOrder(true);
  };

  const onReturnToParentClick = async () => {
    await getSkyboxInvites(skyboxOrder?.purchaseId);
    setId(skyboxOrder?.purchaseId);
    setShowChildOrder(false);
  };

  return (
    <OrderLayout
      livexOrderNumber={livexOrderNumber}
      showChildOrder={showChildOrder}
      id={id}
    >
      <Box>
        <Box>
          {showChildOrder && (
            <Box p={2}>
              <Button onClick={onReturnToParentClick}>
                {t('orders.detailsModal.returnToParent')}
              </Button>
            </Box>
          )}
          <Box p={2}>
            <Grid container>
              <Grid item xs={12} lg={6}>
                <Typography variant="h3">
                  {t('orders.detailsModal.basicInformation')}
                </Typography>
                <SimpleTable items={parentPurchaseData} mt={2} mb={2} />

                <Typography variant="h3">{t('orders.eventBox')}</Typography>
                <SimpleTable items={eventData} mt={2} mb={2} />

                <Typography variant="h3">
                  {t('orders.detailsModal.orderContent')}
                </Typography>
                <SimpleTable items={orderContent} mt={2} mb={2} />
                {currentOrderId && (
                  <Box mb={2}>
                    <OrderReceiptDownloadButton orderId={currentOrderId} />
                  </Box>
                )}

                <Typography variant="h3">{t('common.other')}</Typography>
                <SimpleTable items={otherData} mt={2} mb={2} />
              </Grid>
              <Grid item xs={12} lg={6}>
                {!showChildOrder &&
                  !!skyboxOrder?.purchase?.children?.length && (
                    <Box>
                      <Typography variant="h3">
                        {t('orders.detailsModal.childOrders')}
                      </Typography>
                      {skyboxOrder?.purchase?.children
                        ?.filter(
                          (child: IOrder) => child.paidOn || child.orderedOn,
                        )
                        .map((child: IOrder) => {
                          return (
                            <Box
                              mt={2}
                              p={3}
                              key={child.id}
                              bgcolor={colors.background.bg2}
                            >
                              <SimpleTable
                                items={getChildPurchaseData(child)}
                              />
                              <Box mt={1}>
                                <Button
                                  variant="outlined"
                                  onClick={() =>
                                    onOpenChildOrderClick(child.id)
                                  }
                                >
                                  {t('orders.detailsModal.openDetails')}
                                </Button>
                              </Box>
                            </Box>
                          );
                        })}
                    </Box>
                  )}
              </Grid>
            </Grid>
          </Box>
          {!showChildOrder && isOwner && (
            <Box p={2}>
              <Button onClick={onBuyTicketsClick}>
                {t('orders.detailsModal.buyTickets')}
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    </OrderLayout>
  );
});
