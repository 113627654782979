import { Box, Tab, Tabs } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { ChangeEvent, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PasswordForm } from '../../../../screens/Users/PasswordForm';
import { UserForm } from '../../../../screens/Users/UserForm';
import { useStores } from '../../../../stores/index';
import { CommonModal } from '../../CommonModal';

interface EditUserModalProps {
  open: boolean;
  isLoading: boolean;
  user?: IAdminUser;
  onSubmit: (values: any, form: any, cb: any) => void;
  onCancel: () => void;
}

export const EditUserModal: FC<EditUserModalProps> = observer(
  ({ open, isLoading, user, onSubmit, onCancel }) => {
    const { t } = useTranslation();
    const {
      authStore: { isOwner, currentUser },
      ownerStore: { ownerListPage },
    } = useStores();
    const [activeTab, setActiveTab] = useState(0);

    const onTabChange = (_event: ChangeEvent<{}>, value: any) => {
      setActiveTab(value);
    };

    const isCurrentUser = currentUser?.id === user?.id;

    const BasicForm = (
      <UserForm
        initialValues={user}
        onSubmit={onSubmit}
        onCancel={onCancel}
        isLoading={isLoading}
        isEdit
        isOwner={isOwner}
        ownerList={ownerListPage}
      />
    );

    const CurrentUserForm = (
      <Box>
        <Box mb={5}>
          <Tabs
            value={activeTab}
            onChange={onTabChange}
            TabIndicatorProps={{
              style: { background: '#fff' },
            }}
          >
            <Tab label={t('user.basicInformation')} />
            <Tab label={t('user.changePassword')} />
          </Tabs>
        </Box>
        {activeTab === 0 && BasicForm}
        {activeTab === 1 && (
          <PasswordForm isLoading={isLoading} onCancel={onCancel} />
        )}
      </Box>
    );

    return (
      <CommonModal maxWidth={800} open={open}>
        {isCurrentUser ? CurrentUserForm : BasicForm}
      </CommonModal>
    );
  },
);
