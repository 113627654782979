import countries from 'i18n-iso-countries';
import moment from 'moment-timezone';

export const DEFAULT_TIME_ZONE = 'Europe/Helsinki';

/**
 * Returns a list of countries
 */
export const getCountryOptions = (locale: string) => {
  const alpha2Codes = countries.getAlpha2Codes();
  const keyValues = countries.getNames(locale);

  let options = Object.keys(keyValues).map((id) => ({
    id: alpha2Codes[id],
    label: keyValues[id],
  }));

  // @ts-ignore
  options.unshift({ id: null, label: '' });
  return options;
};

/**
 * Takes in a ISO date and returns it in a pretty form
 */
export const getPrettyDate = (
  date?: string | Date,
  time?: boolean,
  clockAbbr?: string,
  includeDay?: boolean,
) => {
  if (!date) return null;
  const format = includeDay ? 'dd L' : 'L';
  let dateStr = moment(date).tz(DEFAULT_TIME_ZONE).format(format);
  dateStr = dateStr.charAt(0).toUpperCase() + dateStr.substring(1);
  if (!time) return dateStr;

  const timeStr = moment(date).tz(DEFAULT_TIME_ZONE).format('HH:mm');
  return `${dateStr} ${clockAbbr || ''} ${timeStr}`;
};

export const getPrettyDatePeriod = (
  startDate: string,
  endDate: string,
  includeTime?: boolean,
) => {
  return `${getPrettyDate(startDate, includeTime)}–${getPrettyDate(
    endDate,
    includeTime,
  )}`;
};

export const getPrettySum = (sum: number, includeCurrency?: boolean) => {
  const resolvedSum = `${(sum / 100).toFixed(2).replace('.', ',')}`;
  return includeCurrency ? `${resolvedSum} €` : resolvedSum;
};

export const getPrettyTimePeriod = (startDate?: string, endDate?: string) => {
  if (!startDate || !endDate) return '-';
  const startTime = moment(startDate).tz(DEFAULT_TIME_ZONE).format('HH:mm');
  const endTime = moment(endDate).tz(DEFAULT_TIME_ZONE).format('HH:mm');
  return `${startTime}–${endTime}`;
};
