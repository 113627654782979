import { Box, Button, Divider, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { IconTextButton } from '../../components/IconTextButton/IconTextButton';
import { Layout } from '../../components/Layout';
import { CommonModal } from '../../components/Modals';
import { PATHS } from '../../constants/routes';
import BulletinService from '../../services/BulletinService';
import { useStores } from '../../stores/index';
import { BulletinForm } from './BulletinForm';

export interface SingleBulletinScreenProps {}

export const SingleBulletinScreen: FC<SingleBulletinScreenProps> = observer(
  () => {
    const {
      bulletinStore: { bulletin, getBulletin, updateBulletin, deleteBulletin },
      authStore: { isOwner, isSuperOwner },
    } = useStores();

    const { t } = useTranslation();
    const history = useHistory();
    const { id: idParam }: { id: string } = useParams();
    const [modalOpen, setModalOpen] = useState(false);

    useEffect(() => {
      getBulletin(idParam);
    }, [getBulletin, idParam]);

    useEffect(() => {
      const canMarkAsRead = isOwner || isSuperOwner;
      if (bulletin && !bulletin.readAt && canMarkAsRead) {
        BulletinService.markBulletinRead(bulletin.id);
      }
    }, [isOwner, bulletin, isSuperOwner]);

    const navigateToList = () => history.push(PATHS.bulletins.main);

    const onSubmit = async (formValues: any) => {
      const data = {
        ...formValues,
      };
      await updateBulletin(idParam, data);
      navigateToList();
    };

    const onDeleteClick = () => {
      setModalOpen(true);
    };

    const onDeleteConfirm = async () => {
      await deleteBulletin(idParam);
      navigateToList();
    };

    const onDeleteCancel = () => {
      setModalOpen(false);
    };

    const EditContent = (
      <BulletinForm
        onSubmit={onSubmit}
        onCancel={navigateToList}
        initialValues={bulletin}
        title={t('bulletin.editBulletin')}
      />
    );

    const ReadContent = (
      <Box p={3}>
        <Box dangerouslySetInnerHTML={{ __html: bulletin?.content || '' }} />
        <Box my={3}>
          <Divider />
        </Box>
        <Button variant="outlined" onClick={() => navigateToList()}>
          {t('bulletin.backToList')}
        </Button>
      </Box>
    );

    return (
      <Layout
        headerText={bulletin?.title}
        headerElementsRight={
          !isOwner && (
            <IconTextButton
              type="delete"
              label="bulletin.deleteBulletin"
              onClick={onDeleteClick}
            />
          )
        }
      >
        {isOwner ? ReadContent : EditContent}
        <CommonModal
          onConfirm={onDeleteConfirm}
          onCancel={onDeleteCancel}
          open={modalOpen}
          title={t('bulletin.deleteModal.title')}
          confirmText={t('bulletin.deleteModal.confirm')}
          type="prompt"
        >
          <Typography>
            {t('bulletin.deleteModal.body', { name: bulletin?.title })}
          </Typography>
        </CommonModal>
      </Layout>
    );
  },
);
