export const LANGUAGES = [
  { id: 'fi', label: 'common.languages.finnish' },
  { id: 'sv', label: 'common.languages.swedish' },
  { id: 'en', label: 'common.languages.english' },
];

export const MINIMUM_PASSWORD_LENGTH = 10;

export const DEFAULT_PAGE_SIZE = 10;

export const MINIMUM_SKYBOX_TICKET_COUNT = 4;

export const DEFAULT_LANGUAGE = LANGUAGES[0]!.id;

export const DEFAULT_SKYBOX_VAT = 25.5;
export const DEFAULT_INVOICING_FEE_VAT = 25.5;

// Check if the env file has been generated / passed correctly
if (Object.keys(window.__ENV ?? {}).length === 0) {
  console.error('🔴 FAILED TO LOAD ENV FILE');
}

// Read runtime variables from file generated by react-env package
export const API_BASE_URL = window.__ENV?.REACT_APP_API_URL;

export const ENV = window.__ENV?.REACT_APP_ENV;
export const SENTRY_ENABLED = ENV === 'staging' || ENV === 'production';

export const CUSTOMER_ID = window.__ENV?.REACT_APP_CUSTOMER_ID;

export const GTM_ID = window.__ENV?.REACT_APP_GTM_ID;

export const PRIMARY_COLOR = window.__ENV?.REACT_APP_PRIMARY_COLOR;

export const SKYBOX_SALES_DEFAULT_CHANNEL = 'nokiaarena';
