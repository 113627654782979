import DateFnsUtils from '@date-io/date-fns';
import MomentUtils from '@date-io/moment';
import '@fontsource/inter';
import { CssBaseline, MuiThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import fiDateLocale from 'date-fns/locale/fi';
import 'moment/locale/fi';
import ReactDOM from 'react-dom';
import App from './App';
import { ToastProvider } from './components/Toast';
import { initLang } from './i18n/index';
import { stores, StoresProvider } from './stores/Stores';
import theme from './styles/theme';

initLang('fi');

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <StoresProvider value={stores}>
        <MuiPickersUtilsProvider locale={fiDateLocale} utils={DateFnsUtils}>
          <CssBaseline />
          <App />
          <ToastProvider />
        </MuiPickersUtilsProvider>
      </StoresProvider>
    </MuiPickersUtilsProvider>
  </MuiThemeProvider>,
  document.getElementById('root'),
);
