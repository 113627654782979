import { Box, makeStyles, Typography } from '@material-ui/core';
import { FormState } from 'final-form';
import { observer } from 'mobx-react-lite';
import React, { ReactNode } from 'react';
import { FormSpyProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useStores } from '../../../../stores/index';

interface TicketPersonalizationContentProps {
  formState?: FormState<FormSpyProps>;
  getPersonalizationFields: (id?: string) => ReactNode | null;
}

const useStyles = makeStyles({
  ticketContainer: {
    maxHeight: '60vh',
    overflow: 'hidden',
    overflowY: 'auto',
  },
});

export const TicketPersonalizationContent: React.FC<TicketPersonalizationContentProps> =
  observer(({ getPersonalizationFields }) => {
    const {
      cartStore: { cart },
    } = useStores();

    const classes = useStyles();
    const { t } = useTranslation();

    const renderTicketHeader = (ticket: ITicketPurchase) => {
      const {
        eventimTicketData: { Area, TicketTypeText, Row, Seat, SeatInfo2 },
      } = ticket;

      return (
        <Box>
          <Typography component="h3" variant="h3">
            {TicketTypeText}
          </Typography>
          {Row && Seat && (
            <Typography variant="body2">{`${Area}, ${t(
              'common.row',
            )} ${Row}, ${t('common.seat')} ${Seat}`}</Typography>
          )}
          {SeatInfo2 && <Typography variant="body2">{SeatInfo2}</Typography>}
        </Box>
      );
    };

    const renderTicketFields = (item: ITicketPurchase) =>
      getPersonalizationFields(item.id);

    if (!cart) return null;
    return (
      <Box className={classes.ticketContainer}>
        <Typography>
          {t('orders.add.personalizationModal.ticketPersonalization.body')}
        </Typography>
        <Box mt={3} />
        {cart.tickets.map((ticket) => (
          <Box p={3} mb={3}>
            {renderTicketHeader(ticket)}
            {renderTicketFields(ticket)}
          </Box>
        ))}
      </Box>
    );
  });
