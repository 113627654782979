import { Box } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Layout } from '../../components/Layout';
import { PATHS } from '../../constants/routes';
import { useStores } from '../../stores/index';
import { ReportForm } from './ReportForm';

export interface ReportsMainScreenProps {}

export const ReportsMainScreen: React.FC<ReportsMainScreenProps> = observer(
  () => {
    const { t } = useTranslation();
    const history = useHistory();
    const {
      authStore: { isOwner },
      reportStore: { getReport },
    } = useStores();

    const onSubmit = async (values: any) => {
      const LIST_PATH = isOwner
        ? PATHS.skyboxReports.owners.list
        : PATHS.skyboxReports.list;

      await getReport(values);
      history.push(LIST_PATH);
    };

    return (
      <Layout headerText={t('reports.reports')}>
        <Box p={3}>
          <ReportForm
            onSubmit={onSubmit}
            submitText={t('reports.createReport')}
          />
        </Box>
      </Layout>
    );
  },
);
