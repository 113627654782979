import {
  Box,
  Container,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Layout } from '../../components/Layout';
import { Link } from '../../components/Link';
import { PATHS } from '../../constants';
import { useStore } from '../../stores';
import ResetPasswordForm from './ResetPasswordForm';

interface ResetPasswordScreenProps {}

const useStyles = makeStyles({
  fullHeight: {
    height: '100%',
    minHeight: '100vh',
  },
  center: {
    textAlign: 'center',
  },
  formContainer: {
    height: '100%',
    width: '100%',
    maxWidth: '960px',
  },
});

export const ResetPasswordScreen: FC<ResetPasswordScreenProps> = observer(
  () => {
    const { resetPassword, isLoading } = useStore('authStore');
    const {
      userId: userIdParam,
      token: tokenParam,
    }: { userId: string; token: string } = useParams();
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();

    const onResetPasswordSubmit = (formValues: any) => {
      resetPassword(
        {
          userId: userIdParam,
          token: tokenParam,
          password: formValues.password,
        },
        () => history.replace(PATHS.auth.login),
      );
    };

    return (
      <Layout>
        <Grid container justify="center" className={classes.fullHeight}>
          <Box className={classes.formContainer}>
            <Grid
              container
              justify="center"
              alignItems="center"
              className={classes.fullHeight}
            >
              <Grid item xs={12} md={8} lg={6}>
                <Container>
                  <Box mb={4} className={classes.center}>
                    <Typography variant="h4">
                      {t('login.boxManagement')}
                    </Typography>
                  </Box>
                  <Box mb={4} className={classes.center}>
                    <Typography variant="h1">
                      {t('login.changePassword')}
                    </Typography>
                  </Box>
                  <Box>
                    <ResetPasswordForm
                      onSubmit={onResetPasswordSubmit}
                      isLoading={isLoading}
                    />
                  </Box>
                  <Box mt={4} className={classes.center}>
                    <Link to="/login">{t('login.backToLogin')}</Link>
                  </Box>
                </Container>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Layout>
    );
  },
);
