import { observer } from 'mobx-react-lite';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Layout } from '../../components/Layout/Layout';
import { SkyboxEventList } from '../../components/SkyboxEventList/SkyboxEventList';
import { useStores } from '../../stores/index';

export const AllEventsScreen: FC = observer(() => {
  const {
    eventStore: { reset },
  } = useStores();

  const { t } = useTranslation();
  const PAGE_SIZE = 10;

  useEffect(() => {
    return () => {
      reset();
    };
  }, [reset]);

  return (
    <Layout headerText={t('common.events')}>
      <SkyboxEventList pageSize={PAGE_SIZE} />
    </Layout>
  );
});
