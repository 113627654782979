import { makeTestIdProps } from '@guinie/react-testid';
import { Box, Grid, MenuItem, Typography } from '@material-ui/core';
import { makeRequired, makeValidate, Select, TextField } from 'mui-rff';
import { FC, useMemo } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { Button } from '../../components/FormControls';
import { MINIMUM_PASSWORD_LENGTH } from '../../constants/siteSettings';
import { Validator } from '../../utils/validation';

interface UserFormProps {
  initialValues?: IAdminUser;
  ownerList?: IOwner[];
  isLoading: boolean;
  isEdit: boolean;
  isOwner: boolean;
  onCancel: () => void;
  onSubmit: (values: any, form: any, callback: any) => void;
}

const getDefaultValues = (values?: IAdminUser) => {
  let role = '';
  if (values?.roles.includes('SkyboxAdmin')) role = 'SkyboxAdmin';
  if (values?.roles.includes('SkyboxOwner')) role = 'SkyboxOwner';
  if (values?.roles.includes('SuperOwner')) role = 'SuperOwner';
  if (values?.roles.includes('Admin')) role = 'Admin';
  if (values?.roles.includes('Manager')) role = 'Manager';

  let companyId = '';
  if (values?.companies?.length) {
    companyId = values.companies[0].id;
  }
  return {
    id: values?.id || undefined,
    firstName: values?.firstName || '',
    lastName: values?.lastName || '',
    email: values?.email || '',
    password: '',
    role,
    companyId,
  };
};

export const UserForm: FC<UserFormProps> = ({
  initialValues,
  ownerList,
  isLoading,
  isEdit,
  isOwner,
  onSubmit,
  onCancel,
}) => {
  const { t } = useTranslation();

  const schema = Validator.object().shape({
    firstName: Validator.string().max(255).required(),
    lastName: Validator.string().max(255).required(),
    email: Validator.string().email().max(255).required(),
    password: isEdit
      ? Validator.string()
      : Validator.string()
          .hasSpecialChars()
          .hasLowerCase()
          .hasUpperCase()
          .hasNumber()
          .min(MINIMUM_PASSWORD_LENGTH)
          .required(),
    role: isOwner ? Validator.string() : Validator.string().required(),
    companyId: isOwner
      ? Validator.string()
      : Validator.string().when('role', {
          is: 'SkyboxOwner',
          then: Validator.string().required(t('validation.required')),
        }),
  });

  const validate = makeValidate(schema);
  const required = makeRequired(schema);
  const defaultValues = useMemo(
    () => getDefaultValues(initialValues),
    [initialValues],
  );

  const ownerOptions = useMemo(() => {
    if (ownerList) {
      return ownerList.map((owner) => ({
        value: owner.id,
        label: owner.name,
      }));
    }
    return [];
  }, [ownerList]);

  const roleOptions = [
    { value: 'SkyboxAdmin', label: t('common.skyboxAdmin') },
    { value: 'SkyboxOwner', label: t('common.skyboxOwner') },
    { value: 'SuperOwner', label: t('common.superOwner') },
    { value: 'Janitor', label: t('common.janitor') },
  ];

  return (
    <Form
      initialValues={isEdit && defaultValues}
      validate={validate}
      onSubmit={onSubmit}
      render={({ handleSubmit, values, hasValidationErrors }) => {
        return (
          <form onSubmit={handleSubmit} noValidate>
            <Grid container>
              <Grid item>
                <Box>
                  <Grid spacing={3} container>
                    <Grid item xs={12}>
                      <Box>
                        <Typography variant="h2">
                          {t(isEdit ? 'user.editUser' : 'user.addNewUser')}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="firstName"
                        name="firstName"
                        label={t('common.firstName')}
                        required={required.firstName}
                        inputProps={makeTestIdProps(
                          'create-user-form__firstName',
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="lastName"
                        name="lastName"
                        label={t('common.lastName')}
                        required={required.lastName}
                        inputProps={makeTestIdProps(
                          'create-user-form__lastName',
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="email"
                        name="email"
                        label={t('common.email')}
                        required={required.email}
                        inputProps={makeTestIdProps('create-user-form__email')}
                      />
                    </Grid>
                    {!isEdit && (
                      <Grid item xs={12}>
                        <TextField
                          id="password"
                          type="password"
                          name="password"
                          label={t('common.password')}
                          required={required.password}
                          inputProps={makeTestIdProps(
                            'create-user-form__password',
                          )}
                        />
                      </Grid>
                    )}
                    {!isOwner && (
                      <Grid item xs={6}>
                        <Select
                          id="role"
                          name="role"
                          label={t('user.userRole')}
                          required={required.role}
                          SelectDisplayProps={makeTestIdProps(
                            'create-user-form__userRole',
                          )}
                        >
                          {roleOptions.map((option) => (
                            <MenuItem
                              value={option.value}
                              {...makeTestIdProps(
                                `create-user-form__userRole--${option.label}`,
                              )}
                            >
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </Grid>
                    )}
                    {!isOwner && values.role === 'SkyboxOwner' && (
                      <Grid item xs={12}>
                        <Select
                          id="companyId"
                          name="companyId"
                          label={t('common.owner')}
                          data={ownerOptions}
                          required={required.companyId}
                        />
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <Box display="flex">
                        <Box mr={2}>
                          <Button
                            id="saveButton"
                            type="submit"
                            t="common.save"
                            color="secondary"
                            loading={isLoading}
                            disabled={hasValidationErrors}
                            {...makeTestIdProps('create-user-form__submit')}
                          />
                        </Box>
                        <Button
                          id="cancelButton"
                          variant="outlined"
                          t="common.cancel"
                          loading={isLoading}
                          onClick={onCancel}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </form>
        );
      }}
    />
  );
};
