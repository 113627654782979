import { Box, Button, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import queryString from 'query-string';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { Layout } from '../../components/Layout';
import MaterialTable from '../../components/MaterialTable/MaterialTable';
import { SearchInput } from '../../components/SearchInput';
import { PATHS } from '../../constants/routes';
import { useStore } from '../../stores';
import { IEventSeries } from '../../types/Event';
import { getPrettyDatePeriod } from '../../utils/i18nUtils';

export const EventSerieListScreen: React.FC = observer(() => {
  const { getEventSeries, eventSeries, eventSeriesCount } =
    useStore('eventStore');
  const history = useHistory();
  const { t } = useTranslation();
  const location = useLocation();
  const PAGE_SIZE = 10;

  const query = useMemo(() => {
    const params = queryString.parse(location.search) as unknown as {
      search?: string;
      page: number;
    };
    params.page = Number(params.page);
    if (isNaN(params.page)) params.page = 0;
    return params;
  }, [location]);

  useEffect(() => {
    const { page, search } = query;
    const skip = page * PAGE_SIZE;
    getEventSeries({ take: PAGE_SIZE, skip, search });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const onOpenEventSeriesClick = useCallback(
    (eventSeries: IEventSeries) => () => {
      const path = PATHS.skyboxEvents.eventList.replace(
        ':id',
        String(eventSeries.id),
      );

      history.push(path);
    },
    [history],
  );

  const columns = useMemo(() => {
    return [
      {
        Header: t('events.eventSeriesName'),
        accessor: 'title',
        width: '50%',
      },
      {
        Header: t('common.date'),
        accessor: 'startDate',
        Cell: ({ row: { original } }: any) => {
          return getPrettyDatePeriod(original.startDate, original.endDate);
        },
        width: '25%',
      },
      {
        Header: t('common.events'),
        accessor: 'eventCount',
        width: '10%',
      },
      {
        Header: t('common.action'),
        accessor: 'action',
        width: 275,
        Cell: ({ row: { original } }: any) => {
          return (
            <Button
              variant="outlined"
              onClick={onOpenEventSeriesClick(original)}
            >
              {t('events.openEvent')}
            </Button>
          );
        },
      },
    ];
  }, [onOpenEventSeriesClick, t]);

  const navigateWithParams = (params: any) => {
    const newUrlParams = new URLSearchParams(params as any);

    history.push({
      pathname: location.pathname,
      search: newUrlParams.toString(),
    });
  };

  const onPageChange = async (pageIndex: number) => {
    if (pageIndex === null) return;
    if (pageIndex !== query?.page) {
      const newParams = { ...query };
      newParams.page = pageIndex;
      navigateWithParams(newParams);
    }
  };

  const onSearchChange = async (value: string) => {
    const newParams = { ...query };
    newParams.search = value;
    newParams.page = 0;
    navigateWithParams(newParams);
  };

  return (
    <Layout headerText={t('events.eventSerie')}>
      <Box p={3}>
        <Box
          my={3}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography color="textSecondary">
            {t('events.eventSeriesCount', { count: eventSeriesCount })}
          </Typography>
          <SearchInput onChange={onSearchChange} useDebounce />
        </Box>
        <MaterialTable
          columns={columns}
          data={eventSeries}
          onPageChange={onPageChange}
          totalCount={eventSeriesCount}
          defaultPageSize={PAGE_SIZE}
          paginationEnabled
          useControlledState
          controlledPageIndex={query.page}
        />
      </Box>
    </Layout>
  );
});
