import { useEffect } from 'react';

export const usePolling = (pollFn: () => void, pollInterval: number) => {
  useEffect(() => {
    const interval = setInterval(() => {
      pollFn();
    }, pollInterval);
    return () => clearInterval(interval);
  }, [pollFn, pollInterval]);
};
