import { makeAutoObservable, runInAction } from 'mobx';
import CustomerService from '../services/CustomerService';

interface ICustomerStore {
  isLoading: boolean;
  state: StoreState;
  currentCustomer?: ICustomer;
  customers: { [slug: string]: ICustomer };
  getCurrentCustomer: () => void;
}

class CustomerStoreModel implements ICustomerStore {
  formValues: any = {};

  state: StoreState = 'Idle';

  currentCustomer: ICustomerStore['currentCustomer'] = undefined;

  customers: ICustomerStore['customers'] = {};

  constructor() {
    makeAutoObservable(this);
  }

  get isLoading() {
    return this.state === 'Loading';
  }

  get logo() {
    return this.currentCustomer?.logoAsset?.source;
  }

  setFormValues = (formValues: any) => {
    this.formValues = formValues;
  };

  getCurrentCustomer = async () => {
    this.getCustomer();
  };

  getCustomer = async (slug?: string) => {
    this.state = 'Loading';
    try {
      const customerObj = await CustomerService.getCustomer(slug);
      runInAction(() => {
        if (!slug) {
          this.currentCustomer = customerObj;
        }
        this.customers = {
          ...this.customers,
          [slug ?? customerObj.slug]: customerObj,
        };
        this.state = 'Success';
      });
    } catch (error) {
      runInAction(() => {
        this.state = 'Error';
      });
      throw error;
    }
  };
}

const CustomerStore = new CustomerStoreModel();

export default CustomerStore;
