import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { SkyboxEventListLayout } from '../../components/Layout/hocs/SkyboxEventListLayout';
import { SkyboxReservationSettings } from '../../components/SkyboxReservationSettings';
import { useStore } from '../../stores/index';

export const EventSeriesSettingsScreen = observer(() => {
  const { t } = useTranslation();
  const { id }: { id: string } = useParams();
  const {
    eventSeriesSkyboxes,
    getEventSeriesSkyboxes,
    updateEventSeriesSkyboxSettings,
    getEventSeriesSkyboxSettings,
    eventSeriesSkyboxSettings,
  } = useStore('skyboxStore');

  useEffect(() => {
    getEventSeriesSkyboxes(id);
    getEventSeriesSkyboxSettings(id);
  }, [getEventSeriesSkyboxes, id, getEventSeriesSkyboxSettings]);

  const onSave = (values: any) => {
    return updateEventSeriesSkyboxSettings(id, values);
  };

  return (
    <SkyboxEventListLayout>
      <SkyboxReservationSettings
        data={eventSeriesSkyboxes || []}
        formTitle={t('events.eventSeriesSettings')}
        onSave={onSave}
        settings={eventSeriesSkyboxSettings}
        type="eventSeries"
      />
    </SkyboxEventListLayout>
  );
});
