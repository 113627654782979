import * as Sentry from '@sentry/react';
import { AxiosError } from 'axios';
import AuthStore from '../stores/AuthStore';
import ToastStore from '../stores/ToastStore';
import { apiURL } from './api';

const isCaptureableStatus = (status: number) => {
  return status === 429 || status >= 500;
};

const errorHandler = (error: AxiosError) => {
  if (error.response) {
    const {
      response: { status },
      config: { url },
    } = error;
    // @ts-ignore
    const path = url?.replace(apiURL || '', '');

    switch (status) {
      // If backend throws 401, we will remove the token and redirect user to login page
      case 401:
        if (path !== '/auth/login') {
          AuthStore.logout();
        }
        break;
      case 429:
        ToastStore.showError('errors.http.429');
        break;
      default:
        break;
    }
  }
  // Capture specific response errors
  if (error.response?.status && isCaptureableStatus(error.response.status)) {
    Sentry.captureException(error);
  }

  // Capture when no response is received
  if (!error.response || error.message?.includes('timeout')) {
    Sentry.captureException(error);
  }

  throw error;
};

export default errorHandler;
