import {
  makeObservable,
  observable,
  computed,
  runInAction,
  action,
} from 'mobx';
import ToastStore from './ToastStore';
import BulletinService from '../services/BulletinService';

interface IBulletinStore {
  allBulletins?: IBulletin[];
  bulletin?: IBulletin;
  bulletinCount: number;
  isLoading: boolean;
  state: StoreState;
  createBulletin: (data: IBulletin) => void;
  deleteBulletin: (id: string) => void;
  getBulletins: (obj?: PaginationDTO) => void;
  getBulletin: (id: string) => void;
  updateBulletin: (id: string, data: IBulletin) => void;
}

class BulletinModel implements IBulletinStore {
  state: IBulletinStore['state'] = 'Idle';

  allBulletins: IBulletinStore['allBulletins'] = undefined;

  bulletinCount: IBulletinStore['bulletinCount'] = 0;

  bulletin: IBulletinStore['bulletin'] = undefined;

  constructor() {
    makeObservable(this, {
      allBulletins: observable,
      bulletin: observable,
      bulletinCount: observable,
      state: observable,
      // COMPUTED
      isLoading: computed,
      createBulletin: action,
      deleteBulletin: action,
      getBulletin: action,
      getBulletins: action,
      updateBulletin: action,
      resetBulletin: action,
      unreadBulletins: computed,
    });
  }

  get isLoading() {
    return this.state === 'Loading';
  }

  get unreadBulletins() {
    if (!this.allBulletins) return false;
    return this.allBulletins.some((bulletin) => !bulletin.readAt);
  }

  resetBulletin = () => (this.bulletin = undefined);

  getBulletins = async (params?: PaginationDTO) => {
    this.state = 'Loading';
    let paramObj = params ?? { take: 10, skip: 0 };

    try {
      const data = await BulletinService.getBulletins(paramObj);

      runInAction(() => {
        this.allBulletins = data[0];
        this.bulletinCount = data[1];
        this.state = 'Success';
      });
    } catch (error) {
      runInAction(() => {
        ToastStore.showError('errors.bulletins.getBulletins');
        this.state = 'Error';
        throw error;
      });
    }
  };

  getBulletin = async (id: string) => {
    this.state = 'Loading';

    try {
      const data = await BulletinService.getBulletin(id);

      runInAction(() => {
        this.bulletin = data;
        this.state = 'Success';
      });
    } catch (error) {
      runInAction(() => {
        ToastStore.showError('errors.bulletins.getBulletin');
        this.state = 'Error';
        throw error;
      });
    }
  };

  createBulletin = async (data: IBulletin) => {
    this.state = 'Loading';

    try {
      await BulletinService.createBulletin(data);

      runInAction(() => {
        this.state = 'Success';
        ToastStore.showSuccess('successes.bulletins.createBulletin');
      });
    } catch (error) {
      runInAction(() => {
        ToastStore.showError('errors.bulletins.createBulletin');
        this.state = 'Error';
        throw error;
      });
    }
  };

  updateBulletin = async (id: string, data: IBulletin) => {
    this.state = 'Loading';

    try {
      await BulletinService.updateBulletin(id, data);

      runInAction(() => {
        this.state = 'Success';
        ToastStore.showSuccess('successes.bulletins.updateBulletin');
      });
    } catch (error) {
      runInAction(() => {
        ToastStore.showError('errors.bulletins.updateBulletin');
        this.state = 'Error';
        throw error;
      });
    }
  };

  deleteBulletin = async (id: string) => {
    this.state = 'Loading';

    try {
      await BulletinService.deleteBulletin(id);

      runInAction(() => {
        this.state = 'Success';
        ToastStore.showSuccess('successes.bulletins.deleteBulletin');
      });
    } catch (error) {
      runInAction(() => {
        ToastStore.showError('errors.bulletins.deleteBulletin');
        this.state = 'Error';
        throw error;
      });
    }
  };
}

const BulletinStore = new BulletinModel();

export default BulletinStore;
