import { Box } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ReportsLayout } from '../../components/Layout/hocs/ReportsLayout';
import Table from '../../components/MaterialTable/MaterialTable';
import { PATHS } from '../../constants/routes';
import { useStores } from '../../stores/index';
import { getOwnerReportColumns } from './ReportColumns';

export interface ReportsOwnerListScreenProps {}

export const ReportsOwnerListScreen: FC<ReportsOwnerListScreenProps> = observer(
  () => {
    const {
      reportStore: { report },
    } = useStores();
    const { t } = useTranslation();
    const history = useHistory();

    useEffect(() => {
      if (!report) history.push(PATHS.skyboxReports.main);
    }, [report, history]);

    if (!report) return null;
    return (
      <ReportsLayout>
        <Box p={3}>
          <Table data={report} columns={getOwnerReportColumns(t)} />
        </Box>
      </ReportsLayout>
    );
  },
);
