import { CUSTOMER_ID } from '../constants/siteSettings';
import { api, getAuthConfig, getPath } from './api';

const API_PATHS = Object.freeze({
  register: '/customer/{customerId}/admin-user',
  login: '/auth/adminLogin',
  me: '/customer/{customerId}/admin-user/me',
  resetPasswordRequest: '/customer/{customerId}/admin-user/passwordReset/reset',
  resetPassword: '/customer/{customerId}/admin-user/passwordReset',
});

class AuthServiceModel {
  login = async (credentials: ILoginCredentials) => {
    const loginCredentialsWithCustomer = {
      ...credentials,
      ...{ customer: CUSTOMER_ID },
    };
    return api.post(API_PATHS.login, loginCredentialsWithCustomer);
  };

  getMe = async () => api.get(getPath(API_PATHS.me), getAuthConfig());

  getResetPasswordEmail = async (data: IGetResetPassword) =>
    api.post(getPath(API_PATHS.resetPassword), data);

  resetPassword = async (data: IResetPassword) =>
    api.post(getPath(API_PATHS.resetPasswordRequest), data);
}

const AuthService = new AuthServiceModel();

export default AuthService;
