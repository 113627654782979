import { Box, Typography as T } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { makeRequired, makeValidate, TextField } from 'mui-rff';
import { FC } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { Button } from '../../components/FormControls';
import { MINIMUM_PASSWORD_LENGTH } from '../../constants/siteSettings';
import { useStores } from '../../stores/index';
import { Validator } from '../../utils/validation';

interface PasswordFormProps {
  onCancel: () => void;
  isLoading?: boolean;
}

export const PasswordForm: FC<PasswordFormProps> = observer(
  ({ isLoading, onCancel }) => {
    const { t } = useTranslation();
    const {
      userStore: { updateUserPassword },
    } = useStores();

    const FIELDS = {
      currentPassword: 'currentPassword',
      newPassword: 'newPassword',
      newPasswordAgain: 'newPasswordAgain',
    };

    const schema = Validator.object().shape({
      [FIELDS.currentPassword]: Validator.string().required(),
      [FIELDS.newPassword]: Validator.string()
        .hasSpecialChars()
        .hasLowerCase()
        .hasUpperCase()
        .hasNumber()
        .min(MINIMUM_PASSWORD_LENGTH)
        .required(),
      [FIELDS.newPasswordAgain]: Validator.string().oneOf(
        [Validator.ref(FIELDS.newPassword), null],
        t('validation.passwordConfirm'),
      ),
    });

    const validate = makeValidate(schema);
    const required = makeRequired(schema);

    const onSubmit = async ({ newPassword, currentPassword }: any) => {
      await updateUserPassword(currentPassword, newPassword);
      onCancel();
    };

    return (
      <Form
        validate={validate}
        onSubmit={onSubmit}
        render={({ handleSubmit, hasValidationErrors }) => {
          return (
            <form onSubmit={handleSubmit} noValidate>
              <Box mb={3}>
                <T variant="h2">{t('user.changePassword')}</T>
              </Box>
              <Box mb={3}>
                <Box maxWidth="50%">
                  {Object.values(FIELDS).map((field) => (
                    <Box mb={3}>
                      <TextField
                        type="password"
                        name={field}
                        key={field}
                        label={t(`user.${field}`)}
                        required={required[field]}
                      />
                    </Box>
                  ))}
                </Box>
              </Box>
              <Box display="flex" mb={3}>
                <Box mr={2}>
                  <Button
                    id="saveButton"
                    type="submit"
                    t="common.save"
                    color="secondary"
                    loading={isLoading}
                    disabled={hasValidationErrors}
                  />
                </Box>
                <Button
                  id="cancelButton"
                  variant="outlined"
                  t="common.cancel"
                  loading={isLoading}
                  onClick={onCancel}
                />
              </Box>
            </form>
          );
        }}
      />
    );
  },
);
