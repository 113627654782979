import {
  Box,
  Breadcrumbs,
  Typography as T,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Location } from 'history';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useParams } from 'react-router-dom';
import { PATHS } from '../../../constants/routes';
import { useStores } from '../../../stores/index';
import colors from '../../../styles/colors';
import { getPrettyDate } from '../../../utils/i18nUtils';
import { TabbedLayout } from './TabbedLayout';

interface SkyboxEventLayoutProps {}

const useStyles = makeStyles({
  header: {
    backgroundColor: colors.background.bg1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  headerContent: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: 'auto',
  },
  date: {
    fontSize: '16px',
    color: colors.text.body2,
  },
  tabContainer: {
    backgroundColor: colors.background.bg1,
  },
});

export const SkyboxEventLayout: React.FC<SkyboxEventLayoutProps> = observer(
  ({ children }) => {
    const {
      eventId,
      eventSeriesId,
    }: { eventId: string; eventSeriesId: string } = useParams();
    const {
      eventStore: { getEvent, event },
      authStore: { isOwner, isAdmin },
    } = useStores();
    const classes = useStyles();
    const location = useLocation() as Location<{ prevPath: string }>;
    const { t } = useTranslation();

    useEffect(() => {
      if (!event || String(event.id) !== String(eventId)) {
        getEvent(eventSeriesId, eventId, isOwner);
      }
    }, [getEvent, event, eventId, eventSeriesId, isOwner]);

    const replaceIds = (path: string) =>
      path
        .replace(':eventSeriesId', eventSeriesId)
        .replace(':eventId', eventId);

    const TABS = [
      {
        label: t('events.skyboxSettings'),
        path: replaceIds(PATHS.skyboxEvents.eventSkyboxes),
      },
    ];

    if (isAdmin) {
      TABS.push({
        label: t('events.eventSettings'),
        path: replaceIds(PATHS.skyboxEvents.eventSettings),
      });
    }

    const Crumbs = (
      <Box mb={2}>
        <Breadcrumbs>
          <Link
            to={location.state?.prevPath ?? PATHS.skyboxEvents.eventSeriesList}
          >
            <T>{t('events.events')}</T>
          </Link>
          <T>{event ? event.title : '-'}</T>
        </Breadcrumbs>
      </Box>
    );

    return (
      <TabbedLayout
        tabs={TABS}
        title={event?.title ?? '-'}
        headerElementsTop={Crumbs}
        headerElementsBottom={
          <Typography className={classes.date}>
            {getPrettyDate(event?.date, true, t('common.clockAbbr'))}
          </Typography>
        }
      >
        <Box p={3}>{children}</Box>
      </TabbedLayout>
    );
  },
);
