import { faPen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  BoxProps,
  Button,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { FC, useEffect, useRef, useState } from 'react';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import colors from '../../styles/colors';
import theme from '../../styles/theme';
interface ToggleableTextFieldProps extends BoxProps {
  name: string;
  placeholder?: string;
  validate?: any;
  onValidChange?: (name: string, valid: boolean) => void;
  onBlur?: () => void;
  hideEditIcon?: boolean;
  disabled?: boolean;
}

const useStyles = makeStyles({
  placeholder: {
    color: colors.text.body2,
  },
  editButton: {
    fontSize: 14,
    padding: 0,
    height: 45,
    cursor: 'text',
    textAlign: 'left',
    width: '100%',
    '& .MuiButton-label': {
      padding: 0,
      margin: 0,
      justifyContent: 'flex-start',
    },
  },
  textError: {
    fontSize: '11px',
    marginTop: theme.spacing(1),
  },
  textValue: {
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
    margin: 0,
  },
  textField: {
    width: '100%',
  },
});

export const ToggleableTextField: FC<ToggleableTextFieldProps> = ({
  hideEditIcon,
  name,
  onBlur,
  placeholder,
  validate,
  onValidChange,
  disabled,
  ...rest
}) => {
  const classes = useStyles();
  const [editing, setEditing] = useState(false);
  const [valid, setValid] = useState(true);
  const inputEl = useRef(null);
  const { t } = useTranslation();

  const onEditClick = () => {
    if (disabled) return;
    setEditing(true);
  };

  useEffect(() => {
    if (editing) {
      // @ts-ignore
      inputEl.current.focus();
    }
  }, [editing]);

  useEffect(() => {
    onValidChange && onValidChange(name, valid);
  }, [valid, onValidChange, name]);

  const onFieldBlur = () => {
    onBlur && onBlur();
    setEditing(false);
  };

  const renderText = (props: any) => {
    return (
      <Box display="flex" alignItems="center">
        <Button
          className={classes.editButton}
          variant="text"
          color="primary"
          onClick={onEditClick}
        >
          {!hideEditIcon && (
            <Box mr={3}>
              <FontAwesomeIcon icon={faPen} />
            </Box>
          )}
          <Box className={classes.textValue}>
            {!props.input.value && (
              <Typography className={classes.placeholder}>
                {placeholder}
              </Typography>
            )}
            {props.input.value && <Typography>{props.input.value}</Typography>}
            {!!props.meta.touched && !!props.meta.error && (
              <Typography className={classes.textError} color="secondary">
                {t(props.meta.error)}
              </Typography>
            )}
          </Box>
        </Button>
      </Box>
    );
  };

  const renderInput = (props: any) => {
    return (
      <Box>
        <TextField
          {...props.input}
          inputRef={inputEl}
          placeholder={placeholder}
          onBlur={onFieldBlur}
          error={!!props.meta.error}
          helperText={t(props.meta.error)}
          className={classes.textField}
          disabled={disabled}
        />
      </Box>
    );
  };
  return (
    <Box {...rest}>
      <Field name={name} validate={validate}>
        {(props) => {
          // setTimeout because "Cannot update a component while rendering a different component"
          setTimeout(() => {
            setValid(!!props.meta.valid);
          });
          return editing ? renderInput(props) : renderText(props);
        }}
      </Field>
    </Box>
  );
};
