import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { SkyboxTicketType } from '../../services/SkyboxService';
import { useStores } from '../../stores/index';
import { getPrettySum } from '../../utils/i18nUtils';
import { Quantity } from '../Quantity/Quantity';

interface TicketListItemProps {
  ticket: SkyboxTicketType;
}

const useStyles = makeStyles({
  heading: {
    margin: 0,
  },
});

export const TicketListItem: React.FC<TicketListItemProps> = observer(
  ({ ticket }) => {
    const classes = useStyles();
    const {
      cartStore: { reserveTicketsForSkybox },
    } = useStores();

    const onAddToCartClick = async (input: any) => {
      const numberOfTickets = parseInt(input.value, 10);
      const res = await reserveTicketsForSkybox({
        priceId: parseInt(input.name.replace('ticket', ''), 10),
        numberOfTickets,
      });
      return res;
    };

    return (
      <Box mb={2} maxWidth={600}>
        <Box
          display="flex"
          alignItems="center"
          pt={1}
          justifyContent="space-between"
          py={1}
        >
          <Box width={300}>
            <Typography className={classes.heading} variant="h4">
              {ticket.text}
            </Typography>
          </Box>
          <Box minWidth={100}>
            <Typography>{getPrettySum(ticket.price, true)}</Typography>
          </Box>
          <Box>
            <Quantity
              name={`ticket${ticket.priceId}`}
              onAddToCartClick={onAddToCartClick}
            />
          </Box>
        </Box>
      </Box>
    );
  },
);
