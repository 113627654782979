import { PRIMARY_COLOR } from '../constants/siteSettings';

const RED = '#D32029';
const GREEN = '#6ABE39';
// const BLACK = '#000000';
const WHITE = '#ffffff';
const GREY1 = '#141414';
// const GREY2 = '#1d1d1d';
// const GREY3 = '#262626';
// const GREY4 = '#303030';
// const GREY5 = '#434343';
// const GREY6 = '#5A5A5A';
const GREY7 = '#7D7D7D';
// const GREY8 = '#ACACAC';
const GREY9 = '#dbdbdb';
const GREY10 = '#F8F8F8';

const colors = {
  background: {
    bg1: WHITE,
    bg2: GREY10,
    bg3: GREY9,
  },
  text: {
    body1: GREY1,
    body2: GREY7,
    inverted: WHITE,
  },
  accent: {
    primary: PRIMARY_COLOR || RED,
  },
  stroke: GREY9,
  blue3: '#15395B',
  geekblue3: '#1C2755',
  red3: '#58181C',
  red5: '#A61d24',
  red7: '#E84749',
  gold7: '#E8B339',
  green: GREEN,
  red: RED,
};

export default colors;
