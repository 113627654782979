import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  makeStyles,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  KeyValueItem,
  KeyValueList,
} from '../../components/KeyValueList/KeyValueList';
import { AddOrderLayout } from '../../components/Layout';
import { OrderOverview } from '../../components/OrderOverview.tsx/OrderOverview';
import { PATHS } from '../../constants/routes';
import { useStores } from '../../stores/index';
import colors from '../../styles/colors';
import { getPrettySum } from '../../utils/i18nUtils';

interface OrderFormPaymentScreenProps {}
interface PaymentButtonProps {
  item: IPaymentProvider;
}

const useStyles = makeStyles({
  paymentButton: {
    backgroundColor: colors.background.bg2,
    borderRadius: 4,
    maxHeight: 100,
    height: 100,
    width: 100,
    border: 'none',
    cursor: 'pointer',
  },
  name: {
    fontSize: '12px',
  },
  paymentOption: {
    border: `1px solid ${colors.stroke}`,
  },
  radioButtonContainer: {
    width: '100%',
    '& > fieldset': {
      width: '100%',
    },
  },
});
const PaymentButton: React.FC<PaymentButtonProps> = ({ item }) => {
  const classes = useStyles();
  if (!item) return null;
  return (
    <Box>
      <form action={item.url} method="POST">
        {item.parameters?.map((param) => <input type="hidden" {...param} />)}
        <button type="submit" className={classes.paymentButton}>
          <img src={item.svg} alt={item.name} role="presentation" />
          <span className={classes.name}>{item.name}</span>
        </button>
      </form>
    </Box>
  );
};

type PaymentType = 'ePayment' | 'invoice';

export const OrderFormPaymentScreen: FC<OrderFormPaymentScreenProps> = observer(
  () => {
    const PAYMENT_TYPES = ['ePayment', 'invoice'] as PaymentType[];

    const { t } = useTranslation();
    const history = useHistory();
    const [paymentType, setPaymentType] = useState<
      PaymentType | 'paid' | 'not_paid'
    >();
    const [unconfirmedPaymentType, setUnconfirmedPaymentType] =
      useState<PaymentType>();
    const classes = useStyles();
    const [reference, setReference] = useState('');

    const {
      cartStore: {
        activeStep,
        setActiveStep,
        isPaymentInitialized,
        makePayment,
        checkoutResponse,
        cart,
        payByInvoice,
        isLoading,
        sum,
      },
      authStore: { isOwner },
      settingsStore: { myCompanyData },
    } = useStores();

    useEffect(() => {
      if (!myCompanyData) return;
      if (!isPaymentInitialized && paymentType === 'ePayment') {
        makePayment();
      }
    }, [isPaymentInitialized, makePayment, paymentType, myCompanyData]);

    useEffect(() => {
      if (!myCompanyData) return;
      if (!myCompanyData.invoicingEnabled) {
        setPaymentType('ePayment');
      }
    }, [myCompanyData]);

    useEffect(() => {
      if (!cart) {
        console.error('No cart found, redirecting to main screen');
        setActiveStep(0);
      }
    }, [cart, setActiveStep]);

    const onConfirm = async () => {
      try {
        if (isOwner) {
          setActiveStep(activeStep + 1);
        } else {
          setTimeout(() => {
            history.push(PATHS.orders.main);
          }, 3000);
        }
      } catch (error) {
        console.error(error);
      }
    };

    const onPaymentTypeChange = (e: any) => {
      const {
        target: { value },
      } = e;
      setUnconfirmedPaymentType(value);
    };

    const onReferenceChange = (e: any) => {
      const {
        target: { value },
      } = e;
      setReference(value);
    };

    const onPayWithInvoiceClick = async () => {
      await payByInvoice(reference);
      setActiveStep(activeStep + 1);
    };

    const onConfirmOrderClick = async () => {
      await makePayment();
      setActiveStep(activeStep + 1);
    };

    const invoicingFee = getPrettySum(myCompanyData?.invoicingFee || 0, true);
    const invoicingInformation = [
      { id: 'companyId' },
      { id: 'billingAddress' },
      { id: 'invoicingEmail' },
      { id: 'eInvoiceAddress' },
      { id: 'operator' },
      { id: 'brokerIdentifier' },
      { id: 'invoicingFee', render: () => invoicingFee },
    ].map(({ render, id }) => ({
      value:
        (render && render()) ||
        (myCompanyData && (myCompanyData as any)[id]) ||
        '-',
      label: t(`owner.${id}`),
    })) as KeyValueItem[];

    /**
     * ePayment buttons, etc.
     */
    const EPaymentSection = (
      <Box>
        {checkoutResponse?.groups?.map((group: IPaymentGroup) => {
          return (
            <Box mb={4} key={group.id}>
              <Typography variant="h3">{group.name}</Typography>
              <Box mt={3}>
                <Grid container spacing={2}>
                  {checkoutResponse.providers
                    .filter(
                      (provider: IPaymentProvider) =>
                        provider.group === group.id,
                    )
                    .map((provider: IPaymentProvider) => (
                      <Grid item>
                        <PaymentButton key={provider.id} item={provider} />
                      </Grid>
                    ))}
                </Grid>
              </Box>
            </Box>
          );
        })}
      </Box>
    );

    /**
     * Invoicing related fields, etc.
     */
    const InvoicingSection = (
      <Box>
        <Box bgcolor={colors.background.bg2} p={3} mb={3}>
          <Typography variant="h3">
            {t('orders.add.payment.invoicingInformation')}
          </Typography>
          <KeyValueList items={invoicingInformation} />
        </Box>
        <TextField
          name="reference"
          onChange={onReferenceChange}
          label={t('orders.add.payment.reference')}
          fullWidth
          multiline
          rows={5}
        />

        <Box my={2}>
          <Button
            disabled={!myCompanyData?.invoicingEmail || isLoading}
            onClick={onPayWithInvoiceClick}
          >
            {t('orders.add.payment.payWithInvoice')}
          </Button>
        </Box>
        {!myCompanyData?.invoicingEmail && (
          <FormHelperText error>
            {t('orders.add.payment.paymentInformationMissing')}
          </FormHelperText>
        )}
      </Box>
    );

    const onPaymentTypeConfirm = () => {
      setPaymentType(unconfirmedPaymentType);
      setUnconfirmedPaymentType(undefined);
    };

    /**
     * Payment method selector
     */
    const RadioButtons = (
      <Box mb={1} className={classes.radioButtonContainer}>
        <FormControl component="fieldset">
          <Box mb={3}>
            <Typography>
              {t('orders.add.payment.choosePaymentTypeDescription')}
            </Typography>
          </Box>
          <RadioGroup
            onChange={onPaymentTypeChange}
            aria-label="paymentType"
            value={paymentType}
            defaultValue={[PAYMENT_TYPES[0]!]}
            name="paymentType"
            row={false}
            style={{ display: 'block' }}
          >
            {PAYMENT_TYPES.map((type) => (
              <Box className={classes.paymentOption} p={3} mb={2}>
                <FormControlLabel
                  value={type}
                  control={<Radio />}
                  label={
                    <Box width="100%">
                      <Typography variant="h4">
                        {t(`orders.add.payment.paymentType.${type}`)}
                      </Typography>
                      <Typography>
                        {t(
                          `orders.add.payment.paymentType.description.${type}`,
                        )}
                      </Typography>

                      {type === 'invoice' && !!myCompanyData?.invoicingFee && (
                        <Box mt={2}>
                          {t('orders.add.payment.invoicingFee', {
                            invoicingFee,
                          })}
                        </Box>
                      )}
                    </Box>
                  }
                />
              </Box>
            ))}
          </RadioGroup>
        </FormControl>
      </Box>
    );

    const ConfirmOrderSection = (
      <Box>
        <Typography>{t('orders.add.payment.confirmPayment')}</Typography>
        <Box mb={2} />
        <Typography>{t('orders.add.payment.ticketInformation')}</Typography>
        <Box mb={3} />
        <Button onClick={onConfirmOrderClick}>
          {t('orders.add.payment.confirmOrderButton')}
        </Button>
      </Box>
    );

    const PaymentTypeSelectSection = (
      <Box>
        <Typography variant="h2">
          {t('orders.add.payment.choosePaymentType')}
        </Typography>
        {RadioButtons}
        <Button
          disabled={!unconfirmedPaymentType}
          onClick={onPaymentTypeConfirm}
        >
          {t('common.confirm')}
        </Button>
      </Box>
    );

    const titleText = !!sum
      ? 'orders.add.payment.title'
      : 'orders.add.sidebar.payment';

    return (
      <AddOrderLayout
        title={t(titleText)}
        onConfirm={onConfirm}
        hideCart
        hideConfirm
      >
        <Box mt={3} mb={3}>
          <Grid container spacing={3}>
            {isLoading && (
              <Box display="flex" justifyContent="center" width="100%">
                <CircularProgress />
              </Box>
            )}

            <Grid item xs={6}>
              {!!sum && (
                <>
                  {myCompanyData?.invoicingEnabled &&
                    !paymentType &&
                    PaymentTypeSelectSection}
                  {paymentType === 'ePayment' && EPaymentSection}
                  {isOwner && paymentType === 'invoice' && InvoicingSection}
                </>
              )}
              {!sum && ConfirmOrderSection}
            </Grid>
            <Grid item xs={6}>
              <OrderOverview
                displayVat
                invoicingFee={
                  paymentType === 'invoice'
                    ? myCompanyData?.invoicingFee
                    : undefined
                }
              />
            </Grid>
          </Grid>
        </Box>
      </AddOrderLayout>
    );
  },
);
