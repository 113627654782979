import { Box, Typography } from '@material-ui/core';
import { TextField } from 'mui-rff';
import { Form, FormSpy } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { theme } from '../../../../../styles';
import { SelfReservationContactDetailsParams } from './SelfReservationContactDetailsParams';
import { SelfReservationContactDetailsValidate } from './SelfReservationContactDetailsSchema';

export const SelfReservationContactDetailsForm = ({
  setContactDetails,
  contactDetails,
}: {
  setContactDetails: (params: SelfReservationContactDetailsParams) => void;
  contactDetails: SelfReservationContactDetailsParams;
}) => {
  const { t } = useTranslation();

  return (
    <Form
      initialValues={contactDetails}
      onSubmit={() => {}}
      validate={SelfReservationContactDetailsValidate}
      render={() => {
        const onFormChange = ({
          values,
        }: {
          values: SelfReservationContactDetailsParams;
        }) => {
          setContactDetails(values);
        };

        return (
          <Box>
            <Box maxWidth={500} mt={3}>
              <Box mb={2}>
                <Typography variant="h3">
                  {t('common.skyboxContactDetails.prefillTitle')}
                </Typography>
              </Box>
              <Box
                style={{
                  display: 'grid',
                  gap: `${theme.spacing(2)}px`,
                  gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
                }}
              >
                <TextField
                  name={'contactName'}
                  label={t(`common.skyboxContactDetails.contactName`)}
                />
                <TextField
                  name={'contactPhone'}
                  label={t(`common.skyboxContactDetails.contactPhone`)}
                />
                <TextField
                  name={'contactEmail'}
                  label={t(`common.skyboxContactDetails.contactEmail`)}
                />
              </Box>
            </Box>
            <FormSpy onChange={onFormChange} subscription={{ values: true }} />
          </Box>
        );
      }}
    />
  );
};
