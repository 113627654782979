import { Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { IconTextButton } from '../../components/IconTextButton/IconTextButton';
import { Layout } from '../../components/Layout';
import { CommonModal } from '../../components/Modals';
import { useStore } from '../../stores';
import { OwnerForm } from './OwnerForm';

export const EditOwnerScreen: FC = observer(() => {
  const {
    owner,
    ownerUsers,
    isLoading,
    getOwner,
    updateOwner,
    deleteOwner,
    createOwnerUser,
    deleteOwnerUser,
  } = useStore('ownerStore');
  const { t } = useTranslation();
  const history = useHistory();
  const { id: idParam }: { id: string } = useParams();
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    getOwner(idParam);
  }, [getOwner, idParam]);

  const handleUpdateClick = async (
    formValues: any,
    _form: any,
    cb: Function,
  ) => {
    const data = {
      ...formValues,
    };
    await updateOwner(data, cb);
    history.push('/owners');
  };

  const handleCancelClick = () => {
    history.push('/owners');
  };

  const handleDeleteClick = () => {
    setModalOpen(true);
  };

  const onDeleteConfirm = async () => {
    await deleteOwner(idParam);
    history.push('/owners');
  };

  const onDeleteCancel = () => {
    setModalOpen(false);
  };

  return (
    <Layout
      headerText={t('owner.editOwner')}
      headerElementsRight={
        <IconTextButton
          type="delete"
          label="owner.deleteOwner"
          onClick={handleDeleteClick}
        />
      }
    >
      <OwnerForm
        onSubmit={handleUpdateClick}
        onCancel={handleCancelClick}
        onAddOwnerUser={createOwnerUser}
        onDeleteOwnerUser={deleteOwnerUser}
        initialValues={owner}
        ownerUsers={ownerUsers}
        isLoading={isLoading}
      />
      <CommonModal
        onConfirm={onDeleteConfirm}
        onCancel={onDeleteCancel}
        open={modalOpen}
        title={t('owner.deleteModal.title')}
        confirmText={t('owner.deleteModal.confirm')}
        type="prompt"
      >
        <Typography>
          {t('owner.deleteModal.body', { name: owner?.name })}
        </Typography>
      </CommonModal>
    </Layout>
  );
});
