import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import Helmet from 'react-helmet';
import { GTM_ID } from '../../constants';
import { useStores } from '../../stores/index';

interface SeoProps {}

export const Seo: FC<SeoProps> = observer(() => {
  const {
    customerStore: { currentCustomer },
  } = useStores();
  const favicon = currentCustomer?.faviconAsset?.source;
  const title = `${currentCustomer?.name || ''} admin`;
  return (
    <Helmet title={title}>
      {favicon && (
        <link
          rel="shortcut icon"
          href={favicon || 'favicon.ico'}
          type="image/x-icon"
        />
      )}
      <meta name="description" content={title} data-react-helmet="true" />
      {GTM_ID && (
        <script>
          {`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${GTM_ID}');
          `}
        </script>
      )}
    </Helmet>
  );
});
