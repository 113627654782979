import { Box, BoxProps, IconButton, Menu, MenuItem } from '@material-ui/core';
import { MoreHoriz } from '@material-ui/icons';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

export type DropdownItem = {
  label: string;
  onClick: any;
};

interface DropdownMenuProps extends BoxProps {
  items: DropdownItem[];
}

export const DropdownMenu: React.FC<DropdownMenuProps> = ({
  items,
  ...rest
}) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState();
  const { t } = useTranslation();

  const onToggleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
    setOpen(!open);
  };

  const onMenuClose = () => {
    setOpen(false);
  };
  return (
    <Box position="relative" {...rest}>
      <IconButton onClick={onToggleClick}>
        <MoreHoriz />
      </IconButton>

      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={open}
        onClose={onMenuClose}
      >
        {items.map((item: any) => (
          <MenuItem onClick={item.onClick} key={item.label}>
            {t(item.label)}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};
