import { Box, makeStyles } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { Select } from 'mui-rff';
import { FC, useEffect, useMemo, useState } from 'react';
import { Form, FormSpy } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useStores } from '../../../stores/index';
import { CommonModal, CommonModalProps } from '../CommonModal';

export interface SuperOwnerSettingsModalProps
  extends Omit<CommonModalProps, 'children' | 'type'> {
  open: boolean;
  onClose: any;
}

const useStyles = makeStyles({
  modalContent: {
    overflowY: 'auto',
    maxHeight: '60vh',
    minHeight: '60vh',
    paddingRight: 3,
  },
  footer: {},
  modal: {},
});

const FIELDS = {
  role: 'role',
  owner: 'owner',
};

export const SuperOwnerSettingsModal: FC<SuperOwnerSettingsModalProps> =
  observer(({ open, onClose }) => {
    const classes = useStyles();
    const [formValues, setFormValues] = useState();
    const { t } = useTranslation();
    const {
      ownerStore: { ownerListPage, getOwnerList },
      userStore: { updateSuperOwnerSettings, superOwnerRole },
      settingsStore: { myCompanyData },
    } = useStores();

    const initialValues = useMemo(() => {
      return {
        owner: myCompanyData?.id,
        role: superOwnerRole,
      };
    }, [myCompanyData, superOwnerRole]);

    useEffect(() => {
      if (!ownerListPage) getOwnerList();
      /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [ownerListPage]);
    const onSubmit = () => {};

    const ownerOptions = useMemo(() => {
      if (!ownerListPage) return [];
      return ownerListPage.map((owner) => {
        return {
          value: owner.id,
          label: owner.name,
        };
      });
    }, [ownerListPage]);

    const roleOptions = ['SkyboxAdmin', 'SkyboxOwner'].map((role) => {
      return {
        value: role,
        label: t(`user.superOwnerModal.role.${role}`),
      };
    });

    const onConfirmClick = async () => {
      if (!formValues) return;
      await updateSuperOwnerSettings(
        formValues![FIELDS.owner],
        formValues![FIELDS.role],
      );
      onClose();
    };

    const onCancelClick = () => {
      onClose();
    };

    const onFormChange = ({ values }: any) => {
      setFormValues(values);
    };

    return (
      <CommonModal
        maxWidth={640}
        title={t('user.superOwnerModal.title')}
        type="normal"
        open={open}
        className={classes.modal}
        onConfirm={onConfirmClick}
        onCancel={onCancelClick}
      >
        <Box>
          <Form
            initialValues={initialValues}
            onSubmit={onSubmit}
            render={({ values }) => {
              return (
                <form>
                  <Select
                    data={roleOptions}
                    name={FIELDS.role}
                    label={t('user.superOwnerModal.roleLabel')}
                  />
                  {values[FIELDS.role] === 'SkyboxOwner' && (
                    <>
                      <Box mt={3} />
                      <Select
                        data={ownerOptions}
                        name={FIELDS.owner}
                        label={t('user.superOwnerModal.ownerLabel')}
                      />
                    </>
                  )}
                  <FormSpy
                    subscription={{ values: true }}
                    onChange={onFormChange}
                  />
                </form>
              );
            }}
          />
        </Box>
      </CommonModal>
    );
  });
