import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SkyboxEventListLayout } from '../../components/Layout/hocs/SkyboxEventListLayout';
import { SkyboxEventList } from '../../components/SkyboxEventList/SkyboxEventList';
import { useStore } from '../../stores';
import { IEventSeries } from '../../types/Event';

export interface EventListScreenProps {}

export const EventListScreen: React.FC<EventListScreenProps> = observer(() => {
  const { getEventSeries, eventSeries } = useStore('eventStore');
  const { id } = useParams() as { id: string };
  const [selectedSeries, setSelectedSeries] = useState<IEventSeries>();
  const PAGE_SIZE = 10;

  useEffect(() => {
    if (eventSeries && !selectedSeries) {
      setSelectedSeries(eventSeries.find((es) => String(es.id) === id));
    }
  }, [getEventSeries, eventSeries, setSelectedSeries, selectedSeries, id]);

  return (
    <SkyboxEventListLayout>
      <SkyboxEventList pageSize={PAGE_SIZE} eventSeriesId={Number(id)} />
    </SkyboxEventListLayout>
  );
});
