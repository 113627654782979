import { Box } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import moment from 'moment-timezone';
import queryString from 'query-string';
import React, { useCallback, useEffect, useState } from 'react';
import {
  Calendar,
  DateLocalizer,
  momentLocalizer,
  ToolbarProps,
} from 'react-big-calendar';
import { useLocation } from 'react-router-dom';
import { useStores } from '../../stores/index';
import './reservationCalendar.scss';
import { ReservationCalendarEvent } from './ReservationCalendarEvent';
import { ReservationCalendarInternalEventModal } from './ReservationCalendarInternalEventModal';
import { ReservationCalendarReservationModal } from './ReservationCalendarReservationModal';
import { ReservationCalendarToolbar } from './ReservationCalendarToolbar';
import { ReservationsList } from './ReservationsList';

export interface ReservationCalendarProps {}
const localizer: DateLocalizer = momentLocalizer(moment);
// Overwrite startAndEndAreDateOnly since allDay events row is hidden from the calendar and
// would render events there if it thinks they are only dates when their start and end
// dates have times of 00:00:00.
localizer.startAndEndAreDateOnly = (_dateA: Date, _dateB: Date) => false;

export interface IReservationSlot {
  start: Date;
  end: Date;
  resourceId: 'string' | null;
}

export const ReservationCalendar: React.FC<ReservationCalendarProps> = observer(
  () => {
    const location = useLocation();
    const [initialized, setInitialized] = useState(false);
    const [currentDate, setCurrentDate] = useState<Date | undefined>();
    const [activeView, setActiveView] = useState<number>(0);
    const { date: paramDate } = queryString.parse(location.search);
    const {
      authStore: { isOwner, isAdmin, isJanitor },
      calendarStore: {
        getInternalEvents,
        internalEvents,
        getCalendarEvents,
        calendarEvents,
        calendarReservations,
        getCalendarReservations,
        getSkyboxUsage,
      },
    } = useStores();

    const [selectedSlot, setSelectedSlot] = useState<IReservationSlot>();
    const [selectedEvent, setSelectedEvent] =
      useState<IBigCalendarInternalEvent>();
    const [selectedReservation, setSelectedReservation] =
      useState<IBigCalendarReservation>();

    useEffect(() => {
      if (!currentDate) {
        if (paramDate) {
          setCurrentDate(new Date(paramDate as string));
        } else {
          setCurrentDate(new Date());
        }
      }
    }, [currentDate, setCurrentDate, paramDate]);

    const onTabChange = (activeTab: number) => {
      setActiveView(activeTab);
    };

    const CUSTOM_COMPONENTS = {
      toolbar: (props: ToolbarProps) => (
        <ReservationCalendarToolbar
          onTabChange={onTabChange}
          onAddNewClick={onAddNewClick}
          activeTab={activeView}
          {...props}
        />
      ),
      event: ReservationCalendarEvent,
    };

    /**
     * Fetches:
     * - internal events
     * - Reservations
     * - Eventim events
     */
    const getEvents = useCallback(
      async (date?: Date | string) => {
        let d = date || currentDate;
        const timespan = {
          startDate: moment(d).startOf('week').toISOString(),
          endDate: moment(d).endOf('week').toISOString(),
        };
        await getInternalEvents(timespan);
        await getCalendarEvents(timespan);
        await getCalendarReservations(timespan);
        if (isAdmin || isJanitor) {
          await getSkyboxUsage(timespan);
        }
      },
      [
        getInternalEvents,
        currentDate,
        getCalendarEvents,
        getCalendarReservations,
        getSkyboxUsage,
        isAdmin,
        isJanitor,
      ],
    );

    useEffect(() => {
      if (!initialized && currentDate) {
        getEvents();
        setInitialized(true);
      }
    }, [
      getEvents,
      initialized,
      setInitialized,
      getInternalEvents,
      currentDate,
    ]);

    const onAddNewClick = () => {
      setSelectedSlot({
        start: new Date(),
        end: new Date(),
        resourceId: null,
      });
    };
    const onSelecting = (_e: any) => {
      return true;
    };

    const onSelectSlot = (slot: IReservationSlot) => {
      setSelectedSlot(slot);
    };

    const handleModalClose = (refreshData?: boolean) => {
      setSelectedSlot(undefined);
      setSelectedEvent(undefined);
      setSelectedReservation(undefined);
      if (refreshData) {
        getEvents();
      }
    };

    const onSelectEvent = (
      event: IBigCalendarInternalEvent | IBigCalendarReservation,
    ) => {
      if (isJanitor) return;
      if (event.type === 'internal_event') setSelectedEvent(event);
      else if (event.type === 'order') {
        setSelectedReservation(event);
      }
    };

    const onNavigate = (date: Date) => {
      setCurrentDate(date);
      getEvents(date);
    };

    const events = [
      ...(calendarEvents || []),
      ...(internalEvents || []),
      ...(calendarReservations || []),
    ] as any;

    return (
      <>
        <Box
          px={3}
          py={1}
          height="100%"
          width="100%"
          className={activeView === 1 ? 'only-reservations' : ''}
        >
          <Calendar
            localizer={localizer}
            events={events || []}
            defaultView="week"
            onSelecting={onSelecting}
            components={CUSTOM_COMPONENTS as any}
            onSelectSlot={onSelectSlot as any}
            onSelectEvent={onSelectEvent}
            onNavigate={onNavigate}
            selectable
            date={currentDate}
            startAccessor="startDate"
            endAccessor="endDate"
            titleAccessor="name"
            showMultiDayTimes
          />
        </Box>
        {activeView === 1 && (
          <ReservationsList date={currentDate} onSelectEvent={onSelectEvent} />
        )}

        {((selectedSlot && isOwner) || selectedReservation) && (
          <ReservationCalendarReservationModal
            handleClose={handleModalClose}
            selectedSlot={selectedSlot}
            selectedReservation={selectedReservation}
          />
        )}

        {((selectedSlot && isAdmin) || selectedEvent) && (
          <ReservationCalendarInternalEventModal
            handleClose={handleModalClose}
            selectedSlot={selectedSlot}
            selectedEvent={selectedEvent}
          />
        )}
      </>
    );
  },
);
