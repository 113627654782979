/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid, MenuItem, Typography as T } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { Select } from 'mui-rff';
import React, { FC, useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { PATHS } from '../../constants/routes';
import { useStores } from '../../stores/index';
import { getSkyboxStatusSelectOptions } from '../../utils/formUtils';
import { ColorBox, ColorBoxProps } from '../ColorBox/ColorBox';
import { CurrencyField } from '../CurrencyField/CurrencyField';
import { FormHeader } from '../FormHeader/FormHeader';
import { CellRenderers } from '../MaterialTable/CellRenderers';
import MaterialTable from '../MaterialTable/MaterialTable';
import { CommonModal } from '../Modals/CommonModal';
import { SkyboxMap } from '../SkyboxMap';

interface SkyboxReservationSettingsProps {
  data: ISkybox[];
  settings?: any;
  onSave: (formValues: any) => any;
  formTitle: string;
  type: 'eventSeries' | 'event';
}

export const SkyboxReservationSettings: FC<SkyboxReservationSettingsProps> =
  observer(({ data, settings, onSave, formTitle, type }) => {
    const { t } = useTranslation();
    const [selectedBoxIds, setSelectedBoxIds] = useState<string[]>([]);
    const [disabledBoxIds, setDisabledBoxIds] = useState<string[]>([]);
    const [confirmModalOpen, setConfirmModalOpen] = useState(0);
    const history = useHistory();
    const params = useParams() as any;
    const {
      eventStore: { event },
    } = useStores();

    const getSkyboxData = () => {
      const availableSkyboxIds = settings
        ? settings.skyboxes.map((box: ISkybox) => box.id)
        : [];
      return data.map((skybox: ISkybox) => {
        const returnVal = { ...{}, ...skybox } as any;
        if (availableSkyboxIds.includes(skybox.id)) {
          returnVal.allowSkyboxSales = true;
        }
        return returnVal;
      });
    };

    useEffect(() => {
      if (data) {
        const rentedBoxes = data
          .filter((skybox: ISkybox) => {
            return skybox.rented;
          })
          .map((box) =>
            String(data.findIndex((origBox) => box.id === origBox.id)),
          );
        setDisabledBoxIds(rentedBoxes);
      }
    }, [data]);

    const initialValues = React.useMemo(
      () => ({
        skyboxIds:
          settings?.skyboxes?.map((skybox: ISkybox) => skybox.id) || [],
        skyboxListPrice: event?.customerEventInformation?.skyboxListPrice,
      }),
      [settings, event],
    );

    const BOXES_COLUMNS = React.useMemo(
      () => [
        {
          Header: t('common.id'),
          accessor: 'mapId',
          width: 120,
          Cell: CellRenderers.textFn(
            ({ row: { original } }: any) => original.mapId,
          ),
        },
        {
          Header: () => (
            <Box textAlign="center" width="100%">
              {t('common.status')}
            </Box>
          ),
          accessor: 'allowSkyboxSales',
          width: 160,
          Cell: ({ row: { original } }: any) => {
            let color = 'red' as ColorBoxProps['color'];
            let text = 'unbookable';
            if (original.vacantForEvent && !original.rented) {
              color = 'green';
              text = 'bookable';
            }
            if (original.rented) {
              text = 'rented';
            }
            return (
              <ColorBox textAlign="center" color={color} whiteText>
                {t(`common.skyboxOrderStatus.${text}`)}
              </ColorBox>
            );
          },
        },
        {
          Header: t('common.owner'),
          accessor: 'company.name',
        },
        {
          Header: t('events.settings.name'),
          accessor: 'name',
        },
      ],
      [t, settings, disabledBoxIds],
    );

    const onRowSelect = (selectedIds: string[], form: any) => {
      setSelectedBoxIds(selectedIds);
      form.change('newStatus', '');
      form.change('selectedSkyboxIds', selectedIds);
    };

    const renderStatusSelect = () => {
      return (
        <Box display="flex">
          {!!selectedBoxIds.length && (
            <Box
              display="flex"
              mr={2}
              alignItems="center"
              justifyContent="flex-end"
              minWidth={100}
            >
              {t('skybox.selectedSkyboxCount', {
                count: selectedBoxIds.length,
              })}
            </Box>
          )}
          <Select
            variant="outlined"
            name="newStatus"
            displayEmpty
            disabled={!selectedBoxIds.length}
          >
            {getSkyboxStatusSelectOptions(t, true).map((item) => {
              return (
                <MenuItem value={String(item.value)} key={item.label}>
                  {item.label}
                </MenuItem>
              );
            })}
          </Select>
        </Box>
      );
    };

    const onCancel = () => {
      const id = type === 'eventSeries' ? params.id : params.eventSeriesId;
      history.push(PATHS.skyboxEvents.eventList.replace(':id', id));
    };

    const onModalCancel = () => {
      setConfirmModalOpen(0);
    };

    const onSubmit = async (values: any, form: any) => {
      let allowSales = undefined;
      if (values.newStatus === 'true') allowSales = true;
      if (values.newStatus === 'false') allowSales = false;

      const saveData = {
        allowSkyboxSales: allowSales,
        skyboxListPrice: values.skyboxListPrice,
        skyboxIds: selectedBoxIds,
        allowDeleteExisting: type === 'eventSeries' ? !!confirmModalOpen : true,
      };

      const resp = await onSave(saveData);

      if (resp?.results === 'warning') {
        setConfirmModalOpen(resp?.deleteCount || 1);
      }

      if (resp?.results === 'ok') {
        form.change('newStatus', '');
        setConfirmModalOpen(0);
        setSelectedBoxIds([]);
      }
    };

    return (
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        render={({ form, valid, dirty, handleSubmit }) => {
          return (
            <form onSubmit={handleSubmit} noValidate>
              <FormHeader
                title={formTitle}
                onCancel={onCancel}
                submitDisabled={!valid || !dirty}
              />
              <Box p={3}>
                {type === 'event' && (
                  <Box>
                    <T variant="h2">{t('events.release')}</T>
                    <Grid container spacing={3}>
                      <Grid item xs={4}>
                        <CurrencyField
                          name="skyboxListPrice"
                          label={t('events.settings.skyboxListPrice')}
                          helperText={t(
                            'events.settings.skyboxListPriceHelper',
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                )}

                <Field
                  type="hidden"
                  name="selectedSkyboxIds"
                  component="input"
                />

                <Box
                  mt={3}
                  mb={1}
                  display="flex"
                  justifyContent="space-between"
                  width="100%"
                >
                  <T variant="h2">
                    {t('events.settings.bookableBoxesHeading')}
                  </T>
                  <Box display="flex" alignItems="center">
                    {renderStatusSelect()}
                    <SkyboxMap />
                  </Box>
                </Box>
                <MaterialTable
                  columns={BOXES_COLUMNS}
                  data={getSkyboxData()}
                  onRowSelect={(ids) => onRowSelect(ids, form)}
                  selectedIds={selectedBoxIds}
                  disabledIds={disabledBoxIds}
                />
              </Box>

              <CommonModal
                title={t('common.warning')}
                open={!!confirmModalOpen}
                type="prompt"
                confirmText={t('events.settings.confirmModal.confirm')}
                onConfirm={() => form.submit()}
                onCancel={onModalCancel}
              >
                <T>{t('events.settings.confirmModal.body')}</T>
              </CommonModal>
            </form>
          );
        }}
      />
    );
  });
