import React from 'react';
import AuthStore from './AuthStore';
import BulletinStore from './BulletinStore';
import CalendarStore from './CalendarStore';
import CartStore from './CartStore';
import CommonStore from './CommonStore';
import CustomerStore from './CustomerStore';
import EventStore from './EventStore';
import OrderStore from './OrderStore';
import OwnerStore from './OwnerStore';
import ReportStore from './ReportStore';
import SettingsStore from './SettingsStore';
import SkyboxStore from './SkyboxStore';
import ToastStore from './ToastStore';
import UserStore from './UserStore';

export const stores = Object.freeze({
  commonStore: CommonStore,
  toastStore: ToastStore,
  authStore: AuthStore,
  skyboxStore: SkyboxStore,
  ownerStore: OwnerStore,
  eventStore: EventStore,
  userStore: UserStore,
  orderStore: OrderStore,
  reportStore: ReportStore,
  settingsStore: SettingsStore,
  calendarStore: CalendarStore,
  customerStore: CustomerStore,
  cartStore: CartStore,
  bulletinStore: BulletinStore,
});

export const storesContext = React.createContext(stores);
export const StoresProvider = storesContext.Provider;
