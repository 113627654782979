import moment from 'moment-timezone';
import { v4 } from 'uuid';
import { IEvent, IInternalEvent } from '../types/Event';
import { DEFAULT_TIME_ZONE } from './i18nUtils';

export const calendarResponseToCalendarEvents = (
  data: { date: string; duration?: null | number; title: string }[],
): IBigCalendarExternalEvent[] => {
  return data.map(({ title, date, duration }) => {
    const start = new Date(date);
    let end = new Date(date);

    if (duration) {
      end = new Date(end.getTime() + duration * 60 * 1000);
    } else {
      const endOfDay = moment(end, DEFAULT_TIME_ZONE);
      endOfDay.set('hours', 23);
      endOfDay.set('minutes', 59);
      endOfDay.set('seconds', 59);
      end = endOfDay.toDate();
    }

    return {
      id: v4(),
      name: title,
      startDate: start,
      endDate: end,
      allDay: false,
      // Placeholder data, not actually used but required by the typing
      type: 'external_event',
      createdAt: date,
      updatedAt: date,
      customerId: v4(),
    };
  });
};

export const transformToCalendarEvent = (
  data: any[],
  type: CalendarEventType,
): any[] => {
  return data.map(
    ({
      startDate,
      endDate,
      date,
      name,
      title,
      skybox,
      customerEventInformation,
      eventseries,
      ...rest
    }: ICalendarReservation & IInternalEvent & IEvent) => {
      let newStart = new Date(startDate);
      let newEnd = new Date(endDate);
      let newName = name;

      if (type === 'order') {
        newName = skybox.mapId;
      }

      return {
        startDate: newStart,
        endDate: newEnd,
        name: newName,
        allDay: false,
        type,
        ...rest,
      };
    },
  );
};
