import { observer } from 'mobx-react-lite';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Layout } from '../../components/Layout';
import { PATHS } from '../../constants/routes';
import { useStores } from '../../stores/index';
import { OwnerForm } from '../Owners/OwnerForm';
export interface SettingsScreenProps {}

export const SettingsScreen: FC<SettingsScreenProps> = observer(() => {
  const { t } = useTranslation();
  const {
    settingsStore: {
      getMyCompanyData,
      updateMyCompanyData,
      myCompanyData,
      isLoading,
    },
  } = useStores();
  const history = useHistory();

  useEffect(() => {
    if (!myCompanyData) getMyCompanyData();
  }, [getMyCompanyData, myCompanyData]);

  const onSubmit = (values: any) => {
    updateMyCompanyData(values);
  };

  const onCancel = () => {
    history.push(PATHS.skyboxEvents.allEvents);
  };

  return (
    <Layout headerText={t('common.settings')}>
      <OwnerForm
        onSubmit={onSubmit}
        onCancel={onCancel}
        initialValues={myCompanyData as any}
        isLoading={isLoading}
      />
    </Layout>
  );
});
