import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Layout } from '../Layout';

interface UsersLayoutProps {
  headerElementsTop?: any;
  headerElementsRight?: any;
}

export const UsersLayout: FC<UsersLayoutProps> = observer(
  ({ children, ...rest }) => {
    const { t } = useTranslation();

    return (
      <Layout headerText={t('sidemenu.users')} {...rest}>
        {children}
      </Layout>
    );
  },
);
