import { Link } from '@material-ui/core';
import * as Sentry from '@sentry/react';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import { PATHS } from '../../../constants';
import { useStores } from '../../../stores/index';

export const ListTableClickableCell: React.FC<{
  item: { purchase: { livexOrderNumber: string } };
}> = observer(({ item, children }) => {
  const {
    authStore: { isOwner },
  } = useStores();
  const history = useHistory();

  const onClick = async () => {
    const livexOrderNumber = item.purchase?.livexOrderNumber;
    if (!livexOrderNumber) {
      Sentry.captureMessage(
        `livexOrderNumber unavailable. purchase=${item.purchase}`,
      );
      return;
    }
    history.push(
      (isOwner ? PATHS.orders.owners : PATHS.orders.skybox).details.replace(
        ':livexOrderNumber',
        livexOrderNumber,
      ),
    );
  };

  return <Link onClick={onClick}>{children}</Link>;
});
