import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';
import OwnerService from '../services/OwnerService';
import UserService from '../services/UserService';
import { findById } from '../utils';
import ToastStore from './ToastStore';

export interface IOwnerStore {
  state: StoreState;
  ownerListPage?: IOwner[];
  owner?: IOwner;
  ownerUsers?: IAdminUser[];
  isLoading: boolean;
  getOwnerList: () => void;
  getOwner: (id: string) => void;
  createOwner: (owner: IOwner, cb: Function) => void;
  updateOwner: (owner: IOwner, cb: Function) => void;
  deleteOwner: (id: string) => void;
  createOwnerUser: (user: IAdminUser) => void;
  deleteOwnerUser: (id: string) => void;
}

class OwnerModel implements IOwnerStore {
  state: StoreState = 'Idle';
  ownerListPage: IOwnerStore['ownerListPage'] = undefined;
  owner: IOwnerStore['owner'] = undefined;
  ownerUsers: IOwnerStore['ownerUsers'] = undefined;

  constructor() {
    makeObservable(this, {
      state: observable,
      ownerListPage: observable,
      owner: observable,
      ownerUsers: observable,
      isLoading: computed,
      getOwnerList: action,
      getOwner: action,
      createOwner: action,
      updateOwner: action,
      deleteOwner: action,
      createOwnerUser: action,
      deleteOwnerUser: action,
    });
  }

  get isLoading() {
    return this.state === 'Loading';
  }

  /**
   * Returns the owner list
   */
  getOwnerList = async () => {
    this.state = 'Loading';
    try {
      const { data: ownerList }: { data: IOwner[] } =
        await OwnerService.getOwnerList();
      runInAction(() => {
        this.ownerListPage = ownerList;
        this.state = 'Success';
      });
    } catch (error) {
      runInAction(() => {
        this.state = 'Error';
      });
      throw error;
    }
  };

  /**
   * Returns a owner by id
   */
  getOwner = async (id: string) => {
    this.state = 'Loading';
    try {
      const owner = await OwnerService.getOwner(id);
      runInAction(() => {
        this.owner = owner;
        this.ownerUsers = owner.users;
        this.state = 'Success';
      });
    } catch (error) {
      runInAction(() => {
        this.state = 'Error';
      });
      throw error;
    }
  };

  handleCreatedUsers = async (ownerId: string) => {
    if (this.owner?.id === ownerId && this.ownerUsers) {
      for (const user of this.ownerUsers) {
        if (!findById(this.owner.users || [], user.id)) {
          const { data: newUser }: { data: IAdminUser } =
            await UserService.createUser(user);
          await OwnerService.addUserToSkyboxCompany(ownerId, newUser.id);
        }
      }
    }
  };

  deactivateOwnerUsers = async (ownerId: string) => {
    if (this.owner?.id === ownerId && this.owner.users) {
      for (const user of this.owner.users) {
        if (!findById(this.ownerUsers || [], user.id)) {
          await UserService.changeUserActiveStatus(user.id, { active: false });
        }
      }
    }
  };

  /**
   * Create new owner
   */
  createOwner = async (owner: IOwner, cb: Function) => {
    this.state = 'Loading';
    try {
      await OwnerService.createOwner(owner);
      await this.handleCreatedUsers(owner.id);
      ToastStore.showSuccess('successes.owners.createSuccess');
      runInAction(() => {
        this.state = 'Success';
      });
    } catch (error) {
      if (cb) cb(error);
      ToastStore.showError('errors.owners.createFailed');
      runInAction(() => {
        this.state = 'Error';
      });
      throw error;
    }
  };

  /**
   * Update owner
   */
  updateOwner = async (owner: IOwner, cb: Function) => {
    this.state = 'Loading';
    try {
      await OwnerService.updateOwner(owner);
      await this.handleCreatedUsers(owner.id);
      await this.deactivateOwnerUsers(owner.id);
      ToastStore.showSuccess('successes.owners.updateSuccess');
      runInAction(() => {
        this.state = 'Success';
      });
    } catch (error) {
      if (cb) cb(error);
      ToastStore.showError('errors.owners.update');
      runInAction(() => {
        this.state = 'Error';
      });
      throw error;
    }
  };

  /**
   * Delete owner
   */
  deleteOwner = async (id: string) => {
    this.state = 'Loading';
    try {
      await OwnerService.deleteOwner(id);
      ToastStore.showSuccess('successes.owners.deleteSuccess');
      runInAction(() => {
        this.state = 'Success';
      });
    } catch (error) {
      ToastStore.showError('errors.owners.deleteFailed');
      runInAction(() => {
        this.state = 'Error';
      });
      throw error;
    }
  };

  /**
   * Create new owner user
   */
  createOwnerUser = (user: IAdminUser) => {
    if (Array.isArray(this.ownerUsers)) {
      this.ownerUsers.push(user);
    }
  };

  /**
   * Delete owner user
   */
  deleteOwnerUser = (id: string) => {
    if (Array.isArray(this.ownerUsers)) {
      this.ownerUsers = this.ownerUsers.filter((user) => id !== user.id);
    }
  };
}

const OwnerStore = new OwnerModel();

export default OwnerStore;
