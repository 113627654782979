/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/no-danger */
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import { FC, MouseEvent, useState } from 'react';
import { Field } from 'react-final-form';
// @ts-ignore
import {
  Add,
  AddShoppingCart,
  Done,
  ErrorOutline,
  Remove,
} from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import colors from '../../styles/colors';

export interface AddToCartDTO {
  value: string | number;
  name: string;
}
interface Callbacks {
  onAdd?: (e: MouseEvent<HTMLButtonElement>, id: number) => boolean;
  onRemove?: (e: MouseEvent<HTMLButtonElement>, id: number) => boolean;
}
interface QuantityProps extends Callbacks {
  name: string | number;
  idPrefix?: string;
  min?: number;
  max?: number;
  allowZero?: boolean;
  step?: number;
  labelledBy?: string;
  describedBy?: string;
  id?: string;
  onAddToCartClick?: (obj: AddToCartDTO) => void;
  disabled?: boolean;
}

interface ButtonProps extends Callbacks {
  input: any;
  disabled?: boolean;
  min?: number;
  max?: number;
  allowZero?: boolean;
  step?: number;
}

const useStyles = makeStyles({
  value: {
    minWidth: 30,
    padding: 0,
    display: 'flex',
    fontSize: 20,
    justifyContent: 'center',
    fontWeight: 'normal',
    textAlign: 'center',
    borderColor: colors.stroke,
    MozAppearance: 'textfield',
    '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
    borderRadius: 5,
  },
  addToCartButton: {
    padding: '0px !important',
    height: '50px !important',
    minWidth: 50,
    maxWidth: 50,
    borderRadius: 50,
    marginLeft: 24,
  },
  spinner: {
    color: colors.text.inverted,
  },
});

const DEFAULT_VALUE = 1;

/**
 * Quantity: substract
 */
const SubstractButton: FC<ButtonProps> = ({
  onRemove,
  input,
  allowZero,
  min,
  step,
  ...rest
}) => {
  const { t } = useTranslation();
  const { value, onChange } = input;

  const onClick = (e: MouseEvent<HTMLButtonElement>) => {
    const substractVal = step || 1;
    if (!value) return;
    let resume;
    if (onRemove) resume = onRemove(e, input.name);
    if ((onRemove && resume) || !onRemove) {
      if (min && allowZero && value - substractVal < min) {
        onChange(0);
        return;
      }
      onChange(value - substractVal);
    }
  };

  return (
    <IconButton
      type="button"
      onClick={onClick}
      aria-label={t('common.substract')}
      {...rest}
    >
      <Remove />
    </IconButton>
  );
};

/**
 * Quantity: Add
 */
const AddButton: FC<ButtonProps> = ({
  onAdd,
  input,
  max,
  min,
  step,
  ...rest
}) => {
  const { onChange, value } = input;
  const initialValue = step || DEFAULT_VALUE;
  const { t } = useTranslation();
  const onClick = (e: MouseEvent<HTMLButtonElement>) => {
    const addVal = step || 1;

    let nextVal = ((value && parseInt(value, 10)) || initialValue) + addVal;
    if (min && nextVal < min) nextVal = min;

    let resume;

    if (onAdd) resume = onAdd(e, input.name);

    if ((onAdd && resume) || !onAdd) {
      if (!max) {
        onChange(nextVal);
      } else {
        // If +1 value is less than equal to maxvalue, we'll use the max value
        // eslint-disable-next-line no-lonely-if
        if (nextVal > max) {
          onChange(max);
        } else {
          onChange(nextVal);
        }
      }
    }
  };

  return (
    <IconButton
      type="button"
      onClick={onClick}
      aria-label={t('common.add')}
      {...rest}
    >
      <Add />
    </IconButton>
  );
};

export const Quantity: FC<QuantityProps> = ({
  max,
  min: propMin,
  name,
  onAdd,
  onRemove,
  idPrefix,
  allowZero,
  step,
  onAddToCartClick,
  disabled,
}) => {
  const classes = useStyles();
  const s = step || 1;
  const min = step || propMin;
  const initialValue = step || DEFAULT_VALUE;

  const { t } = useTranslation();
  const [state, setState] = useState<'Idle' | 'Success' | 'Error' | 'Loading'>(
    'Idle',
  );

  const isSubstractDisabled = (input: any) =>
    !input.value || (input.value === min && !allowZero) || disabled;

  const onAddToCartSuccess = () => {
    setState('Success');
    const to = setTimeout(() => {
      setState('Idle');
      clearTimeout(to);
    }, 3000);
  };

  const onAddToCartError = () => {
    setState('Error');
    const to = setTimeout(() => {
      setState('Idle');
      clearTimeout(to);
    }, 3000);
  };

  return (
    // React final form loses it, if fieldId is a number
    <Field allowNull type="number" name={`${idPrefix || ''}${name}`}>
      {({ input }) => {
        const onAddToCart = async () => {
          const { name: inputName, value } = input;
          if (state !== 'Idle') return;
          setState('Loading');
          if (onAddToCartClick) {
            try {
              await onAddToCartClick({
                name: inputName,
                value: value || DEFAULT_VALUE,
              });
              onAddToCartSuccess();
            } catch {
              onAddToCartError();
            }
          }
        };

        return (
          <Box display="flex" alignItems="center">
            <Box display="flex" alignItems="center">
              <SubstractButton
                onRemove={onRemove}
                input={input}
                step={step}
                min={min}
                disabled={isSubstractDisabled(input)}
                allowZero={allowZero}
              />

              <Box className={classes.value}>{input.value || initialValue}</Box>
              <AddButton
                max={max}
                min={min}
                onAdd={onAdd}
                input={input}
                step={step}
                disabled={max ? input.value + s > max : false || disabled}
              />
            </Box>
            {onAddToCartClick && (
              <Button
                type="button"
                className={classes.addToCartButton}
                onClick={onAddToCart}
                disabled={disabled}
                aria-label={t('common.addToCart')}
              >
                {state === 'Idle' && (
                  <AddShoppingCart
                    role="presentation"
                    style={{ fill: 'white' }}
                  />
                )}
                {state === 'Success' && <Done />}
                {state === 'Error' && <ErrorOutline />}
                {state === 'Loading' && (
                  <CircularProgress
                    aria-busy="true"
                    className={classes.spinner}
                  />
                )}
              </Button>
            )}
          </Box>
        );
      }}
    </Field>
  );
};
