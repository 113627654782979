import { Box, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { PATHS } from '../../../../constants/routes';
import { useStores } from '../../../../stores';
import { BoxRadioGroup } from '../../../BoxRadioGroup/BoxRadioGroup';
import { SkyboxSettingsModalFooter } from './SkyboxSettingsModalFooter';

export interface SkyboxSettingsNotForSaleProps {
  onCancel: any;
  onConfirm: any;
  skybox: ISkybox;
}

/**
 * Modal component
 */
export const SkyboxSettingsNotForSale: FC<SkyboxSettingsNotForSaleProps> =
  observer(({ onCancel, onConfirm, skybox }) => {
    const { t } = useTranslation();
    const {
      cartStore: { setActiveStep, setEventIds, addSkyboxToCart, setSkyboxId },
      eventStore: { event },
    } = useStores();
    const history = useHistory();

    const RADIO_OPTS = ['true', 'false'].map((type) => ({
      value: type,
      label: t(`events.skyboxSettingsModal.not_for_sale.options.${type}`),
    }));

    const onSubmit = async (values: any) => {
      if (values.orderNow === 'true') {
        setEventIds({
          eventId: event!.id,
          eventSeriesId: event!.eventSeriesId,
        });
        setActiveStep(1);
        setSkyboxId(skybox.id);
        await addSkyboxToCart();
        history.push(PATHS.orders.add.tickets);
        return;
      }

      onConfirm();
    };

    return (
      <Box>
        <Typography variant="h1">
          {t('events.skyboxSettingsModal.not_for_sale.title')}
        </Typography>
        <Box mt={2} />
        <Typography variant="body2">
          {t('events.skyboxSettingsModal.not_for_sale.description')}
        </Typography>
        <Box mt={3} />
        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit }) => {
            return (
              <form onSubmit={handleSubmit}>
                <BoxRadioGroup
                  label={t(
                    'events.skyboxSettingsModal.not_for_sale.radioLabel',
                  )}
                  options={RADIO_OPTS}
                  name="orderNow"
                />
                <SkyboxSettingsModalFooter
                  onCancel={onCancel}
                  confirmText="events.skyboxSettingsModal.not_for_sale.confirm"
                  cancelText="common.back"
                />
              </form>
            );
          }}
        />
      </Box>
    );
  });
