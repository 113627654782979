import {
  Box,
  Button,
  makeStyles,
  Modal,
  ModalProps,
  Paper,
  Typography,
} from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import theme from '../../styles/theme';

export interface CommonModalProps extends Omit<ModalProps, 'title'> {
  type?: 'prompt' | 'normal';
  title?: string | ReactNode;
  confirmText?: string;
  cancelText?: string;
  onClose?: () => void;
  onConfirm?: () => void;
  onCancel?: () => void;
  footer?: ReactNode;
  hideClose?: boolean;
  maxWidth?: number | string;
  confirmDisabled?: boolean;
}

export const CommonModal: FC<CommonModalProps> = observer(
  ({
    open,
    footer,
    onCancel,
    onConfirm,
    confirmText,
    cancelText,
    children,
    title,
    onClose,
    type,
    maxWidth,
    confirmDisabled,
    ...rest
  }) => {
    const { t } = useTranslation();

    const getMaxWidth = () => {
      if (maxWidth) return maxWidth;
      switch (type) {
        case 'prompt':
          return 600;
        case 'normal':
          return 1024;
        default:
          return 'auto';
      }
    };
    const useStyles = makeStyles({
      paper: {
        left: '50%',
        top: '50%',
        maxHeight: '90%',
        overflow: 'scroll',
        transform: 'translate(-50%, -50%)',
        position: 'absolute',
        minWidth: getMaxWidth(),
        padding: theme.spacing(4),
        maxWidth: getMaxWidth(),
        '@media (max-width: 960px)': {
          width: '90%',
          minWidth: 'auto',
        },
      },
      footer: {
        marginTop: theme.spacing(4),
        '& > *': {
          marginRight: theme.spacing(1),
        },
      },
      header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      },
      title: {
        marginBottom: 0,
      },
    });

    const classes = useStyles();

    if (!open) return null;

    return (
      <Modal {...rest} open={open} onClose={onClose}>
        <Paper className={classes.paper}>
          {(title || onClose) && (
            <Box mb={3} component="header" className={classes.header}>
              <Box>
                {title && (
                  <Typography className={classes.title} variant="h2">
                    {title}
                  </Typography>
                )}
              </Box>
              {onClose && (
                <Button variant="outlined" onClick={onClose}>
                  {t('common.close')}
                </Button>
              )}
            </Box>
          )}

          {children}
          {(onConfirm || onCancel) && (
            <Box className={classes.footer}>
              {onConfirm && (
                <Button
                  color="secondary"
                  disabled={confirmDisabled}
                  onClick={onConfirm}
                  id="modal-confirm"
                >
                  {t(confirmText || 'common.confirm')}
                </Button>
              )}
              {onCancel && (
                <Button variant="outlined" onClick={onCancel} id="modal-cancel">
                  {t(cancelText || 'common.cancel')}
                </Button>
              )}
            </Box>
          )}
          {footer}
        </Paper>
      </Modal>
    );
  },
);
