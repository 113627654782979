import { Box, Button } from '@material-ui/core';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface SkyboxSettingsModalFooterProps {
  onConfirm?: any;
  onCancel: any;
  confirmText: string;
  cancelText?: string;
  confirmDisabled?: boolean;
  hideConfirm?: boolean;
}

export const SkyboxSettingsModalFooter: FC<SkyboxSettingsModalFooterProps> = ({
  onConfirm,
  onCancel,
  confirmText,
  cancelText,
  confirmDisabled,
  hideConfirm,
}) => {
  const { t } = useTranslation();

  return (
    <Box display="flex" mt={2}>
      {!hideConfirm && (
        <>
          <Button type="submit" onClick={onConfirm} disabled={confirmDisabled}>
            {t(confirmText)}
          </Button>
          <Box mr={1} />
        </>
      )}
      <Button variant="outlined" onClick={onCancel}>
        {(cancelText && t(cancelText)) || t('common.cancel')}
      </Button>
    </Box>
  );
};
