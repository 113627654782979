import { KeyboardDateTimePickerProps } from '@material-ui/pickers';
import { KeyboardDatePicker, KeyboardDateTimePicker } from 'mui-rff';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export interface DateTimePickerProps
  extends Omit<KeyboardDateTimePickerProps, 'name' | 'onChange' | 'value'> {
  name: string;
  disableTime?: boolean;
}

export const DateTimePicker: FC<DateTimePickerProps> = (props) => {
  const { t } = useTranslation();
  const Component = props.disableTime
    ? KeyboardDatePicker
    : KeyboardDateTimePicker;
  const rest = {
    format: `dd.MM.yyyy`,
  } as any;

  if (!props.disableTime) {
    rest.ampm = false;
    rest.format = 'dd.MM.yyyy, HH:mm';
  }
  return <Component {...props} cancelLabel={t('common.cancel')} {...rest} />;
};
