import { api, getAuthConfig, getPath } from '../api';
import { SystemNotification } from './types/SystemNotification';

export const API_PATHS = Object.freeze({
  activeSystemNotifications: '/settings/system-notification/active',
});

class SettingsServiceModel {
  getActiveSystemNotifications = async () => {
    const res = await api.get<SystemNotification[]>(
      getPath(API_PATHS.activeSystemNotifications),
      getAuthConfig(),
    );
    return res.data;
  };
}

const SettingsService = new SettingsServiceModel();

export default SettingsService;
