import axios from 'axios';
import errorHandler from './errorHandler';
import CommonStore from '../stores/CommonStore';
import { CUSTOMER_ID, API_BASE_URL } from '../constants/siteSettings';

export const apiURL = API_BASE_URL;

/**
 * Helper for get'n  path URLs
 */
export const getPath = (
  path: string,
  itemId?: number | string,
  secondaryItemId?: number | string,
) => {
  return path
    .replace('{customerId}', CUSTOMER_ID!)
    .replace('{itemId}', String(itemId))
    .replace('{secondaryItemId}', String(secondaryItemId));
};

export const api = axios.create({
  baseURL: apiURL,
  timeout: 120000,
});

// Errors
if (api) {
  api.interceptors.response.use((response) => response, errorHandler);

  api.interceptors.request.use((config) => {
    if (typeof window !== 'undefined' && window.localStorage) {
      const token = CommonStore.authToken;
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });
}

export const getAuthConfig = (fileDownload?: boolean, params?: any): any => {
  const obj = {
    headers: {
      Authorization: `Bearer ${CommonStore.authToken}`,
    },
    responseType: 'json',
    params,
  };

  if (fileDownload) obj.responseType = 'arraybuffer';
  return obj;
};
