import { Box, Button, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Layout } from '../../components/Layout/Layout';
import { BOOKING_SIDEBAR_STEPS } from '../../constants/routes';
import { useStores } from '../../stores/index';
interface OrderFormPaymentFailureScreenProps {}

export const OrderFormPaymentFailureScreen: FC<OrderFormPaymentFailureScreenProps> =
  observer(() => {
    const history = useHistory();
    const { t } = useTranslation();

    const {
      cartStore: { clearCart },
    } = useStores();

    useEffect(() => {
      clearCart();
    });

    const onBackClick = () => {
      history.push(BOOKING_SIDEBAR_STEPS[0]!.href);
    };

    return (
      <Layout headerText={t('orders.add.paymentFailure.title')}>
        <Box p={3}>
          <Typography>{t('orders.add.paymentFailure.body')}</Typography>
          <Box mt={3}>
            <Button id="return-button" onClick={onBackClick}>
              {t('orders.add.paymentFailure.return')}
            </Button>
          </Box>
        </Box>
      </Layout>
    );
  });
