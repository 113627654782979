/* eslint-disable react/no-danger */
import { Box, Typography, makeStyles } from '@material-ui/core';
import React, { FC, ReactNode, ReactNodeArray } from 'react';
import colors from '../../styles/colors';

export type KeyValueItem = {
  label: string;
  value?: null | number | string | boolean | ReactNode;
  render?: () => string | ReactNodeArray;
};
interface KeyValueListProps {
  items: KeyValueItem[];
}

const useStyles = makeStyles({
  dl: {
    display: 'grid',
    rowGap: '2px',
    columnGap: '12px',
    gridTemplateColumns: 'max-content auto',
  },
  dt: {
    gridColumnStart: 1,
    fontWeight: 600,
  },
  dd: {
    color: colors.text.body2,
    gridColumnStart: 2,
  },
});
export const KeyValueList: FC<KeyValueListProps> = ({ items }) => {
  const classes = useStyles();

  const getValue = (item: KeyValueItem) => {
    if (item.value) return item.value;
    if (item.render) return item.render();
    return undefined;
  };
  return (
    <Box component="dl" className={classes.dl}>
      {items.map((item) => (
        <React.Fragment key={item.label}>
          <Typography className={classes.dt} component="dt">
            {item.label}
          </Typography>
          <Typography className={classes.dd} component="dd">
            {getValue(item)}
          </Typography>
        </React.Fragment>
      ))}
    </Box>
  );
};
