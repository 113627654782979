import { Box, darken, lighten, makeStyles } from '@material-ui/core';
import { Error, InfoOutlined } from '@material-ui/icons';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useMemo, useState } from 'react';
import SettingsService from '../../services/SettingsService/SettingsService';
import {
  SystemNotification,
  SystemNotificationType,
} from '../../services/SettingsService/types/SystemNotification';
import { useStores } from '../../stores';
import colors from '../../styles/colors';
import { usePolling } from '../../utils/usePolling';

interface Props {}

const containerBaseStyles = {
  color: colors.text.inverted,
  minHeight: 50,
  display: 'flex',
  alignItems: 'center',
};

const useStyles = makeStyles({
  skyboxNotificationContainer: {
    backgroundColor: darken(colors.accent.primary, 0.6),
    ...containerBaseStyles,
  },
  infoNotificationContainer: {
    backgroundColor: lighten(colors.accent.primary, 0.3),
    ...containerBaseStyles,
  },
  errorNotificationContainer: {
    backgroundColor: lighten(colors.red, 0.3),
    ...containerBaseStyles,
  },
});

const SkyboxNotification = observer(() => {
  const {
    customerStore: { currentCustomer },
  } = useStores();

  const classes = useStyles();
  const notification = useMemo(() => {
    if (!currentCustomer?.settings?.notifications) return undefined;
    const {
      settings: {
        notifications: {
          skyboxAdminNotificationEnabled,
          skyboxAdminNotificationMessage,
        },
      },
    } = currentCustomer;
    return skyboxAdminNotificationEnabled
      ? skyboxAdminNotificationMessage
      : undefined;
  }, [currentCustomer]);

  if (!notification) return null;

  return (
    <>
      {notification && (
        <Box
          px={3}
          py={2}
          className={`${classes.skyboxNotificationContainer} no-print`}
        >
          <InfoOutlined />
          <Box ml={1} />
          {notification}
        </Box>
      )}
    </>
  );
});

const SystemNotifications = observer(() => {
  const classes = useStyles();
  const [notifications, setNotifications] = useState<SystemNotification[]>([]);

  const updateNotifications = useCallback(async () => {
    try {
      setNotifications(await SettingsService.getActiveSystemNotifications());
    } catch {}
  }, [setNotifications]);

  useEffect(() => {
    updateNotifications();
  }, [updateNotifications]);

  usePolling(() => updateNotifications(), 60000);

  return (
    <Box>
      {notifications.map((n) => {
        return (
          <Box
            px={3}
            py={2}
            className={`${
              n.type === SystemNotificationType.INFO
                ? classes.infoNotificationContainer
                : classes.errorNotificationContainer
            } no-print`}
          >
            {n.type === SystemNotificationType.INFO ? (
              <InfoOutlined />
            ) : (
              <Error />
            )}
            <Box ml={1} />
            {n.message}
          </Box>
        );
      })}
    </Box>
  );
});

export const NotificationBar: React.FC<Props> = observer(() => {
  return (
    <Box>
      <SkyboxNotification />
      <SystemNotifications />
    </Box>
  );
});
